/* eslint sort-keys: "error" */

const translations = {
  da: {
    'about-this-service': 'Om tjenesten',
    accessibility: 'Fremkommelighed',
    'add-itinerary-via-point': 'Tilføj viapunkt til rejsen',
    'add-location-to-favourites': 'Tilføj sted til favoritter',
    address: 'Adresse',
    'address-or-stop': 'Adresse eller stoppested',
    agency: 'Operatør',
    airplane: 'Fly',
    'airplane-with-route-number': 'Fly {routeNumber}',
    'airport-check-in': 'Bagage kan checkes ind hos {agency}',
    'airport-collect-luggage': 'Hent eventuel bagage',
    'airport-security-check-go-to-gate':
      'Passer sikkerhedskontrollen og fortsæt til gate',
    'approx-meters': '{approxDistance} meter',
    'arriving-at': 'Ankomst',
    'arriving-soon': 'Nu',
    'avoid-transfers': 'Undgå skift',
    'avoid-walking': 'Undgå at gå',
    'back-button-title': 'Gå tilbage til forrige side',
    'back-to-front-page': 'Tilbage til forsiden ›',
    'bike-availability': 'Cykler tilgængelige',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Køb billet',
    call: 'Ring',
    cancel: 'Fortryd',
    'car-distance-duration': 'Kør {duration} ({distance})',
    cause: 'Årsag',
    'char-left': 'slet',
    'choose-stop': 'Vælg stoppested',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Bycykel',
    'citybike-register-required': 'Bycykelregistrering påkræves',
    close: 'Lukket',
    'contact-information': '{contactName} kontaktinformation',
    'create-account': 'Gem {contactName}-konto',
    'cycle-distance-duration': 'Cykle {duration} ({distance})',
    'cyclewalk-distance-duration': 'Tril cyklen {duration} ({distance})',
    datasources: 'Datakilder',
    delete: 'Slet',
    'departure-time-in-minutes': '{minutes} m',
    departures: 'Afgange',
    description:
      'En national rejseplanlægger skal bidrage til at gøre det lettere at rejse kollektivt over hele landet.',
    destination: 'Til',
    'destination-label-change': 'Ændre destination',
    'destination-placeholder': 'Hvorhen vil du rejse?',
    'digitransit-platform': 'Digitransit platform',
    'disruption-info': 'Afvigsinformation',
    'disruption-info-no-alerts': 'Ingen afvigsinformation.',
    'disruption-info-route-no-alerts': 'Ingen afvigsinformation.',
    disruptions: 'Afvig',
    'distance-between': 'Afstand {distance1} m — {distance2} m',
    'distance-under': 'Afstand mindre end {distance} m',
    earlier: 'Tidligere',
    'edit-favourites': 'Rediger favorit',
    'extra-info': 'Mere info',
    'favourite-target': 'Favoritdestination',
    ferry: 'Færge',
    'ferry-with-route-number': 'Færge {routeNumber} {headSign}',
    frontpage: 'Forside',
    'geolocate-yourself': 'Find min position',
    'geolocation-denied-heading':
      'Tjenesten fungerer bedst når du oplyser din position',
    'geolocation-denied-text':
      'Lokalisering er ikke tilladt. Kontroller indstillingene for din browser.',
    'geolocation-failed-heading': 'Lokalisering fungerer ikke',
    'geolocation-failed-text':
      'Din browser har ikke understøttelse for lokalisering.',
    'geolocation-prompt-text': 'Venligst accepter lokalisering.',
    'geolocation-timeout-heading':
      'Lokalisering tager længre tid end forventet.',
    'geolocation-timeout-text': 'Vælg udgangspunkt og prøv igen.',
    'give-destination': 'skriv hvor du vil hen',
    'give-name-to-location': 'Giv stedet en beskrivelse',
    'give-origin': 'tilføj afgangssted',
    'give-position': 'Tilføj position i søgefeldet over.',
    'hour-short': 't',
    'how-to-rate-service': 'Hvordan vil du rangere tjenesten?',
    'in-addition': 'Og',
    inquiry: 'Send tilbagemelding',
    instructions: 'Instruktioner',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Fandt du ikke det du ledte efter?',
    'itinerary-feedback-placeholder': 'Beskrivelse (valgfrit)',
    'itinerary-hide-stops': 'Skjul stop',
    'itinerary-page.description': 'Rejsebeskrivelse',
    'itinerary-page.title': 'Rejsebeskrivelse',
    'itinerary-summary-page.description': 'Anbefalte rute',
    'itinerary-summary-page.title': 'Anbefalte rute',
    later: 'Senere',
    leaves: 'Går',
    'leaving-at': 'Afgang',
    'likely-to-recommend':
      'Hvor sandsynlig er det at du vil anbefale denne tjeneste til en ven eller kollega?',
    'likely-to-use':
      'Hvor sandsynlig er det at du vil bruge denne tjeneste fremfor tjenesten du allerede bruger?',
    loading: 'indlæser',
    locate: 'Der jeg er',
    'location-examples': 'f.eks hjemme, arbejde, skole ...',
    'location-from-map': 'Vælg position',
    login: 'Log ind',
    'long-transfers-margin': 'Rigtig lang',
    'main-menu-label-open': 'Åben hovedmenuen',
    'main-mode': 'Jeg rejser med',
    map: 'Kort',
    'messagebar-label-close-message-bar': 'Luk besked',
    'messagebar-label-page': 'Side',
    'minute-short': 'm',
    minutes: 'min',
    navigate: 'Naviger',
    nearest: 'Forrige',
    'network-error': 'Netværksfejl',
    next: 'Næste',
    'no-position': 'Ingen position',
    'no-position-no-stops':
      'Kan ikke vise stoppesteder i nærheden da din position er ukendt.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Vi finder desværre ingen rejseforslag til rejsesøget. Forsøg at ændre adressen',
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'No route suggestions were found because the origin and destination are very close to each other.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Venligst vælg udgangspunkt og destination.',
    'no-transfers-margin': 'Ingen',
    'no-trips-found': 'Ingen rejser tilgænglig for denne dag.',
    now: 'Nu',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ingen stop} other {{number} stop} }',
    'off-canvas-info':
      'Ved at logge ind kan du finde tilbage til dine favoritter på andre enheder',
    or: 'eller',
    origin: 'Fra',
    'origin-label-change': 'Ændre afgangsted',
    'origin-placeholder': 'Fra',
    'own-position': 'Min position',
    'page-not-found': 'Siden findes ikke ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Indfartsparkering',
    'park-and-ride-availability': 'Tilgjængelighed',
    'park-and-ride-unknown': 'Ukendt kapacitet',
    'pay-attention': 'Obs!',
    'pick-icon': 'Vælg et ikon',
    'place-route-or-keyword': 'Adresse eller stoppested',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat.',
    'platform-short-no-num': 'Plat.',
    'prefer-walking': 'Foretræk at gå',
    previous: 'Tidligere',
    print: 'Skriv ud',
    'print-timetable': 'Køreplan',
    rail: 'Tog',
    'rail-with-route-number': 'Tog {routeNumber} {headSign}',
    'realtime-matching': 'Sandtidsintegration',
    'rent-cycle-at': 'Lej cykel fra stativ {station}',
    'required-ticket': 'Billet kræves før påstigning',
    'right-now': 'Nu',
    route: 'linje',
    'route-destination-arrives': 'Destination / Sidste holdeplads',
    'route-from-here': 'Find rute herfra',
    'route-here': 'Find rute hertil',
    'route-not-possible':
      'Desværre kan vi ikke finde et rejseforslag til dig. Teknisk feil: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Rute {shortName}, {longName}',
    'route-page.title': 'Rute {shortName}',
    'route-page.title-short': 'Rute',
    routes: 'Ruter',
    run: 'Løbe',
    save: 'Gem',
    search: 'Til',
    'search-destination-results-but-no-search': 'Se resultater i Til-fanen',
    'search-no-results': 'Ingen resultater',
    'search-origin': 'tilføj afgangssted',
    'search-position': 'Led efter position',
    'search-search-results-but-no-destination': 'Se resultater i Fra-fanen',
    'searching-position': 'Leder etter position',
    'select-position': 'Vælg din position baseret på tidligere søg',
    send: 'Send',
    settings: 'Indstillinger',
    'settings-label-change': 'Ændre indstillinger',
    share: 'Del rejsen',
    'show-departures': 'Vis afgange',
    'show-more': 'Vis flere',
    'skip-positioning': 'Hop over',
    'skip-to-content': 'Skip to content',
    slow: 'Spasering',
    'specify-location': 'Angiv sted',
    'splash-locating': 'Søger efter din position',
    'splash-or-choose': 'Eller fravælg din position',
    'splash-use-positioning': 'Brug min position',
    'splash-welcome': 'Velkommen til ny version af din rejseplanlægger',
    'splash-you-can-also': 'Alternativt',
    stop: 'Holdeplads',
    'stop-number': 'Holdepladsnummer',
    'stop-page.description': 'Holdeplads - {name} {code}, {desc}',
    'stop-page.title': 'Holdeplads - {name} {code}',
    'stop-page.title-short': 'Holdeplads',
    stops: 'Holdepladser',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Anbefalte ruter',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Brugervilkår',
    'ticket-and-price-info': 'Billet- og prisinformation',
    tickets: 'Billetter',
    time: 'Tid',
    timetable: 'Køreplan',
    today: 'I dag',
    tomorrow: 'I morgen',
    tram: 'Sporvogn',
    'tram-with-route-number': 'Sporvogn {routeNumber} {headSign}',
    transfers: 'Bytte',
    'transfers-allowed': 'Bytte tilladt',
    'transfers-margin': 'Byttetid',
    'trip-co2-emissions': 'Rejsens CO2-udslip',
    'trip-page.description': 'Linje {shortName}, {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Prøv igen.',
    'use-citybike': 'Brug bycykel',
    'use-own-position': 'Brug min position',
    'using-modes': 'Transportform',
    'very-likely': 'Højst sandsynlig',
    'very-unlikely': 'Lidt sandsynlig',
    'via-leg-stop-duration': 'Viapunkt {stayDuration}',
    'via-point': 'Viapunkt',
    'via-point-index': 'Viapunkt {index}',
    wait: 'Vent',
    'wait-amount-of-time': 'Vent {duration}',
    walk: 'gå',
    'walk-distance-duration': 'Gå {duration} ({distance})',
    walking: 'Gå',
    'walking-speed': 'Gåhastighed',
    'warning-call-agency':
      'Kun bestillingstransport: {routeName}, afgangen må forudbestilles af operatør.',
    'warning-call-agency-no-route':
      'Kun bestillingstransport. Afgangen må forudbestilles af operatør.',
    'weather-at-destination': 'Vejret på destinationen',
    yesterday: 'I går',
    zones: 'Zoner',
  },

  de: {
    'bicycle-parking-filter': 'Bevorzugte Abstellart',
    'bicycle-parking-filter-all': 'Alle Abstellanlagen',
    'bicycle-parking-filter-freeOnly': 'Nur kostenlose Anlagen',
    'bicycle-parking-filter-securePreferred': 'Sichere bevorzugt',
    'tier_REUTLINGEN-availability': 'TIER E-Scooter verfügbar',
    'tier_REUTLINGEN-station-no-id': 'TIER E-Scooter',
    'tier_REUTLINGEN-start-using': 'Buchen Sie einen TIER E-Scooter',
    'zone-info': 'Zoneninformation',
    'fares-disclaimer': 'Tarifauskünfte werden durch die Nahverkehrsgesellschaft Baden-Württemberg mbH (NVBW) zur Verfügung gestellt. Alle Angaben ohne Gewähr.',
    imprint: 'Impressum',
    privacy: 'Datenschutz',
    'rent-cycle-at': 'Leihrad ausleihen:',
    'regiorad-availability': 'Leihräder verfügbar',
    'stadtrad-availability': 'Leihräder verfügbar',
    'cargo-bike': 'Lastenfahrrad',
    'cargo-bike-availability': 'Lastenräder verfügbar',
    'cargo-bike-booking-link' : 'Buchen',
    'cargo-bikes-capacity-no-data': 'Keine Kapazitätsdaten verfügbar',
    'cargo-bikes-capacity-available': '{available} von {capacity} verfügbar',
    'cargo-bikes-capacity-in-total': 'Kapazität: {capacity} Lastenrad/ Lastenräder',
    'taxi-availability': 'Taxis verfügbar',
    'report-defect': 'Mangel melden',
    'alert:bikerental:free-floating-drop-off':
      'Ziel ist keine Rückgabestation. Ausleihe kann hier nicht abgeschlossen werden. Anbieterabhängig fallen weiter Gebühren bis zur Rückgabe an einer Station an.',
    day: 'Tag',
    monday: 'Montag',
    tuesday: 'Dienstag',
    wednesday: 'Mittwoch',
    thursday: 'Donnerstag',
    friday: 'Freitag',
    saturday: 'Samstag',
    sunday: 'Sonntag',
    'weekday-mo': 'Montag',
    'weekday-tu': 'Dienstag',
    'weekday-we': 'Mittwoch',
    'weekday-th': 'Donnerstag',
    'weekday-fr': 'Freitag',
    'weekday-sa': 'Samstag',
    'weekday-su': 'Sonntag',
    'weekday-ph': 'Gesetzlicher Feiertag',
    'opening-hours': 'Öffnungszeiten',
    'parking-spaces-in-total': '{total} Stellplätze',
    'parking-spaces-available': '{free} von {total} Stellplätzen verfügbar',
    'map-layer-dynamic-parking-lots': 'Parkplätze',
    'map-layer-roadworks': 'Baustellen',
    'map-layer-sharing': 'Carsharing & Fahrrad- / Lastenradverleih',
    'map-layer-category-public-transit': 'Öffentlicher Nahverkehr',
    'map-layer-category-bicycle': 'Fahrrad',
    'map-layer-category-sharing': 'Sharing-Angebote',
    'map-layer-category-car': 'Auto',
    'map-layer-category-others': 'Andere',
    open: 'Geöffnet',
    closed: 'Geschlossen',
    'about-this-service': 'Über diesen Service',
    'offer-ride': 'Fahrgemeinschaft anbieten',
    'thank-you': 'Vielen Dank!',
    'car-park-capacity-alert': 'Es sind kaum noch freie Parkplätze vorhanden. Bitte prüfen Sie eine Route zu einem späteren Zeitpunkt.',
    'exclude-full-carparks': 'Belegte Parkplätze ausschließen',
    'chosen-times-recurring':
      'Sie haben für folgende Zeit und Tage inseriert: ',
    'chosen-times-once':
      'Sie haben für den folgendes Datum und Uhrzeit inseriert: ',
    'accept-carpool-policy':
      'Ich habe die <a href="{policyUrl}" target="_blank">Datenschutzbestimmungen</a> und die <a href="{termsUrl}" target="_blank">AGB</a> gelesen und erkläre mich einverstanden.',
    'add-carpool-offer-frequency': 'Wie oft bieten Sie diese Fahrt an?',
    once: 'einmalig',
    recurring: 'regelmäßig',
    'add-phone-number': 'Bitte fügen Sie Ihre Telefonnummer hinzu:',
    'phone-number-info': 'Diese wird Interessenten angezeigt.',
    'your-carpool-trip': 'Ihr Inserat',
    'carpool-success-info':
      'Ihr Inserat wird nach Ablauf der Zeit jedoch spätestens nach drei Monaten (bei regelmäßigen Fahrten) gelöscht.',
    'carpool-offer-success':
      'Ihr Inserat von {origin} nach {destination} wurde eingestellt.',
    'time-oclock': ' Uhr.',
    accessibility: 'Barrierefreiheit',
    'accessibility-limited': 'Rollstuhlgerecht',
    'accessibility-nolimit': 'Keine Einschränkungen',
    'accessibility-statement': 'Erklärung zur Barrierefreiheit',
    'map-type': 'Kartenarten',
    streets: 'Straßen',
    satellite: 'Satellit',
    'add-favourite-bikeStation-failed-heading':
        'Speichern der Ausleihstation fehlgeschlagen',
    'add-favourite-place-failed-heading': 'Standort kann nicht gespeichert werden',
    'add-favourite-route-failed-heading': 'Speichern der Route fehlgeschlagen',
    'add-favourite-station-failed-heading': 'Speichern der Station fehlgeschlagen',
    'add-favourite-stop-failed-heading': 'Speichern der Haltestelle fehlgeschlagen',
    'add-home': 'Zuhause setzen',
    'map-layer-carpool': 'Mitfahrpunkte',
    'add-itinerary-via-point': 'Zwischenstopp hinzufügen',
    'add-location-to-favourites': 'Ort zu Favoriten hinzufügen',
    'add-to-favourites': 'Zu Favoriten hinzufügen',
    'add-via-button-label': 'Zwischenstopp hinzufügen',
    'add-via-duration-button-label': 'Aufenthaltszeit am Zwischenstopp {index}',
    'add-via-duration-button-label-close':
      'Schließe Aufenthaltszeit-Auswahl am Zwischenstopp {index}',
    'add-via-duration-button-label-open':
      'Öffne Aufenthaltszeit-Auswahl am Zwischenstopp {index}',
    'add-work': 'Arbeit setzen',
    address: 'Adresse',
    'address-or-stop': 'Adresse oder Haltestelle',
    agency: 'Betreiber',
    'air-temperature': 'Lufttemperatur',
    airplane: 'Flug',
    'airplane-with-route-number': 'Flug {routeNumber}',
    'airport-check-in': 'Falls Sie sich noch nicht im voraus eingecheckt haben, tun Sie dies direkt nach der Ankunft.',
    'airport-collect-luggage': 'Falls vorhanden, Gepäck einsammeln',
    'airport-security-check-go-to-gate':
      'Gehen Sie durch die Sicherheitskontrolle zum Abfluggate',
    'all-routes': 'Alle Linien',
    'all-routes-disabled': 'Keine Linien',
    'approx-meters': '{approxDistance} Meter',
    'aria-itinerary-summary':
      'Gesamtdauer {duration}. Route fährt ab {inFuture} {departureTime} mit Ankunft {arrivalTime}.',
    'aria-itinerary-summary-bike-distance':
      'Strecke per Rad {duration}, {distance} ',
    'aria-itinerary-summary-walk-distance':
      'Strecke zu Fuß {duration}, {distance} ',
    'arrive-leave': 'Ankunft oder Abfahrt an der ausgewählten Zeit',
    'arriving-at': 'Ankunft',
    'arriving-soon': 'Jetzt',
    'as-destination': 'Ziel',
    'as-origin': 'Start',
    'as-viapoint': 'Zwischenhalt',
    'at-time': 'um',
    'avoid-transfers': 'Umstiege vermeiden',
    'avoid-walking': 'Gehen vermeiden',
    'back-button-title': 'Zurück zur vorheringen Seite',
    'back-to-front-page': 'Zurück zur Startseite ›',
    bicycle: 'Fahrrad',
    'bicycle-charging-station': 'Fahrradladestation',
    'bicycle-distance-duration': 'Fahrrad {duration} ({distance})',
    bicycle_walk: 'Fahrrad schieben',
    'bicycle-walk-from-transit': 'Steigen Sie mit dem Fahrrad aus dem {transportMode} aus und schieben Sie {distance} ({duration})',
    'bicycle-walk-to-transit': 'Schieben sie ihr Fahrrad {distance} ({duration}) und steigen Sie mit ihm in den/die {transportMode}',
    'bicycle-parking': 'Fahrradabstellanlage',
    'bicycle-parking-covered': 'überdacht',
    'bicycle-parking-shed': 'Fahradsammelanlage',
    'bicycle-parking-lockers': 'Fahradboxen',
    'bicycle-parking-garage': 'Fahrradparkhaus',
    'bike-availability': 'Fahrräder verfügbar',
    'bike-not-allowed-bus': 'Keine Fahrradmitnahme im Bus',
    'bike-not-allowed-tram': 'Keine Fahrradmitnahme in der Straßenbahn',
    'bike-park': 'Fahrrad abstellen',
    'bike-station-disabled': 'Leihstation außer Betrieb.',
    'bikes-available': 'Fahrräder verfügbar',
    'biketransport-citybike': 'Ich benutze ein Leihrad',
    'biketransport-only-bike': 'Ich fahre nur mit dem Fahrrad',
    'biking-amount': 'Anteil der Radstrecke',
    'biking-speed': 'Geschwindigkeit mit dem Fahrrad',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Kaufen',
    'buy-ticket': 'Tickets kaufen',
    call: 'Anrufen',
    cancel: 'Abbrechen',
    canceled: 'Ausgefallen',
    'canceled-itineraries-amount':
      '{itineraryAmount, plural, =1 {1 weitere} other {{itineraryAmount} weitere}} ausgefallene Verbindungen',
    'canceled-itineraries-amount-hide':
      'Ausgefallene Verbindungen ausblenden ({itineraryAmount})',
    'canceled-legs': 'Ausgefallene Abfahrten auf der Route',
    car: 'Auto',
    'car-charging-station': 'Elektroauto-Ladestation',
    'car-distance-duration': 'Fahren {duration} ({distance})',
    'car-park-full': 'Volle Parkplätze ausschließen',
    car_park: 'Park & Ride',
    carpool: 'Fahrgemeinschaft',
    'card-header-back-to-result-list' : 'Zurück zu den Ergebnissen',
    'carpool-distance-duration': '{duration} ({distance}) mitfahren',
    cause: 'Grund',
    'char-left': 'Zeichen',
    'charging-direct-deep-link': 'Ladevorgang starten',
    'charging-free': 'Ladevorgang kostenlos',
    'charging-not-free': 'Ladevorgang kostenpflichtig',
    'charging-payment-contactless' : 'Kontaktlos',
    'charging-payment-credit' : 'Kreditkarte',
    'charging-payment-debit' : 'Debitkarte',
    'charging-payment-rfid' : 'RFID',
    'charging-spaces-available': '{available} von {capacity} Ladeplätzen frei',
    'charging-spaces-in-total': '{capacity} Ladeplätze',
    'charging-spaces-no-data' : 'Keine Daten zu den Ladeplätze vorhanden',
    'choose-stop': 'Option auswählen',
    'choose-stop-or-vehicle': 'Fahrzeug oder Haltestelle auswählen',
    'choose-vehicle': 'Fahrzeug auswählen',
    'direction': 'Ri. ',
    'position-estimated': 'Position geschätzt. Keine aktuellen Positionsinformationen verfügbar.',
    citybike: 'Sharing-Angebote',
    'citybike-buy-season': 'Ticket für Tag, Monat oder Jahr kaufen',
    'citybike-distance-duration': 'Leihrad {duration} ({distance})',
    'citybike-duration-general-header': 'Durch die Nutzung eines Leihrades entstehen Extrakosten auf dieser Route.',
    'citybike-duration-info': 'Sie können ihr Leihrad bis zu {duration} Minuten kostenlos ausleihen. Danach ist die Benutzung kostenpflichtig.',
    'citybike-duration-info-header': 'Der Teil der Strecke auf einem Leihrad dauert mehr als {duration} Minuten.',
    'citybike-duration-info-short': 'Der Teil der Strecke dauert länger als {duration} Minuten, was Extrakosten verursacht.',
    'citybike-network-headers': 'Leihräder und Scooter',
    'citybike-purchase-link': 'Buchen',
    'citybike-register-required': 'Anmeldung für Leihräder benötigt',
    'citybike-start-using': 'Beginne Leihrad-Nutzung',
    'citybike-start-using-info': 'Mehr Information',
    'citybike-station': 'Leihrad-Station {stationId}',
    'citybike-station-no-id': 'Leihrad-Station',
    'regiorad-station-no-id': 'Leihrad-Station',
    'stadtrad-station-no-id': 'Leihrad-Station',
    'cargo-bike-station-no-id': 'Lastenrad-Station',
    'taxi-station-no-id': 'Taxistand',
    'car-sharing-station-no-id': 'Car-Sharing-Station',
    'clear-button-label': 'Leeren',
    close: 'Schließen',
    'close-favourite-module': 'Favoriten-Modul schließen',
    'condition': 'Straßenzustand',
    'contact-information': '{contactName} Kontaktinformationen',
    continue: 'Fortsetzen',
    'create-account': '{contactName}-Konto anlegen',
    currentposition: 'Aktuelle Position',
    'cycle-distance-duration': 'Radfahren: {duration} ({distance})',
    'cyclewalk-distance-duration': 'Fahrrad schieben: {duration} ({distance})',
    datasources: 'Datenquellen',
    date: 'Datum',
    delete: 'Löschen',
    'departure-is-canceled': '{modeInfo} {from}–{to} at {time} fällt aus',
    'departure-is-canceled-airplane': 'Flug {shortName}',
    'departure-is-canceled-bus': 'Bus {shortName}',
    'departure-is-canceled-ferry': 'Fähre {shortName}',
    'departure-is-canceled-rail': 'Zug {shortName}',
    'departure-is-canceled-subway': 'U-Bahn {shortName}',
    'departure-is-canceled-tram': 'Tram {shortName}',
    'departure-list-update.sr-instructions': 'Die Abfahrts- und Ankunfstafeln zeigen Informationen in Echtzeit an.',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Abfahrten',
    'departures-by-hour': 'Abfahrten pro Stunde',
    'departures-by-hour-minutes-route': '(Minuten/Route)',
    description:
      'Finde Verkehrsverbindungen zu allen Orten. Egal ob Nah oder Fern.',
    destination: 'Zielort',
    'destination-label-change': 'Zielort ändern',
    'destination-outside-service':
      'Leider konnte keine Route an diesen Zielort gefunden werden. Der Zielort befindet sich außerhalb des abgedeckten Gebietes. Bitte ändern Sie den Zielort.',
    'destination-placeholder': 'Wohin reisen?',
    'dialog-return-to-map': 'Zurück zur Karte',
    'digitransit-platform': 'Digitransit-Plattform',
    disclaimer:
      'Bitte beachten Sie, dass es sich um geschätzte Reisezeiten handelt. Die vorgeschlagenen Verbindungen können nicht garantiert werden.',
    disruption: 'Störung',
    'disruption-info': 'Störungsmeldungen',
    'disruption-info-no-alerts': 'Keine Störungsmeldungen.',
    'disruption-info-route-no-alerts': 'Keine Störungsmeldungen.',
    disruptions: 'Störungen',
    'disruptions-tab.sr-disruptions': 'Mindestens eine bekannte Störung',
    'disruptions-tab.sr-no-disruptions': 'Keine bekannten Störungen',
    'distance-between': 'Abstand zwischen {distance1} m — {distance2} m',
    'distance-total': 'Gesamtstrecke',
    'distance-under': 'Abstand unter {distance} m',
    earlier: 'Früher',
    'edit-favourites': 'Favoriten bearbeiten',
    'elevation-gained-total': 'Bergauf',
    'elevation-lost-total': 'Bergab',
    explanations: 'Erklärung',
    'extra-info': 'Mehr Infos',
    'favourite-target': 'Favoriten',
    ferry: 'Fähre',
    'ferry-with-route-number': 'Fähre {routeNumber} {headSign}',
    'fetch-new-route': 'Neue Verbindung anfragen',
    'from-ferry': 'Fähre',
    'from-rail': 'Zug',
    'from-subway': 'U-Bahn',
    'from-station': 'von Bahnhof',
    'from-stop': 'von Halt',
    frontpage: 'Startseite',
    'generic-cancelation': '{mode} {route} {headsign} um {time} fällt aus.',
    'generic-error': 'Ein Fehler ist aufgetreten',
    'geolocate-yourself': 'Lokalisieren',
    'geolocation-denied-heading': 'Bitte Zugriff auf den Standort erlauben',
    'geolocation-denied-text':
      'Zugriff auf den Standort ist nicht erlaubt. Bitte prüfen Sie die Einstellungen Ihres Browsers.',
    'geolocation-failed-heading': 'Standortermittlung fehlgeschlagen',
    'geolocation-failed-text':
      'Ihr Browser unterstützt keinen Zugriff auf den Standort',
    'geolocation-prompt-text': 'Bitte Zugriff auf den Standort erlauben',
    'geolocation-timeout-heading':
      'Standortermittlung dauert länger als erwartet.',
    'geolocation-timeout-text':
      'Abfahrtsort unten wählen, oder später versuchen.',
    'give-destination': 'Geben Sie Ihren Zielort ein',
    'give-name-to-location': 'Name für diesen Ort',
    'give-origin': 'Geben Sie Ihren Abfahrtsort ein',
    'give-position': 'Geben Sie Ihre Position ins Suchfeld ein.',
    'give-route': 'Verbindung suchen',
    hour: 'Stunde',
    'hour-short': 'h',
    'how-to-rate-service': 'Wie würden Sie den Service bewerten?',
    'in-addition': 'auch',
    'index.title': 'Reiseplaner',
    inquiry: 'Feedback geben',
    instructions: 'Anweisungen',
    'itinerary-details.biking-leg':
      'Um {time} {distance} mit dem Rad von {origin} nach {destination} fahren. Geschätzte Dauer: {duration}',
    'itinerary-details.car-leg':
      'Um {time} {distance} mit dem Auto von {origin} nach {destination} fahren. Geschätzte Dauer: {duration}',
    'itinerary-details.end-leg': 'Um {time} am Ziel {destination} ankommen.',
    'itinerary-details.route-has-info-alert': 'Route enthält Warnungen.',
    'itinerary-details.route-has-severe-alert': 'Route enthält Störungen.',
    'itinerary-details.route-has-unknown-alert': 'Route enthält Störungen.',
    'itinerary-details.route-has-warning-alert': 'Route enthält Störungen.',
    'itinerary-details.scooter-leg':
      'Um {time} {distance} mit dem Scooter von {origin} nach {destination} fahren. Geschätzte Dauer: {duration}',
    'itinerary-details.transit-leg-part-1': 'Um {time} ',
    'itinerary-details.transit-leg-part-2':
      'von {startStop} {trackInfo} nach {endStop}. Geschätzte Dauer: {duration}',
    'itinerary-details.walk-leg':
      'Um {time} {distance} zu Fuß von {origin} nach {destination} laufen. Geschätzte Dauer: {duration}',
    'itinerary-feedback-button': 'Feedback senden',
    'itinerary-feedback-message': 'Nichts gefunden?',
    'itinerary-feedback-placeholder': 'Beschreibung (optional)',
    'itinerary-hide-stops': 'Zwischenstopps ausblenden',
    'itinerary-page.description': 'Reisebeschreibung',
    'itinerary-page.hide-details': 'Details ausblenden',
    'itinerary-page.show-details': 'Details zeigen',
    'itinerary-page.show-details-label':
      'Zeige Details zu Routenvorschlag {number}',
    'itinerary-page.title': 'Reisebeschreibung',
    'itinerary-page.update-alert': 'Suchergebnisse aktualisiert',
    'itinerary-summary-page-street-mode.update-alert':
      'Gehen- und Radfahren-Ergebnisse aktualisiert',
    'itinerary-summary-page.description': 'Reisevorschläge',
    'itinerary-summary-page.title': 'Routenvorschläge',
    'itinerary-summary-row.biking-distance':
      'Gesamte Fahrradstrecke: {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Auf der Karte zeigen',
    'itinerary-summary-row.description':
      'Route fährt ab {departureDate} {departureTime} mit Ankunft {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Gesamte Dauer {totalTime}.',
    'itinerary-summary-row.first-departure':
      '{vehicle} fährt um {departureTime} von {stopName}.',
    'itinerary-summary-row.first-leg-start-time':
      'Fährt ab um {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Abfahrt um {firstDepartureTime} von {firstDepartureStop} Leihrad-Station',
    'itinerary-summary-row.no-transit-legs': 'Start jederzeit möglich',
    'itinerary-summary-row.transfers':
      'Umstieg auf {vehicle} an Halt {stopName}',
    'itinerary-summary-row.walking-distance':
      'Gesamte Laufstrecke: {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Verbindungen mit Fahrradmitnahme',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Verbindungen mit Fahrradmitnahme',
    'itinerary-summary.bikeAndPublic-rail-title':
      'Verbindungen mit Fahrradmitnahme',
    'itinerary-summary.bikeAndPublic-subway-title':
      'Verbindungen mit Fahrradmitnahme',
    'itinerary-summary.bikePark-title': 'Fahrrad abstellen und weiterfahren',
    'itinerary-summary.interline-wait': 'Die Route ({shortName}) und Ziel ({destination}) werden sich am Halt {stop} verändern. Die Wartezeit beträgt {time}. Bitte bleiben Sie sitzen.',
    'itinerary-summary.show-on-map': 'Auf Karte zeigen {target}',
    'disabled-parking-spaces-available':'{freeDisabled} von {totalDisabled} barrierefreien Stellplätzen verfügbar',
    'itinerary-ticket.title': 'Benötigte Fahrkarte',
    'itinerary-tickets.title': 'Benötigte Fahrkarten',
    'itinerary-time.title': 'Dauer',
    'itinerary-walk.title': 'Gesamte Laufstrecke',
    'journeyplanner.title': 'Reiseplaner',
    later: 'Später',
    'last-updated': 'Daten von {time} Uhr',
    leaves: 'Abfahrt',
    'leaving-at': 'Abfahrt',
    'likely-to-recommend':
      'Wie wahrscheinlich ist es, dass Sie unseren Service weiterempfehlen würden?',
    'likely-to-use':
      'Wie wahrscheinlich würden Sie weiterhin diesen Service anstatt des bisherigen Services nutzen?',
    loading: 'laden',
    localadmin: 'Ort',
    locate: 'Lokalisieren',
    'location-examples': 'z.B. Zuhause, Arbeit, Schule,...',
    'location-from-map': 'Gewählte Position',
    'location-from-map-confirm': 'Auswahl bestätigen',
    'location-home': 'Zuhause',
    'location-school': 'Schule',
    'location-shopping': 'Einkaufen',
    'location-sport': 'Sport',
    'location-work': 'Arbeit',
    login: 'Anmelden',
    'login-content':
      'Um Favoriten, Linien und Haltestellen zu speichern, müssen Sie sich einloggen.',
    'login-header': 'Loggen Sie sich ein',
    logout: 'Abmelden',
    'long-transfers-margin': 'Lange',
    'main-menu-label-close': 'Hauptmenü schließen',
    'main-menu-label-open': 'Menü öffnen',
    'main-mode': 'Ich reise mit',
    map: 'Karte',
    'map-layer-citybike': 'Leihrad-Stationen',
    'map-layer-charging-stations': 'Ladestationen',
    'map-layer-bike-parks': 'Fahrradstellplätze',
    'map-layer-park-and-ride': 'Park & Ride-Plätze',
    'map-layer-stop-bus': 'Bushaltestellen',
    'map-layer-stop-ferry': 'Fähren',
    'map-layer-stop-tram': 'Tramhaltestellen',
    'map-layer-terminal-bus': 'Busbahnhöfe',
    'map-layer-terminal-rail': 'Bahnhöfe',
    'map-layer-terminal-subway': 'U-Bahnhöfe',
    'map-layer-vehicles': 'Buspositionen',
    'map-layer-weather-stations': 'Straßenwetter',
    'menu-faq': 'FAQ',
    'menu-feedback': 'Feedback senden',
    'menu-link-to-privacy-policy': 'Datenschutz',
    'messagebar-label-close-message-bar': 'Banner schließen',
    'messagebar-label-page': 'Seite',
    'minute-short': 'm',
    minutes: 'min',
    'minutes-or-route': 'Min / Strecke',
    'missing-price-info-disclaimer': 'Keine Preisangabe möglich',
    'more-departures': 'Weitere Abfahrten',
    'more-settings': 'Erweiterte Einstellungen',
    'move-to-tab': 'Zur Registerkarte {number}',
    'move-on-map': 'Auf der Karte bewegen',
    navigate: 'Navigieren',
    nearest: 'Nächstgelegene { mode }',
    'nearest-no-stops': 'Es gibt keine Haltestellen für ihrer ausgewählten Transportarten in der Nähe.',
    'nearest-stops': 'Haltestellen',
    'nearest-stops-airplane': 'Flughäfen',
    'nearest-stops-bus': 'Bushaltestellen',
    'nearest-stops-carpool': 'Fahrgemeinschaft',
    'nearest-stops-citybike': 'Fahrrad-Leihstationen',
    'nearest-stops-ferry': 'Fähranleger',
    'nearest-stops-rail': 'Bahnstationen',
    'nearest-stops-subway': 'U-/Stadtbahn-Haltestelle',
    'nearest-stops-tram': 'Straßenbahn-Haltestelle',
    'nearest-stops-update-location': 'Aktualisiere {mode}',
    'nearest-stops-updated-location':
      'Position auf der Karte hat sich geändert',
    neighbourhood: 'Viertel ',
    'network-error': 'Ein Netzwerkfehler ist aufgetreten.',
    next: 'Nächste',
    'no-bike-allowed-popup-train':
      'Die Fahrradmitnahme ist in Pendlerzügen erlaubt, in Fernverkehrszügen ist jedoch ein Zuschlag nötig und es könnte eine Reservierung erforderlich sein.',
    'no-bike-allowed-popup-tram-bus':
      'Die Fahrradmitnahme ist in Bussen und Straßenbahnen nicht erlaubt. Lassen Sie Ihr Fahrrad bitte an der Haltestelle oder an Park&Ride Plätzen, wenn Sie diese Verkehrsmittel benutzen. ',
    'no-departures': 'Keine Abfahrten',
    'no-position': 'Kein Standort',
    'no-position-no-stops':
      'Die nächsten Haltestellen können nicht gezeigt werden, da Ihr Standort unbekannt ist.',
    'no-route-already-at-destination': 'Sie sind schon an Ihrem Ziel.',
    'no-route-end': 'Bitte wählen Sie ihr Ziel aus',
    'no-route-end-date-not-in-range':
      'Der aktuelle Fahrplan enthält keine späteren Abfahrten.',
    'no-route-msg':
      'Wir konnten leider keine Reisevorschläge für diese Strecke finden. Bitte versuchen Sie den Abfahrts- oder Zielort zu ändern.',
    'no-route-msg-with-changes':
      'Leider wurden keine Reiseoptionen zu Ihrer Anfrage gefunden. Passen Sie Ihre Einstellungen, Abfahrt- oder Zielort an.',
    'book-locker': 'Stellplatz buchen',
    'no-route-origin-near-destination':
      'Die Entfernung zwischen Start und Ziel ist sehr gering. Bitte ändern Sie einen dieser Orte.',
    'no-route-origin-same-as-destination':
      'Keine Reisevorschläge gefunden, da Abfahrts- und Zielort übereinstimmen.',
    'no-route-showing-alternative-options':
      'Es wurden keine Routen mit öffentlichen Verkehrsmitteln und Ihren Einstellungen gefunden. Prüfen Sie, ob die folgenden Reiseoptionen für Sie passend sind:',
    'no-route-start': 'Bitte wählen Sie den Startort aus',
    'no-route-start-date-too-early':
      'Der aktuelle Fahrplan enthält keine früheren Abfahrten.',
    'no-route-start-end': 'Bitte Start und Ziel auswählen.',
    'no-transfers-margin': 'Keine',
    'no-trips-found': 'Keine Reisen für diesen Tag gefunden.',
    now: 'Jetzt',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Keine Zwischenstopps} one {1 Zwischenstopp} other {{number} Zwischenstopps} }',
    'number-of-minutes':
      '{number, plural, =0 {0 Minuten} one {1 Minute} other {{number} Minuten}}',
    'off-canvas-info':
      'Mit einer Anmeldung können Sie Favoriten speichern und auf anderen Geräten wieder verwenden.',
    'only-public-transport-disclaimer': 'Preisauskunft nur für ÖPNV gültig.',
    'open-24-7': 'Durchgängig geöffnet',
    'option-default': 'Normal',
    'option-least': 'Langsam',
    'option-most': 'Schnell',
    or: 'oder',
    origin: 'Start',
    'origin-label-change': 'Abfahrt ändern',
    'origin-outside-service':
      'Leider konnte keine Route von diesem Startort gefunden werden. Der Startort befindet sich außerhalb des abgedeckten Gebietes. Bitte ändern Sie den Startort.',
    'origin-placeholder': 'Start',
    'other-dates': 'Anderer Zeitraum',
    'route-page.special-routes': 'Geänderte Routenführung',
    'car-sharing-start-using': 'Buchen Sie ein Car-Sharing-Auto',
    'cargo-bike-content-description' : 'Lastenrad Station',
    'cargo-bike-start-using': 'Buchen Sie ein Lastenrad',
    'regiorad-start-using': 'Buchen Sie ein Leihrad',
    'stadtrad-start-using': 'Buchen Sie ein Leihrad',
    'own-position': 'Aktueller Standort',
    'occupancy-status-FEW_SEATS_AVAILABLE': 'Mittlere Auslastung',
    'occupancy-status-MANY_SEATS_AVAILABLE': 'Niedrige Auslastung',
    'occupancy-status-STANDING_ROOM_ONLY': 'Hohe Auslastung',
    'occupancy-status-EMPTY': 'Sehr niedrige Auslastung',
    'position-disclaimer': 'Position ist geschätzt. Letzte bekannte Position von {time} Uhr.',
    'page-not-found': 'Oh! Seite wurde nicht gefunden ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Park-and-Ride',
    'park-and-ride-availability': 'Plätze verfügbar',
    'park-and-ride-unknown': 'Unbekannte Kapazität',
    'pay-attention': 'Achtung',
    'pick-icon': 'Icon auswählen',
    'pick-mode': 'Verkehrsmittel auswählen',
    'pick-mode-airplane-disabled': 'Flug nicht ausgewählt',
    'pick-mode-airplane-enabled': 'Flug ausgewählt',
    'pick-mode-bus-disabled': 'Bus nicht ausgewählt',
    'pick-mode-bus-enabled': 'Bus ausgewählt',
    'pick-mode-citybike-disabled': 'Leihrad nicht ausgewählt',
    'pick-mode-citybike-enabled': 'Leihrad ausgewählt',
    'pick-mode-ferry-disabled': 'Fähre nicht ausgewählt',
    'pick-mode-ferry-enabled': 'Fähre ausgewählt',
    'pick-mode-rail-disabled': 'Zug nicht ausgewählt',
    'pick-mode-rail-enabled': 'Zug ausgewählt',
    'pick-mode-selected': 'Ausgewählte Transportmittel',
    'pick-mode-subway-disabled': 'U-Bahn nicht ausgewählt',
    'pick-mode-subway-enabled': 'U-Bahn ausgewählt',
    'pick-mode-tram-disabled': 'Tram nicht ausgewählt',
    'pick-mode-tram-enabled': 'Tram ausgewählt',
    'pick-street-mode': 'Meine Reiseoptionen',
    'place-route-or-keyword': 'Adresse oder Haltestelle',
    platform: 'Gleis',
    'platform-num': 'Gleis {platformCode}',
    'platform-short': 'Gl. {platformCode}',
    'platform-short-no-num': 'Gl.',
    precipitation: 'Niederschlag',
    'precipitation-hail': 'Hagel',
    'precipitation-icy-rain': 'Eisregen',
    'precipitation-none': 'keiner',
    'precipitation-rain': 'Regen',
    'precipitation-sleet': 'Schneeregen',
    'precipitation-snow': 'Schnee',
    'precipitation-unknown': 'unbekannt',
    'prefer-walking': 'Gehen bevorzugen',
    previous: 'Vorherige',
    print: 'Drucken',
    'print-timetable': 'Fahrplan',
    'public-transport': 'Öffentlicher Nahverkehr',
    rail: 'Zug',
    'rail-with-route-number': 'Zug {routeNumber} {headSign}',
    realtime: 'Echtzeit',
    'realtime-matching': 'Echtzeit-Matching',
    releases: 'Veröffentlichungen',
    'remove-favourite': 'Von Favoriten entfernen',
    'remove-via-button-label': 'Zwischenstopp {index} entfernen',
    'rent-scooter-at': 'Scooter ausleihen:',
    'required-ticket': 'Benötigtes Ticket',
    'return-cycle-to': 'Das Fahrrad zurückgeben:',
    'return-scooter-to': 'Den Scooter zurückgeben:',
    'right-now': 'Jetzt',
    'road-condition-dry': 'trocken',
    'road-condition-frosty': 'Frost/Reif',
    'road-condition-icy': 'Eis',
    'road-condition-moist': 'feucht',
    'road-condition-moist-salty': 'feucht mit Salz',
    'road-condition-snowy': 'Schneet',
    'road-condition-unknown': 'unbekannt',
    'road-condition-wet': 'nass',
    'road-condition-wet-salty': 'nass mit Salz',
    'road-temperature': 'Straßentemperatur',
    'road-weather': 'Straßenwetter',
    route: 'Linie',
    'route-add-viapoint': 'Zwischenstopp',
    'route-custom-settings': 'Eigene Einstellungen',
    'route-default': 'Voreinstellungen',
    'route-destination-arrives': 'Nur Ausstieg',
    'route-destination-endpoint': 'Ankunft / Endstation',
    'route-fastest': 'Schnellste Route',
    'route-from-here': 'Route von hier',
    'route-guide': 'Routenplan',
    'route-here': 'Route hierher',
    'route-least-elevation-changes': 'Höhenunterschiede vermeiden',
    'route-least-transfers': 'Umstiege vermeiden',
    'route-least-walking': 'Wenig zu Fuß',
    'route-not-possible':
      'Leider konnte keine Route berechnet werden. Technischer Fehler: "{error}"',
    'route-page.similar-routes': 'Vielleicht interessiert Sie auch',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Linie {shortName}, {longName}',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Linie',
    'route-to-stop': 'Linie zur Haltestelle',
    routes: 'Linien',
    'routes-platforms': 'Linien, Steige',
    'routes-tracks': 'Linien, Gleise',
    run: 'Schnell',
    save: 'Speichern',
    'scooter-availability': 'Scooter sind jetzt an der Station verfügbar',
    'scooter-distance-duration': 'Scooter {distance} fahren ({duration})',
    'scooter-register-required':
      'Um Scooter nutzen zu können, wird eine Anmeldung benötigt',
    'scooterwalk-distance-duration': 'Scooter {distance} schieben ({duration})',
    search: 'Suchen',
    'search-autosuggest-label':
      'Ort und Haltestellensuche. Liste mit Pfeiltasten navigieren, mit Enter-Taste auswählen',
    'search-autosuggest-len': 'Es sind {len} Vorschläge verfügbar',
    'search-button-label': 'Suchen',
    'search-current-suggestion': 'Aktuelle Auswahl: {selection}',
    'search-destination': 'Suche Ziel, Linie oder Haltestelle',
    'search-destination-index': 'Geben Sie das Ziel ein',
    'search-destination-results-but-no-search':
      'Siehe Ergebnisse im "Zielort"-Tab',
    'search-fields.sr-instructions': 'Die Verbindungssuche wird automatisch ausgelöst, wenn sie Start und Ziel eingegeben oder die Suchparameter verändert haben.',
    'search-no-results': 'Keine Ergebnisse',
    'search-origin': 'Geben Sie Ihren Abfahrtsort ein',
    'search-origin-index': 'Geben Sie Ihren Abfahrtsort ein',
    'search-position': 'Standort suchen',
    'search-search-results-but-no-destination':
      'Siehe Ergebnisse im "Linie oder Haltestelle"-Tab',
    'searching-position': 'Suche aktuellen Standort...',
    'select-date': 'Datum auswählen',
    'select-from-map-destination': 'Ziel wählen',
    'select-from-map-no-title': 'Kein Titel',
    'select-from-map-origin': 'Abfahrsort wählen',
    'select-from-map-viaPoint': 'Zwischenstopp wählen',
    'select-map-layers-header': 'Kartendaten',
    'select-position': 'Standort aus vorherigen Suchen wählen',
    send: 'Senden',
    'separate-ticket-required':
      'Für einen Teil der Route ist ein separates Ticket erforderlich.',
    'separate-ticket-required-disclaimer':
      'Die Route umfasst einen oder mehrere Abschnitte, für die {agencyName} keine Tickets verkauft.',
    'set-specific-settings': 'Setze spezifischere Einstellungen',
    'set-stop-as-routes': "Setzen Sie die Haltestelle der Linie",
    'set-time-earlier-button-label': 'Frühere Reisezeit wählen',
    'set-time-later-button-label': 'Spätere Reisezeit wählen',
    'set-time-now-button-label': 'Reisezeit ab jetzt',
    settings: 'Einstellungen',
    'settings-dropdown-close-label': 'Schließe Einstellungen',
    'settings-dropdown-open-label': 'Öffne Einstellungen',
    'layer-dropdown-close-label': 'Schließe Layer Optionen',
    'layer-dropdown-open-label': 'Öffne Layer Optionen',
    'settings-label-change': 'Einstellungen ändern',
    share: 'Reise teilen',
    show: 'zeigen',
    'show-departures': 'Abfahrten zeigen',
    'show-more': 'Mehr zeigen',
    'show-more-stops-near-you': 'Zeige mehr Haltestellen in der Nähe',
    'show-routes': 'Verbindungen auswählen',
    'skip-positioning': 'Überspringen',
    'skip-to-content': 'Zum Inhalt springen',
    slow: 'Langsam',
    'specify-location': 'Ort eingeben',
    'splash-locating': 'Suche aktuellen Standort...',
    'splash-or-choose': 'oder einen dieser Abfahrtsorte wählen',
    'splash-use-positioning': 'Aktuellen Standort benutzen',
    'splash-welcome': 'Wie möchten Sie starten?',
    'splash-you-can-also': 'Sie können auch',
    station: 'Station',
    stop: 'Haltestelle',
    'stop-departure-time-future': 'Abfahrt ist in {minutes} Minuten',
    'stop-departure-time-past': 'Abfahrtszeit war',
    'stop-list-update.sr-instructions': 'Abfahrtszeiten für jede Haltestelle werden in Echtzeit angezeigt.',
    'stop-near-you-modal-grant-permission': 'oder aktiviere die Ortsbestimmung',
    'stop-near-you-modal-grant-permission-info':
      'Sie können die Standortabfrage Ihres Smartphonse oder Browsers freigeben, so dass dieser Dienst Ihren aktuellen Standort verwenden kann.',
    'stop-near-you-modal-header':
      'Sie haben die Standortabfrage nicht freigegeben.',
    'stop-near-you-modal-info':
      'Zur Nutzung der Suche nach Haltestellen in der Nähe wird Zugriff auf die Standortabfrage benötigt.',
    'stop-near-you-title': 'Suche nach Haltestellen oder Linien',
    'stop-number': 'Haltestellennummer',
    'stop-page.description': 'Haltestelle - {name} {code}, {desc}',
    'stop-page.right-now.loaded': 'Haltestellenseite geladen',
    'stop-page.title': 'Haltestelle - {name} {code}',
    'stop-page.title-short': 'Haltestelle',
    'stop-virtual-monitor': 'Virtueller Monitor',
    stops: 'Haltestellen',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    street: 'Straße',
    'street-mode-bicycle': 'Fahrrad',
    'street-mode-bike-aria':
      'Zeige Fahrradroute. Dauer {duration}, Strecke {length}',
    'street-mode-bikeandvehicle-aria':
      'Zeige Kombination von Fahrrad und ÖPNV. Dauer {duration}, davon {length} per Rad',
    'street-mode-car': 'Auto',
    'street-mode-car_park': 'Park & Ride',
    'street-mode-public_transport': 'ÖPNV',
    'street-mode-walk': 'Zu Fuß',
    'street-mode-walk-aria': 'Zeige Fußweg. Dauer {duration}, Strecke {length}',
    'street-mode-ondemandtaxi-aria': 'Zeige Ruftaxi. Dauer {duration}, Strecke {length}',
    subway: 'U-Bahn',
    'subway-with-route-number': 'U-Bahn {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Reisevorschlag {number}',
    'summary-page.title': 'Reisevorschläge',
    'swap-order-button-label': 'Start und Ziel tauschen',
    'swipe-sr-new-tab-opened': 'Tab {number} geöffnet.',
    'swipe-disruption-info': 'Störungsinformation wische Ergebnistabs',
    'swipe-disruption-info-header': 'Störungsinformation wische Ergebnistabs.',
    'swipe-message-bar': 'Meldungen wische Ergebnistabs',
    'swipe-message-bar-header': 'Meldungen wische Ergebnistabs.',
    'swipe-result-tab-left': 'Drücke Enter or Leertaste, um vorigen Tab anzuzeigen.',
    'swipe-result-tab-right': 'Drücke Enter or Leertaste, um nächsten Tab anzuzeigen',
    'swipe-result-tabs': 'Wechsle mit Pfeiltasten zwischen den Tabs.',
    'swipe-stops-near-you': 'Halte in der Nähe Stops wische Ergebnistabs.',
    'swipe-stops-near-you-header': 'Halte in der Nähe Stops wische Ergebnistabs',
    'swipe-summary-page': 'Reisekette wische Ergebnistabs',
    'swipe-summary-page-header': 'Reisekette wische Ergebnistabs.',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Nutzungsbedingungen',
    'ticket-and-price-info': 'Ticket- und Preisinformation',
    'ticket-single-adult': 'Erwachsener',
    'ticket-type-none': 'Keine Tarifzonenbeschränkung',
    tickets: 'Fahrkarten',
    time: 'Zeit',
    'time-navigation-buttons': 'Zeitnavigation',
    'time-selector-form': 'Zeit bearbeiten',
    'time-selector-hours-label': 'Stunde',
    'time-selector-minutes-label': 'Minute',
    timetable: 'Fahrplan',
    today: 'Heute',
    tomorrow: 'Morgen',
    'to-frontpage': 'Zurück zur Startseite',
    track: 'Gleis',
    'track-num': 'Gleis {platformCode}',
    'track-short': 'Gl. {platformCode}',
    'track-short-no-num': 'Gl.',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfers: 'Umstiege',
    'transfers-allowed': 'Umstiege erlauben',
    'transfers-margin': 'Umstiegszeit',
    'travel-time': '{min} min',
    'travel-time-label': 'Reisezeit',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'CO2-Emissionen der Reise',
    'trip-information': 'Reiseinformationen',
    'trip-page.description': 'Linie {shortName}, {longName}',
    'trip-page.title': 'Linie {shortName}',
    'try-again': 'Nochmal versuchen',
    'use-citybike': 'Leihrad benutzen',
    'use-national-service-postfix': '.',
    'use-national-service-prefix':
      'Wir empfehlen, dass Sie den überregionalen Routenplaner nutzen, ',
    'use-own-position': 'Aktuellen Standort benutzen',
    'using-modes': 'Verkehrsmittel',
    'vehicle-parking-operator': 'Betreiberin: {operator}',
    venue: 'Ort',
    'very-likely': 'Sehr wahrscheinlich',
    'very-unlikely': 'Sehr unwahrscheinlich',
    'via-leg-stop-duration': 'Am Zwischenstopp {stayDuration}',
    'via-point': 'Zwischenstopp',
    'via-point-index': 'Zwischenstopp {index}',
    'viapoint-slack-amount': 'Aufenthalt am Zwischenstopp',
    'view-route': 'Route anzeigen',
    wait: 'Wartezeit',
    'wait-amount-of-time': 'Wartezeit {duration}',
    walk: 'gehen',
    'walk-bike-itinerary-1': 'Ihre Suche ergab nur Fußweg-Routen.',
    'walk-bike-itinerary-2': 'Ihre Suche ergab nur Fahrrad-Routen.',
    'walk-bike-itinerary-3': 'Ihre Suche ergab nur Fußweg- und Fahrrad-Routen.',
    'on-demand-taxi-itinerary': 'Nur Routen mit Ruftaxi-Anteil gefunden.',
    'car-park-ride-itinerary': 'Es wurde keine Route gefunden, die ausschließlich öffentliche Verkehrsmittel nutzt.',
    'walk-distance-duration': 'Gehen {duration} ({distance})',
    walking: 'Gehen',
    'walking-speed': 'Laufgeschwindigkeit',
    'warning-call-agency':
      'Nur auf Nachfrage: {routeName}, muss vorher angefragt werden.',
    'warning-call-agency-no-route':
      'Nur auf Nachfrage. Diese Fahrt muss vorher angefragt werden.',
    weather: 'Wetter',
    'weather-at-destination': 'Wetter am Reiseziel',
    'weather-data-source': 'Quelle: Finnish Meteorological Institute',
    'weather-detail-title': 'Wettervorhersage für den Herkunftsort um',
    'weather-icon-1': 'Klar',
    'weather-icon-2': 'Teilweise bewölkt',
    'weather-icon-21': 'Leichter Schauer',
    'weather-icon-22': 'Schauer',
    'weather-icon-23': 'Starker Schauer',
    'weather-icon-3': 'Bedeckt',
    'weather-icon-31': 'Leichter Regen',
    'weather-icon-32': 'Regen',
    'weather-icon-33': 'Starker Regen',
    'weather-icon-41': 'Leichter Schnee Schauer',
    'weather-icon-42': 'Schnee Schauer',
    'weather-icon-43': 'Starker Schnee Schauer',
    'weather-icon-51': 'Leichter Schneefall',
    'weather-icon-52': 'Schneefall',
    'weather-icon-53': 'Starker Schneefall',
    'weather-icon-61': 'Gewitterschauer',
    'weather-icon-62': 'Schwerer Gewitterschauer',
    'weather-icon-63': 'Gewitter',
    'weather-icon-64': 'Schweres Gewitter',
    'weather-icon-71': 'Leichter Graupelschauer',
    'weather-icon-72': 'Graupelschauer',
    'weather-icon-73': 'Schwere Graupelschauer',
    'weather-icon-81': 'Leichter Graupel',
    'weather-icon-82': 'Graupel',
    'weather-icon-83': 'Starker Graupel',
    'weather-icon-91': 'Nebel',
    'weather-icon-92': 'Schleier',
    where: 'Wohin?',
    yesterday: 'Gestern',
    zone: 'Tarifzone',
    zones: 'Tarifzonen',
    'book-on-demand-taxi': 'Fahrt buchen'
  },

  en: {
    'bicycle-parking-filter': 'Bicycle parking options',
    'bicycle-parking-filter-all': 'All parking spaces',
    'bicycle-parking-filter-freeOnly': 'Only free ones',
    'bicycle-parking-filter-securePreferred': 'Secure preferred',
    'bicycle-parking-shed': 'Bicycle shed',
    'bicycle-parking-lockers': 'Bicycle lockers',
    'bicycle-parking-garage': 'Bicycle parking garage',
    
    'tier_REUTLINGEN-start-using': 'Book a TIER e-scooter',
    'tier_REUTLINGEN-station-no-id': 'TIER e-scooter',
    'tier_REUTLINGEN-availability': 'TIER e-scooters available at the station right now',
    'book-locker': 'Book space',
    'fares-disclaimer': 'Fare information provided by Nahverkehrsgesellschaft Baden-Württemberg mbH (NVBW). No liability for the correctness of the information.',
    imprint: 'Imprint',
    'position-estimated': 'Position estimated. No current position information is available.',
    'position-disclaimer': 'Position is estimated. Last known location at {time}.',
    'direction': 'To ',
    'car-sharing-start-using': 'Book a shared car',
    'regiorad-start-using': 'Book a rental bike',
    'stadtrad-start-using': 'Book a rental bike',
    'cargo-bike': 'Cargo bike',
    'cargo-bike-booking-link' : 'Book',
    'cargo-bike-content-description' : 'Cargo bike station',
    'cargo-bike-start-using': 'Book a cargo bike',
    'cargo-bike-station-no-id': 'Cargo bike rental station',
    'cargo-bikes-capacity-no-data': 'No capacity data available',
    'cargo-bikes-capacity-available': '{available} of {capacity} available',
    'cargo-bikes-capacity-in-total': 'Capacity: {capacity} cargo bike/ cargo bikes',
    'bike-availability': "Bikes available",
    privacy: 'Privacy',
    'regiorad-station-no-id': 'Bike rental station',
    'stadtrad-station-no-id': 'Bike rental station',
    'taxi-station-no-id': 'Taxi rank',
    'car-sharing-station-no-id': 'Car sharing station',
    'report-defect': 'Report defect',
    'map-layer-roadworks': 'Roadworks',
    'weekday-mo': 'Monday',
    'weekday-tu': 'Tuesday',
    'weekday-we': 'Wednesday',
    'weekday-th': 'Thursday',
    'weekday-fr': 'Friday',
    'weekday-sa': 'Saturday',
    'weekday-su': 'Sunday',
    'weekday-ph': 'Public holiday',
    'opening-hours': 'Opening hours',
    'parking-spaces-in-total': '{total} parking spaces',
    'parking-spaces-available': '{free} of {total} parking spaces available',
    open: 'Open',
    closed: 'Closed',
    'map-layer-sharing': 'Car, Bike & Cargo bike sharing',
    'map-layer-category-public-transit': 'Public Transit',
    'map-layer-category-bicycle': 'Bicycle',
    'map-layer-category-sharing': 'Sharing',
    'map-layer-category-car': 'Car',
    'map-layer-category-others': 'Others',
    'about-this-service': 'About the service',
    'car-park-capacity-alert': 'This car park is close to capacity. Please allow additional time for your journey',
    'exclude-full-carparks': 'Exclude full car parks',
    'accept-carpool-policy':
      'I have read and agreed to the <a href="{policyUrl}" target="_blank">privacy policy</a> and <a href="{termsUrl}" target="_blank">terms of use</a>.',
    accessibility: 'Accessibility',
    'accessibility-limited': 'Wheelchair',
    'accessibility-nolimit': 'No limits',
    'accessibility-statement': 'Accessibility statement',
    'add-favourite-bikeStation-failed-heading':
      'Saving the citybike station failed',
    'add-favourite-place-failed-heading': 'Unable to save location',
    'add-favourite-route-failed-heading': 'Saving the route failed',
    'add-favourite-station-failed-heading': 'Saving the station failed.',
    'add-favourite-stop-failed-heading': 'Saving the stop failed',
    'add-home': 'Add home',
    'add-itinerary-via-point': 'Add via point for itinerary',
    'add-location-to-favourites': 'Add an important location to your Favorites',
    'add-to-favourites': 'Add to your Favorites',
    'add-via-button-label': 'Add via point',
    'add-via-duration-button-label': 'Set stop duration at via point {index}',
    'add-via-duration-button-label-close':
      'Close stop duration selector at via point {index}',
    'add-via-duration-button-label-open':
      'Open stop duration selector at via point {index}',
    'add-work': 'Add work',
    'air-temperature': 'Air temperature',
    'alert:bikerental:free-floating-drop-off':
      'Destination is not a designated drop-off area. Rental cannot be completed here. Please check terms & conditions for additional fees.',
    'aria-itinerary-summary':
      'Total journey time {duration}. Departing at {inFuture} {departureTime} and arriving at {arrivalTime}',
    'aria-itinerary-summary-bike-distance':
      'Total cycling {duration}, {distance} ',
    'aria-itinerary-summary-walk-distance':
      'Total walking {duration}, {distance} ',
    'arrive-leave': 'Arrive or leave at selected time',
    'as-destination': 'Destination',
    'as-origin': 'Origin',
    'as-viapoint': 'Viapoint',
    'search-autosuggest-label':
      'Venue, place and stopsearch. Navigate list with arrow keys and select with enter key',
    'search-autosuggest-len':
      'There {len, plural, =1 {is 1 suggestion} other {are {len} suggestions}} available',
    'search-current-suggestion': 'Current selection: {selection}',
    'select-date': 'Select date',
    'select-from-map-destination': 'Select destination',
    'select-from-map-no-title': 'No title',
    'select-from-map-origin': 'Select origin',
    'select-from-map-viaPoint': 'Select viapoint',
    'set-time-earlier-button-label': 'Set travel time to earlier',
    'set-time-later-button-label': 'Set travel time to later',
    'set-time-now-button-label': 'Set travel time to current',
    'time-navigation-buttons': 'Time navigation buttons',
    'time-selector-form': 'Edit time',
    // eslint-disable-next-line sort-keys
    address: 'Address',
    'address-or-stop': 'Address or stop',
    agency: 'Operator',
    airplane: 'Flight',
    'airplane-with-route-number': 'Flight {routeNumber}',
    'airport-check-in':
      'Check-in immediately upon arrival if you have not completed check-in in advance',
    'airport-collect-luggage': 'Collect your luggage',
    'airport-security-check-go-to-gate':
      'Proceed to your gate through security check',
    'all-routes': 'All routes',
    'all-routes-disabled': 'No routes',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arriving',
    'arriving-soon': 'Now',
    'at-time': 'at',
    'avoid-transfers': 'Avoid transfers',
    'avoid-walking': 'Avoid walking',
    'back-button-title': 'Go back to previous page',
    'back-to-front-page': 'Back to the front page',
    bicycle: 'Bike',
    'bicycle-charging-station': 'Bicycle charging station',
    'bicycle-distance-duration': 'Bike {duration} ({distance})',
    'bicycle-walk-from-transit':
      'Take your bike off the {transportMode} and walk it for {distance} ({duration})',
    'bicycle-walk-to-transit':
      'Walk your bike for {distance} ({duration}) and take it on the {transportMode}',
    bicycle_walk: 'Walking a bike',
    "bicycle-parking": "Bicycle parking",
    "bicycle-parking-covered": "covered",
    'regiorad-availability': 'Bikes available at the station right now',
    'stadtrad-availability': 'Bikes available at the station right now',
    'cargo-bike-availability': 'Cargo bikes available at the station right now',
    'taxi-availability': 'Taxis available at the station right now',
    'bike-not-allowed-bus': 'Bikes are not allowed on the bus',
    'bike-not-allowed-tram': 'Bikes are not allowed on the tram',
    'bike-park': 'Park & Ride for bikes',
    'bike-station-disabled': 'Bike station is closed.',
    'bikes-available': '{amount} bikes at the station ',
    'biketransport-citybike': "I'm using a citybike",
    'biketransport-only-bike': "I'm travelling only by bike",
    'biking-amount': 'Amount of biking',
    'biking-speed': 'Biking speed',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Buy tickets',
    call: 'Call',
    cancel: 'Cancel',
    canceled: 'Canceled',
    'canceled-itineraries-amount':
      'Additional {itineraryAmount, plural, =1 {1 canceled itinerary} other {{itineraryAmount} canceled itineraries}}',
    'canceled-itineraries-amount-hide':
      'Hide canceled itineraries ({itineraryAmount})',
    'canceled-legs': 'Canceled departures on the route',
    car: 'Car',
    'car-charging-station': 'Car charging station',
    'car-distance-duration': 'Drive {duration} ({distance})',
    'car-park-full': 'Exclude car parks with few spaces',
    car_park: 'Park & ride',
    carpool: 'Carpool',
    'carpool-distance-duration': 'Ride {duration} ({distance})',
    'carpool-with-route-number': 'Carpool route {routeNumber} to {headsign}',
    'card-header-back-to-result-list' : 'Go back to results',
    cause: 'cause',
    'char-left': 'characters',
    'charging-free': 'Charging is free',
    'charging-not-free': 'Charging subject to a fee',
    'charging-spaces-available': '{available} of {capacity} charging slots available',
    'charging-spaces-in-total': '{capacity} charging slots',
    'charging-spaces-no-data' : 'No capacity data available',
    'choose-stop': 'Select stop',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Sharing',
    'citybike-buy-season': 'Buy a daily, weekly or season pass',
    'citybike-distance-duration': 'Bike {duration} ({distance})',
    'citybike-duration-general-header':
      'Extra charge applies to several sections of route to be completed by a city bike.',
    'citybike-duration-info':
      'You can ride a city bike for up to {duration} minutes at a time, after which you will be charged extra.',
    'citybike-duration-info-header':
      'The section of route to be completed by a city bike takes more than {duration} minutes.',
    'citybike-duration-info-short':
      'Section of route takes more than {duration} min. Extra charge applies.',
    'citybike-network-headers': 'Citybikes',
    'citybike-purchase-link': 'Book',
    'citybike-register-required': 'To use city bikes, you need to register',
    'citybike-return-full': 'Returning a city bike?',
    'citybike-return-full-link':
      'How to return a bike when a bike station is full ›',
    'citybike-start-using': 'Start using rental bikes',
    'citybike-start-using-info': 'More information',
    'citybike-station': 'Bike station {stationId}',
    'citybike-station-no-id': 'Bike station',
    'clear-button-label': 'Clear',
    close: 'Close',
    'close-favourite-module': 'Close the Favorites module',
    'close-settings': 'Close window and save settings',
    'contact-information': '{contactName} contact details',
    continue: 'Continue',
    'create-account': 'Create {contactName} account',
    'cycle-distance-duration': 'Cycle {duration} ({distance})',
    'cyclewalk-distance-duration': 'Walk your bike {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Current position',
    datasources: 'Data sources',
    date: 'Date',
    day: 'Day',
    delete: 'Delete',
    'delete-favourite-failed-heading': 'Unable to delete save',
    'departure-is-canceled': '{modeInfo} {from}–{to} at {time} is cancelled',
    'departure-is-canceled-airplane': 'Flight {shortName}',
    'departure-is-canceled-bus': 'Bus {shortName}',
    'departure-is-canceled-ferry': 'Ferry {shortName}',
    'departure-is-canceled-rail': '{shortName} train',
    'departure-is-canceled-subway': 'Metro service {shortName}',
    'departure-is-canceled-tram': 'Tram {shortName}',
    'departure-list-update.sr-instructions':
      'The list of upcoming departures and departure times will update in real time.',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Departures',
    'departures-by-hour': 'Departures by hour',
    'departures-by-hour-minutes-route': '(minutes/route)',
    description: 'Find public transport services to locations near and far.',
    destination: 'Destination',
    'destination-outside-service':
      'No route suggestions were found because the destination is outside the service area.',
    'destination-placeholder': 'Enter destination, route or stop',
    'dialog-return-to-map': 'Return to map',
    'digitransit-platform': 'Digitransit service platform',
    disclaimer:
      'Please note that the results are based on estimated travel times. The suggested transport connections cannot be guaranteed.',
    disruption: 'Disruption',
    'disruption-info': 'Disruption info',
    'disruption-info-no-alerts': 'No known disruptions or diversions.',
    'disruption-info-route-no-alerts':
      'No known disruptions or diversions to the route.',
    disruptions: 'Disruptions',
    'disruptions-and-diversions': 'Disruptions and diversions',
    'disruptions-tab.sr-disruptions': 'One or more known disruptions',
    'disruptions-tab.sr-no-disruptions': 'No known disruptions',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-total': 'Total distance',
    'distance-under': 'Distance less than {distance} m',
    earlier: 'Earlier departures',
    'edit-favourites': 'Edit the location in the Favorites',
    'elevation-gained-total': 'Elevation gained',
    'elevation-lost-total': 'Elevation lost',
    explanations: 'Explanations',
    'extra-info': 'More info',
    'favourite-failed-text': 'Please try again in a while.',
    'favourite-target': 'Favorite location',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    'fetch-new-route': 'Fetch a new route',
    'from-ferry': 'ferry',
    'from-rail': 'train',
    'from-station': 'from station',
    'from-stop': 'from stop',
    'from-subway': 'subway',
    frontpage: 'Frontpage',
    'generic-cancelation': '{mode} {route} {headsign} at {time} is cancelled.',
    'generic-error': 'There was an error',
    'geolocate-yourself': 'Detect your location',
    'geolocation-denied-heading': 'Geolocation denied',
    'geolocation-denied-text':
      'You have not given a permission to use your geolocation. You can allow it from the phone or browser settings.',
    'geolocation-failed-heading': 'Geolocation failed',
    'geolocation-failed-text':
      'Your browser does not support location retrieval.',
    'geolocation-prompt-text':
      'Accept your browser’s request to access your location.',
    'geolocation-timeout-heading':
      'Detecting your location is taking longer than expected.',
    'geolocation-timeout-text':
      'Have you accepted your browser’s request to access your location?',
    'give-destination': 'Enter your destination',
    'give-name-to-location': 'Give the location a descriptive name',
    'give-origin': 'Enter your origin.',
    'give-position': 'Enter your location or origin in the search field.',
    'give-route': 'Search route',
    hour: 'Hour',
    'hour-short': 'h',
    'how-to-rate-service': 'How would you rate the service?',
    'in-addition': 'In addition',
    'index.title': 'Journey Planner',
    inquiry: 'How did you find the new Journey Planner? Please tell us!',
    instructions: 'Instructions',
    'itinerary-details.biking-leg':
      'At {time} cycle {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.car-leg':
      'At {time} drive {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.end-leg':
      'At {time} arrive to destination {destination}.',
    'itinerary-details.route-has-info-alert': 'Route has alerts.',
    'itinerary-details.route-has-severe-alert': 'Route has disruptions.',
    'itinerary-details.route-has-unknown-alert': 'Route has disruptions.',
    'itinerary-details.route-has-warning-alert': 'Route has disruptions.',
    'itinerary-details.scooter-leg':
      'At {time} ride your kick scooter {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-details.transit-leg-part-1': 'At {time} take',
    'itinerary-details.transit-leg-part-2':
      'from stop {startStop} {startZoneInfo} {trackInfo} to stop {endStop} {endZoneInfo}. Estimated duration {duration}',
    'itinerary-details.walk-leg':
      'At {time} walk {distance} from {origin} to {destination}. Estimated time {duration}',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Couldn’t find what you were looking for?',
    'itinerary-feedback-placeholder': 'Description (optional)',
    'itinerary-hide-stops': 'Hide stops',
    'itinerary-page.description': 'Itinerary',
    'itinerary-page.hide-details': 'Hide itinerary details',
    'itinerary-page.itineraries-loaded': 'More search results downloaded',
    'itinerary-page.loading-itineraries': 'Downloading more search results',
    'itinerary-page.show-details': 'Show itinerary details',
    'itinerary-page.show-details-label': 'Show itinerary {number} details',
    'itinerary-page.title': 'Itinerary',
    'itinerary-page.update-alert': 'Search results updated',
    'itinerary-summary-page-street-mode.update-alert':
      'Walking and biking results updated',
    'itinerary-summary-page.description': 'Route suggestions',
    'itinerary-summary-page.title': 'Route suggestions',
    'itinerary-summary-row.biking-distance':
      'Total biking distance {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Show on map',
    'itinerary-summary-row.description':
      'Itinerary departing at {departureDate} {departureTime} and arriving at {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Total time {totalTime}.',
    'itinerary-summary-row.first-departure':
      '{vehicle} leaves at {departureTime} from stop {stopName}.',
    'itinerary-summary-row.first-leg-start-time':
      'Leaves at {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}{firstDeparturePlatform}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Departure at {firstDepartureTime} from {firstDepartureStop} bike station',
    'itinerary-summary-row.no-transit-legs': 'Leave when it suits you',
    'itinerary-summary-row.transfers':
      'Transfer to {vehicle} on stop {stopName}',
    'itinerary-summary-row.walking-distance':
      'Total walking distance {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Take your bike with you onboard',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Take your bike with you on the train or to metro',
    'itinerary-summary.bikeAndPublic-rail-title':
      'Take your bike with you on the train',
    'itinerary-summary.bikeAndPublic-subway-title': 'Take your bike to metro',
    'itinerary-summary.bikePark-title': 'Leave your bike at a Park & Ride',
    'itinerary-summary.interline-wait':
      'The route number ({shortName}) and destination ({destination}) will change at the {stop} stop. Waiting time at the stop is {time}. Please wait onboard.',
    'itinerary-summary.show-on-map': 'Show on map {target}',
    'itinerary-ticket.title': 'Required ticket',
    'itinerary-tickets.title': 'Required tickets',
    'itinerary-time.title': 'Duration',
    'itinerary-walk.title': 'Total walking distance',
    'journeyplanner.title': 'HSL Journey Planner',
    'last-updated': 'Last updated {time}',
    later: 'Later departures',
    leaves: 'Leaves',
    'leaving-at': 'Leaving at',
    'likely-to-recommend':
      'How likely are you to recommend our service to your friends or colleagues?',
    'likely-to-use':
      'How likely are you to use this service rather than the current Journey Planner?',
    loading: 'Loading...',
    localadmin: 'Locality',
    locate: 'Detect location',
    'location-examples': 'e.g. Home, Work, School,...',
    'location-from-map': 'Selected location',
    'location-from-map-confirm': 'Confirm selection',
    'location-home': 'Home',
    'location-school': 'School',
    'location-shopping': 'Shopping',
    'location-sport': 'Sports',
    'location-work': 'Work',
    login: 'Log in',
    'login-content':
      'To save and utilize places, routes and stops, you must log in using your HSL account.',
    'login-header': 'Log in First',

    logout: 'Log out',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Close the main menu',
    'main-menu-label-open': 'Open the main menu',
    'main-mode': "I'm traveling by",
    map: 'Map',
    'map-layer-charging-stations': 'Charging stations',
    'map-layer-citybike': 'Citybike stations',
    'map-layer-park-and-ride': 'Park & ride sites',
    'map-layer-bike-parks': 'Bike parking spaces',
    'map-layer-stop-bus': 'Bus stops',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-tram': 'Tram stops',
    'map-layer-terminal-bus': 'Bus terminals',
    'map-layer-terminal-rail': 'Train stations',
    'map-layer-terminal-subway': 'Metro stations',
    'map-layer-vehicles': 'Bus positions',
    'map-layer-dynamic-parking-lots': 'Parking spaces',
    'menu-faq': 'FAQ',
    'menu-feedback': 'Submit feedback',
    'menu-link-to-privacy-policy': 'Privacy policy',
    'messagebar-label-close-message-bar': 'Close banner',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Route',
    'missing-price-info-disclaimer': 'No price information',
    'more-departures': 'More departures',
    'more-settings': 'More settings',
    'move-on-map': 'Move on the map',
    'move-to-tab': 'Go to tab {number}',
    navigate: 'Navigate',
    nearest: '{ mode } near you',
    'nearest-favorites': 'Stops nearby me',
    'nearest-favorites-browse-stops': 'Browse and select stops',
    'nearest-favorites-no-favorites':
      'Timetables for the selected stops and vehicles on map',
    'nearest-no-stops':
      'There are no stops or stations for the selected mode of transport nearby.',
    'nearest-stops': 'The closest stops',
    'nearest-stops-airplane': 'airports',
    'nearest-stops-bus': 'bus stops',
    'nearest-stops-carpool': 'carpool',
    'nearest-stops-citybike': 'bike stations',
    'nearest-stops-favorite': 'stops nearby me',
    'nearest-stops-ferry': 'ferry piers',
    'nearest-stops-rail': 'train stations',
    'nearest-stops-subway': 'metro stations',
    'nearest-stops-tram': 'tram stops',
    'nearest-stops-update-location': 'Update {mode}',
    'nearest-stops-updated-location': 'Location on the map has changed',
    'network-error': 'Network error',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Neighbourhood ',
    next: 'Next',
    'no-bike-allowed-popup-train':
      'Bicycles are allowed on commuter trains but on long distance trains you have to pay an extra fee and it might require a reservation.',
    'no-bike-allowed-popup-tram-bus':
      'Bicycles are not allowed on buses or trams. If you are using these vehicles on your route, leave the bicycle at the stop or at a Park-and-Ride. ',
    'no-departures': 'No departures',
    'no-position': 'No location',
    'no-position-no-stops':
      'Your location is not available. We are unable to show the closest stops.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end': 'Please select destination',
    'no-route-end-date-not-in-range':
      'The timetable does not include later departures.',
    'no-route-msg': 'Unfortunately, no route suggestions were found.',
    'no-route-msg-with-changes':
      'Unfortunately, no route suggestions were found. Please check your search settings or try changing the origin or destination.',
    'no-route-origin-near-destination':
      'No route suggestions were found because the origin and destination are very close to each other.',
    'no-route-origin-same-as-destination':
      'No route suggestions were found because the origin and destination are the same.',
    'no-route-showing-alternative-options':
      'Only routes without public transport were found with your settings. However, we suggest you consider the following route options:',
    'no-route-start': 'Please select origin',
    'no-route-start-date-too-early': 'There are no earlier departures.',
    'no-route-start-end': 'Please select origin and destination',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'No journeys found for the selected date.',
    now: 'Now',
    'number-of-intermediate-stops':
      '{number, plural, =0 {No stops} one {1 stop} other {{number} stops} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minutes} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      'Log in to the service to save your favorites and utilize them on other devices',
    'occupancy-status-FEW_SEATS_AVAILABLE': 'medium occupancy',
    'occupancy-status-MANY_SEATS_AVAILABLE': 'low occupancy',
    'occupancy-status-STANDING_ROOM_ONLY': 'high occupancy',
    'occupancy-status-EMPTY': 'empty',
    'only-public-transport-disclaimer': 'Price only valid for public transport part of the journey.',
    'open-24-7': 'Open 24/7',
    'option-default': 'Average',
    'option-least': 'Slow',
    'option-less': 'Calm',
    'option-more': 'Prompt',
    'option-most': 'Fast',
    or: 'or',
    origin: 'Origin',
    'origin-outside-service':
      'No route suggestions were found because the origin is outside the service area.',
    'origin-placeholder': 'From? - address or stop',
    'other-dates': 'Other dates',
    'own-position': 'Your current location',
    'page-not-found': 'The page cannot be found.',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Spaces available',
    'pay-attention': 'N.B.',
    'pick-icon': 'Select icon',
    'pick-mode': 'Transport modes',
    'pick-mode-airplane-disabled': 'Flight not selected',
    'pick-mode-airplane-enabled': 'Flight selected',
    'pick-mode-bus-disabled': 'Bus not selected',
    'pick-mode-bus-enabled': 'Bus selected',
    'pick-mode-citybike-disabled': 'Citybike not selected',
    'pick-mode-citybike-enabled': 'Citybike selected',
    'pick-mode-ferry-disabled': 'Ferry not selected',
    'pick-mode-ferry-enabled': 'Ferry selected',
    'pick-mode-rail-disabled': 'Rail not selected',
    'pick-mode-rail-enabled': 'Rail selected',
    'pick-mode-selected': 'Selected transport modes',
    'pick-mode-subway-disabled': 'Metro not selected',
    'pick-mode-subway-enabled': 'Metro selected',
    'pick-mode-tram-disabled': 'Tram not selected',
    'pick-mode-tram-enabled': 'Tram selected',
    'pick-street-mode': 'My modes of transport',
    'place-route-or-keyword': 'Destination, route or stop',
    platform: 'Platform',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'platform-short-no-num': 'Plat.',
    position: 'Position',
    precipitation: 'Precipitation',
    'precipitation-hail': 'hail',
    'precipitation-icy-rain': 'sleet',
    'precipitation-none': 'none',
    'precipitation-rain': 'rain',
    'precipitation-sleet': 'sleet',
    'precipitation-snow': 'snow',
    'precipitation-unknown': 'unknown',
    'prefer-walking': 'Prefer walking',
    previous: 'Previous',
    print: 'Print',
    'print-timetable': 'Weekly timetable',
    'public-transport': 'Public transport',
    rail: 'Commuter train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    'read-more': 'Read more',
    realtime: 'Real-time',
    'realtime-matching': 'Real-time integration',
    releases: 'Releases',
    'remove-favourite': 'Remove from favorites',
    'remove-via-button-label': 'Remove via point {index}',
    'rent-cycle-at': 'Fetch a rental bike:',
    'rent-scooter-at': 'Rent a kick scooter:',
    'required-ticket': 'Ticket required for the journey',
    'return-cycle-to': 'Return the bike:',
    'return-scooter-to': 'Return the kick scooter:',
    'right-now': 'Right now',
    'road-condition-dry': 'dry',
    'road-condition-frosty': 'frost',
    'road-condition-icy': 'icy',
    'road-condition-moist': 'moist',
    'road-condition-moist-salty': 'moist/salty',
    'road-condition-snowy': 'snow',
    'road-condition-unknown': 'unknown',
    'road-condition-wet': 'wet',
    'road-condition-wet-salty': 'wet/salty',
    'road-temperature': 'Road temperature',
    'road-weather': 'road weather',
    route: 'Route',
    'route-add-viapoint': 'Via point',
    'route-custom-settings': 'Custom settings',
    'route-default': 'Default settings',
    'route-destination-arrives': 'Drop-off only',
    'route-destination-endpoint': 'Arrives / Terminus',
    'route-fastest': 'Fast route',
    'route-from-here': 'Origin',
    'route-guide': 'Route guide',
    'route-here': 'Destination',
    'route-least-elevation-changes': 'Avoid changes in elevation',
    'route-least-transfers': 'Avoid transfers',
    'route-least-walking': 'Avoid walking',
    'route-not-possible':
      'Unfortunately, your route is not available. Technical error: "{error}"',
    'route-page-vehicle-position':
      '{mode} {shortName} is at the {stopName} stop',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between the {stopName} stop and the {nextStopName} stop.',
    'route-page.description': 'Route {shortName} - {longName}',
    'route-page.future-routes': 'Future routes',
    'route-page.pattern-chosen': 'Selected',
    'route-page.pattern-select-title': 'Select the route to be viewed',
    'route-page.similar-routes': 'You might also be interested in',
    'route-page.special-routes': 'Other routes',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Route',
    'route-to-stop': 'Route to the stop',
    routes: 'Routes',
    'routes-platforms': 'Routes and platforms',
    'routes-tracks': 'Routes and tracks',
    run: 'Run',
    save: 'Save',
    'scooter-availability': 'Kick scooters available at the station right now',
    'scooter-distance-duration':
      'Ride your kick scooter {duration} ({distance})',
    'scooter-register-required': 'To use kick scooters, you need to register',
    'scooterwalk-distance-duration':
      'Walk your kick scooter {duration} ({distance})',
    search: 'Search',
    'search-button-label': 'Search',
    'search-destination': 'Search destination, route or stop',
    'search-destination-index': 'Enter destination',
    'search-destination-results-but-no-search':
      'View results in the adjacent “Destination” tab',
    'search-fields.sr-instructions':
      'Route search will take place automatically when you enter origin and destination. Changing search parameters will trigger a new search.',
    'search-no-results': 'No location',
    'search-origin': 'Search origin, route or stop',
    'search-origin-index': 'Enter origin',
    'search-position': 'Detect location',
    'search-search-results-but-no-destination':
      'View results in the adjacent “About the route or stop” tab',
    'searching-position': 'Detecting location...',
    'select-all-routes': 'Select all routes',
    'select-map-layers-header': 'Map data',
    'select-position': 'Select your location from your previous searches',
    'select-route': 'Select {mode} route {shortName} to {headsign}',
    'selected-routes': 'Selected lines',
    send: 'Send',
    'separate-ticket-required':
      'A separate ticket is required for this part of the itinerary.',
    'separate-ticket-required-disclaimer':
      'This itinerary contains one or more legs for which {agencyName} does not sell tickets.',
    'set-specific-settings': 'Set more specific settings',
    'set-stop-as-routes': "Set the stop as route's",
    settings: 'Settings',
    'settings-dropdown-close-label': 'Close options',
    'settings-dropdown-open-label': 'Open options',
    'layer-dropdown-close-label': 'Close layer options',
    'layer-dropdown-open-label': 'Open layer options',
    'settings-label-change': 'Change settings',
    share: 'Share itinerary',
    show: 'Show',
    'show-departures': 'Show departures',
    'show-more': 'Show more',
    'show-more-stops-near-you': 'Show more stops near you',
    'show-routes': 'Choose routes',
    'skip-positioning': 'Skip',
    'skip-to-content': 'Skip to content',
    slow: 'Slow',
    'specify-location': 'Specify location',
    'splash-locating': 'Detecting location',
    'splash-or-choose': 'or select your origin',
    'splash-use-positioning': 'Use location services',
    'splash-welcome': 'How do you wish to start?',
    'splash-you-can-also': 'or',
    station: 'Station',
    stop: 'Stop',
    'stop-departure-time-future': 'Departure time is in {minutes} minutes',
    'stop-departure-time-past': 'Departure time was',
    'stop-list-update.sr-instructions':
      'Departure times for each stop will update in real time.',
    'stop-near-you': 'Search stops and routes near you',
    'stop-near-you-modal-grant-permission': 'or enable location services',
    'stop-near-you-modal-grant-permission-info':
      'You can enable location services in your phone or browser settings, allowing the service to use your current location.',
    'stop-near-you-modal-header': 'You have not enabled location services',
    'stop-near-you-modal-info':
      'Using the nearby stops function requires location access.',
    'stop-near-you-title': 'Search for stops and routes',
    'stop-near-you-update-alert': 'Nearest stops updated',
    'stop-number': 'Stop number',
    'stop-page.description': 'Stop {name} - {code}',
    'stop-page.right-now.loaded': 'stop page loaded',
    'stop-page.title': 'Stop {name} - {code}',
    'stop-page.title-short': 'Stop',
    'stop-virtual-monitor': 'Virtual monitor',
    stops: 'Stops',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    'street-mode-bicycle': 'Bicycle',
    'street-mode-bike-aria':
      'Show cycle route. Duration {duration}, distance {length}',
    'street-mode-bikeandvehicle-aria':
      'Show a combination of cycling and public transport. Duration {duration}, distance {length}',
    'street-mode-car': 'Car',
    'street-mode-car_park': 'Park & ride',
    'street-mode-public_transport': 'Public transport',
    'street-mode-walk': 'Walking',
    'street-mode-walk-aria':
      'Show walking route. Duration {duration}, distance {length}',
    'street-mode-ondemandtaxi-aria':
      'Show on demand taxi route. Duration {duration}, distance {length}',
    // eslint-disable-next-line sort-keys
    street: 'Street',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Itinerary suggestion {number}',
    'summary-page.title': 'Itinerary suggestions',
    'swap-order-button-label': 'Reverse origin and destination',
    'swipe-disruption-info': 'Disruption info  swipe result tabs',
    'swipe-disruption-info-header': 'Disruption info swipe result tabs.',
    'swipe-message-bar': 'Message swipe result tabs',
    'swipe-message-bar-header': 'Message swipe result tabs.',
    'swipe-result-tab-left': 'Press Enter or Space to show the previous tab.',
    'swipe-result-tab-right': 'Press Enter or Space to show the next tab.',
    'swipe-result-tabs': 'Switch tabs using arrow keys.',
    'swipe-sr-new-tab-opened': 'Tab {number} opened.',
    'swipe-stops-near-you': 'Stops near you swipe result tabs.',
    'swipe-stops-near-you-header': 'Stops near you swipe result tabs',
    'swipe-summary-page': 'Itinerary swipe result tabs',
    'swipe-summary-page-header': 'Itinerary swipe result tabs.',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Terms of Use',
    'ticket-and-price-info': 'Ticket and price information',
    'ticket-single-adult': 'Adult',
    'ticket-type-none': 'No limits',
    tickets: 'Tickets',
    time: 'Time',
    'time-selector-hours-label': 'Hour',
    'time-selector-minutes-label': 'Minute',
    timetable: 'Timetable',
    'to-frontpage': 'To the front page',
    today: 'Today',
    tomorrow: 'Tomorrow',
    track: 'Track',
    'track-num': 'Track {platformCode}',
    'track-short': 'Track {platformCode}',
    'track-short-no-num': 'Track',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfers: 'Number of transfers',
    'transfers-allowed': 'Transfers allowed',
    'transfers-margin': 'Transfer margin at least',
    'travel-time': '{min} min',
    'travel-time-label': 'Travel time',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'CO2 emissions of the journey',
    'trip-information': 'Trip information',
    'trip-page.description': 'Route {route_short_name} - {route_long_name}',
    'trip-page.title': 'Route {route_short_name}',
    'try-again': 'Try again',
    'use-citybike': 'Start using',
    'use-national-service-postfix': '.',
    'use-national-service-prefix':
      'We recommend you try the national journey planner, ',
    'use-own-position': 'Use current location',
    userinfo: 'My information',
    usermenu: 'User menu',
    'using-modes': 'I want to travel by',
    'vehicle-parking-operator': 'Operator: {operator}',
    'very-likely': 'Very likely',
    'very-unlikely': 'Very unlikely',
    'via-leg-stop-duration': 'At via point {stayDuration}',
    'via-point': 'Via point',
    'via-point-index': 'Via point {index}',
    'viapoint-slack-amount': 'Stop duration',
    'view-route': 'View Route',
    // eslint-disable-next-line sort-keys
    venue: 'Venue',
    wait: 'Wait',
    'wait-amount-of-time': 'Wait {duration}',
    walk: 'walking',
    'walk-bike-itinerary-1': 'Your search returned only walking routes.',
    'walk-bike-itinerary-2': 'Your search returned only cycling routes.',
    'walk-bike-itinerary-3':
      'Your search returned only walking and cycling routes.',
    'on-demand-taxi-itinerary': 'Only routes involving on-demand taxis found.',
    'car-park-ride-itinerary': 'No route using public transport only found.',
    'walk-distance-duration': 'Walk {duration} ({distance})',
    walking: 'Amount of walking',
    'walking-speed': 'Walking speed',
    'warning-call-agency':
      'Only on demand: {routeName}, which needs to be booked in advance.',
    'warning-call-agency-no-route':
      'Only on demand. Needs to be booked in advance.',
    weather: 'Weather',
    'weather-at-destination': 'Weather at the destination',
    'weather-data-source': 'Source: Finnish Meteorological Institute',
    'weather-detail-title': 'Weather forecast for the place of origin at',
    'weather-icon-1': 'Clear',
    'weather-icon-2': 'Partly cloudy',
    'weather-icon-21': 'Light showers',
    'weather-icon-22': 'Showers',
    'weather-icon-23': 'Heavy showers',
    'weather-icon-3': 'Overcast',
    'weather-icon-31': 'Light rain',
    'weather-icon-32': 'Rain',
    'weather-icon-33': 'Heavy rain',
    'weather-icon-41': 'Light snow showers',
    'weather-icon-42': 'Snow showers',
    'weather-icon-43': 'Heavy snow showers',
    'weather-icon-51': 'Light snowfall',
    'weather-icon-52': 'Snowfall',
    'weather-icon-53': 'Heavy snowfall',
    'weather-icon-61': 'Thundershowers',
    'weather-icon-62': 'Heavy thundershowers',
    'weather-icon-63': 'Thunder',
    'weather-icon-64': 'Heavy thunder',
    'weather-icon-71': 'Light sleet showers',
    'weather-icon-72': 'Sleet showers',
    'weather-icon-73': 'Heavy sleet showers',
    'weather-icon-81': 'Light sleet',
    'weather-icon-82': 'Sleet',
    'weather-icon-83': 'Heavy sleet',
    'weather-icon-91': 'Mist',
    'weather-icon-92': 'Fog',
    where: 'Where to?',
    yesterday: 'Yesterday',
    zone: 'Zone',
    'zone-info': 'Zone {zone}',
    'zone-unknown': 'Unknown zone',
    zones: 'Fare zones',
  },

  es: {
    'about-this-service': 'Sobre el servicio',
    accessibility: 'Accesibilidad',
    'accessibility-limited': 'Silla de ruedas',
    'accessibility-nolimit': 'Sin límites',
    'add-itinerary-via-point': 'Añadir punto intermedio al itinerario',
    'add-location-to-favourites':
      'Añadir una importante localización a tus Favoritos',
    address: 'Dirección',
    'address-or-stop': 'Dirección o parada',
    agency: 'Operador',
    airplane: 'Vuelo',
    'airplane-with-route-number': 'Vuelo {routeNumber}',
    'airport-check-in': 'Facture en el mostrador de {agency}',
    'airport-collect-luggage': 'Recoja su equipaje',
    'airport-security-check-go-to-gate':
      'Proceda a su puerta a través del control de seguridad',
    'all-routes': 'Todas las rutas',
    'all-routes-disabled': 'Ninguna ruta',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Llegada',
    'arriving-soon': 'Ahora',
    'avoid-transfers': 'Evitar transbordos',
    'avoid-walking': 'Evitar andar',
    'back-to-front-page': 'Volver a la página principal',
    bicycle: 'bicicleta',
    'bicycle-distance-duration': 'Bicicleta {duration} ({distance})',
    'bike-availability': 'Bicicletas disponibles actualmente en la estación',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Cómo comprar un ticket',
    call: 'Llamar',
    cancel: 'Cancelar',
    car: 'Coche',
    'car-distance-duration': 'Conducir {duration} ({distance})',
    car_park: 'park & ride',
    cause: 'causa',
    'char-left': 'carácteres',
    'choose-stop': 'Seleccione parada',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Bicicleta pública',
    'citybike-distance-duration': 'Bicicleta {duration} ({distance})',
    'citybike-register-required':
      'Para usar la red de bicicleta pública, necesita registrarse.',
    close: 'Cerrar',
    'contact-information': '{contactName} detalles de contacto',
    'create-account': 'Crear cuenta {contactName}',
    'cycle-distance-duration': 'Ir en bici {duration} ({distance})',
    'cyclewalk-distance-duration':
      'Anda junto a tu bici {duration} ({distance})',
    datasources: 'Fuentes de datos',
    date: 'Fecha',
    delete: 'Eliminar',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Salidas',
    description:
      'Encuentre servicios de transporte público a localizaciones cercanas y lejanas.',
    destination: 'Destino',
    'destination-outside-service':
      'Desafortunadamente no hemos encontrado rutas para su viaje. Su destino está fuera del área de servicio. Por favor cambie el destino.',
    'destination-placeholder': 'Introduzca destino, ruta or parada',
    'digitransit-platform': 'Plataforma del servicio Digitransit',
    disruption: 'Incidencia',
    'disruption-info': 'Información sobre incidencias',
    'disruption-info-no-alerts': 'No hay desvíos o incidencias conocidos.',
    'disruption-info-route-no-alerts':
      'No hay desvíos o incidencias conocidos en la ruta.',
    disruptions: 'Incidencias',
    'distance-between': 'Distancia {distance1} m — {distance2} m',
    'distance-under': 'Distancia menor que {distance} m',
    earlier: 'Antes',
    'edit-favourites': 'Edite la localización en los Favoritos',
    explanations: 'Explicaciones',
    'extra-info': 'Más información',
    'favourite-target': 'Localización favorita',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    frontpage: 'Página principal',
    'generic-error': 'Ha habido un error',
    'geolocate-yourself': 'Detecte su localización',
    'geolocation-denied-heading': 'Geolocalización denegada',
    'geolocation-denied-text':
      'No ha dado permiso para usar su geolocalización. Puede permitirlo desde los ajustes de su teléfono o navegador web.',
    'geolocation-failed-heading': 'Geolocalización fallida',
    'geolocation-failed-text':
      'Su navegador no soporta la obtención de localización.',
    'geolocation-prompt-text':
      'Acepte la petición de su navegador para acceder a su localización.',
    'geolocation-timeout-heading':
      'Detectar su localización está llevando más tiempo del esperado.',
    'geolocation-timeout-text':
      '¿Ha aceptado la petición de su navegador para acceder a su localización?',
    'give-destination': 'Busque destino',
    'give-name-to-location': 'De un nombre descriptivo a la localización',
    'give-origin': 'Introduzca su origen.',
    'give-position':
      'Introduzca su localización u origen en el cuadro de búsqueda.',
    hour: 'Hora',
    'hour-short': 'h',
    'how-to-rate-service': '¿Qué nota le pondría al servicio?',
    'in-addition': 'Como añadido',
    inquiry: '¿Qué le ha parecido el nuevo ruteador? ¡Cuéntenos!',
    instructions: 'Instrucciones',
    'itinerary-feedback-button': 'Enviar comentarios',
    'itinerary-feedback-message': '¿No encuentra lo que estaba buscando?',
    'itinerary-feedback-placeholder': 'Descripción (opcional)',
    'itinerary-hide-stops': 'Ocultar paradas',
    'itinerary-page.description': 'Itinerario',
    'itinerary-page.title': 'Itinerario',
    'itinerary-summary-page.description': 'Sugerencias de ruta',
    'itinerary-summary-page.title': 'Sugerencias de ruta',
    'itinerary-ticket.title': 'Ticket requerido',
    'itinerary-tickets.title': 'Tickets requeridos',
    'itinerary-time.title': 'Duración',
    'itinerary-walk.title': 'Distancia total caminando',
    'journeyplanner.title': 'Ruteador de HSL',
    later: 'Después',
    leaves: 'Sale',
    'leaving-at': 'Sale a las',
    'likely-to-recommend':
      '¿Recomendaría el servicio a sus amigos o conocidos?',
    'likely-to-use': '¿Usaría este servicio en vez del ruteador actual?',
    loading: 'Cargando...',
    locate: 'Detectando localización',
    'location-examples': 'p.ej. Casa, Trabajo, Colegio,...',
    'location-from-map': 'Localización seleccionada',
    login: 'Iniciar sesión',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Cerrar el menú principal',
    'main-menu-label-open': 'Abrir el menú principal',
    'main-mode': 'Viajo mediante',
    map: 'Mapa',
    'menu-feedback': 'Envíe comentarios',
    'menu-link-to-privacy-policy': 'Política de privacidad',
    'messagebar-label-close-message-bar': 'Cerrar el banner',
    'messagebar-label-page': 'Página',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Ruta',
    'more-settings': 'Más configuración',
    navigate: 'Navegar',
    nearest: 'Más cercano',
    'network-error': 'Error de red',
    next: 'Siguiente',
    'no-position': 'Sin localización',
    'no-position-no-stops':
      'Su localización no está disponible. No podemos enseñarle las paradas cercanas.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Los horarios actuales no incluyen rutas saliendo más tarde.',
    'no-route-msg':
      'Desafortunadamente no se encontraron rutas para su viaje. Por favor cambie el origen o el destino.',
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'The origin and the destination are close to each other. Please change either location.',
    'no-route-start-date-too-early':
      'Los horarios actuales no incluyen rutas saliendo antes.',
    'no-route-start-end': 'Por favor seleccione origen y destino.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'No se han encontrado viajes para la fecha seleccionada.',
    now: 'Ahora',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Sin paradas} one {1 parada} other {{number} paradas} }',
    'off-canvas-info':
      'Inicie sesión para guardar sus favoritos y utilizarlos en otros dispositivos',
    or: 'o',
    origin: 'Origen',
    'origin-outside-service':
      'Desafortunadamente no hemos encontrado rutas para su viaje. Su origen está fuera del área de servicio. Por favor cambie el destino.',
    'origin-placeholder': '¿Desde? - dirección o parada',
    'own-position': 'Su posición actual',
    'page-not-found': 'La página no se puede encontrar.',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Espacios disponibles',
    'pay-attention': 'N.B.',
    'pick-icon': 'Seleccione icono',
    'place-route-or-keyword': 'Destino, ruta o parada',
    'platform-num': 'Platforma {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'platform-short-no-num': 'Plat.',
    'prefer-walking': 'Preferir andar',
    previous: 'Anterior',
    print: 'Imprimir',
    rail: 'Tren',
    'rail-with-route-number': 'Tren {routeNumber} {headSign}',
    realtime: 'Tiempo real',
    'realtime-matching': 'Integración del tiempo real',
    'rent-cycle-at': 'Alquile una bici en la estación {station}',
    'required-ticket': 'Ticket requerido para el viaje',
    'right-now': 'Ahora mismo',
    route: 'Ruta',
    'route-default': 'Configuración por defecto',
    'route-destination-arrives': 'Llegada / Fin de línea',
    'route-fastest': 'Ruta rápida',
    'route-from-here': 'Ruta desde aquí',
    'route-guide': 'Guía de la ruta',
    'route-here': 'Ruta hacia aquí',
    'route-least-transfers': 'Evitar transbordos',
    'route-least-walking': 'Evitar andar',
    'route-not-possible':
      'Desafortunadamente, su ruta no está disponible. Error: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Ruta {shortName} - {longName}',
    'route-page.title': 'Ruta {shortName}',
    'route-page.title-short': 'Ruta',
    routes: 'Rutas',
    run: 'Correr',
    save: 'Guardar',
    search: 'Buscar',
    'search-destination': 'Busque destino, ruta o parada',
    'search-destination-results-but-no-search':
      'Ver resultados en la pestaña de “Destino”',
    'search-no-results': 'Sin localización',
    'search-origin': 'Busque origen, ruta o parada',
    'search-position': 'Detectar localización',
    'search-search-results-but-no-destination':
      'Ver resultados en la pestaña “Sobre la ruta o la parada”',
    'searching-position': 'Detectando localización...',
    'select-position': 'Seleccione su localización de las búsquedas anteriores',
    send: 'Enviar',
    settings: 'Configuración',
    share: 'Compartir itinerario',
    'show-departures': 'Ver salidas',
    'show-more': 'Ver más',
    'show-routes': 'Ver rutas',
    'skip-positioning': 'Saltar',
    'skip-to-content': 'Skip to content',
    slow: 'Lento',
    'specify-location': 'Especificar localización',
    'splash-locating': 'Detectando localización',
    'splash-or-choose': 'o seleccione su origen',
    'splash-use-positioning': 'Usar servicios de localización',
    'splash-welcome': '¿Cómo desea empezar?',
    'splash-you-can-also': 'o',
    stop: 'Parada',
    'stop-number': 'Número de parada',
    'stop-page.description': 'Parada {name} - {code}',
    'stop-page.title': 'Parada {name} - {code}',
    'stop-page.title-short': 'Parada',
    stops: 'Paradas',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Términos de uso',
    'ticket-and-price-info':
      'Información sobre precios y títulos de transporte',
    'ticket-single-adult': 'Adulto',
    'ticket-type-none': 'Sin límites de tarifas',
    tickets: 'Tickets',
    time: 'Tiempo',
    timetable: 'Horario',
    today: 'Hoy',
    tomorrow: 'Mañana',
    tram: 'Tranvía',
    'tram-with-route-number': 'Tranvía {routeNumber} {headSign}',
    transfers: 'Número de transbordos',
    'transfers-allowed': 'Transbordos permitidos',
    'transfers-margin': 'Márgen de transbordos de al menos',
    'trip-co2-emissions': 'Emisiones de CO2 del viaje',
    'trip-information': 'Información sobre el viaje',
    'trip-page.description': 'Ruta {route_short_name} - {route_long_name}',
    'trip-page.title': 'Ruta {route_short_name}',
    'try-again': 'Pruebe de nuevo',
    'use-citybike': 'Empiece a usar',
    'use-national-service':
      'También puede probar el servicio nacional disponible en',
    'use-own-position': 'Usar localización actual',
    'using-modes': 'Quiero viajar mediante',
    'very-likely': 'Muy probable',
    'very-unlikely': 'Muy poco probable',
    'via-point': 'Punto intermedio',
    'via-point-index': 'Punto intermedio {index}',
    'view-route': 'Ver ruta',
    wait: 'Esperar',
    'wait-amount-of-time': 'Esperar {duration}',
    walk: 'andando',
    'walk-distance-duration': 'Andar {duration} ({distance})',
    walking: 'Cantidad de andar',
    'walking-speed': 'Velocidad de andar',
    'warning-call-agency':
      'Sólo bajo demanda: {routeName}, que necesita ser reservada con antelación.',
    'warning-call-agency-no-route':
      'Sólo bajo demanda, necesita ser reservado con antelación.',
    'weather-at-destination': 'Tiempo en destino',
    yesterday: 'Ayer',
    zones: 'Zonas tarifarias',
  },

  fi: {
    'about-this-service': 'Tietoja palvelusta',
    accessibility: 'Esteettömyys',
    'accessibility-limited': 'Pyörätuoli',
    'accessibility-nolimit': 'Ei rajoitusta',
    'accessibility-statement': 'Saavutettavuusseloste',
    'add-favourite-bikeStation-failed-heading':
      'Kaupunkipyöräaseman tallentaminen ei onnistunut',
    'add-favourite-place-failed-heading': 'Paikan tallentaminen ei onnistunut',
    'add-favourite-route-failed-heading': 'Linjan tallentaminen ei onnistunut',
    'add-favourite-station-failed-heading':
      'Aseman tallentaminen ei onnistunut',
    'add-favourite-stop-failed-heading': 'Pysäkin tallentaminen ei onnistunut',
    'add-home': 'Lisää koti',
    'add-itinerary-via-point': 'Lisää reitille välipiste',
    'add-location-to-favourites':
      'Lisää sinulle tärkeä paikka Suosikkisi-välilehdelle',
    'add-to-favourites': 'Lisää suosikiksi',
    'add-via-button-label': 'Lisää välipiste',
    'add-work': 'Lisää työ',
    'aria-itinerary-summary':
      'Matkan kokonaiskesto {duration}. Lähtö {inFuture} kello {departureTime}. Perillä kello {arrivalTime}.',
    'aria-itinerary-summary-bike-distance':
      'pyöräilyä yhteensä {duration}, {distance} ',
    'aria-itinerary-summary-walk-distance':
      'kävelyä yhteensä {duration}, {distance} ',
    'arrive-leave': 'Saavu tai lähde valittuna aikana',
    'as-destination': 'Määränpääksi',
    'as-origin': 'Lähtöpaikaksi',
    'as-viapoint': 'Välipisteeksi',
    'search-autosuggest-label':
      'Paikka, linja ja pysäkkihaku. Navigoi listassa nuolinäppäimillä ja valitse enterillä',
    'search-autosuggest-len':
      ' Löydettiin {len, plural, =1 {1 ehdotus} other {{len} ehdotusta}}',
    'search-current-suggestion': 'Tämänhetkinen valinta: {selection}',
    // eslint-disable-next-line sort-keys
    'add-via-duration-button-label':
      'Aseta välipisteelle {index} pysähdyksen kesto',
    'add-via-duration-button-label-close':
      'Avaa välipisteen {index} pysähdyksen kesto valitsin',
    'add-via-duration-button-label-open':
      'Sulje välipisteen {index} pysähdyksen kesto valitsin',
    'select-date': 'Valitse päivä',
    'select-from-map-destination': 'Valitse määränpää',
    'select-from-map-no-title': 'Ei otsikkoa',
    'select-from-map-origin': 'Valitse lähtöpaikka',
    'select-from-map-viaPoint': 'Valitse välipiste',
    'set-time-earlier-button-label': 'Aikaista matkustusajankohtaa',
    'set-time-later-button-label': 'Myöhäistä matkustusajankohtaa',
    'set-time-now-button-label': 'Aseta matkustusajankohdaksi nyt',
    'time-navigation-buttons': 'Matkustusajankohdan muokkausnapit',
    'time-selector-form': 'Muokkaa Matkustusajankohtaa',
    // eslint-disable-next-line sort-keys
    address: 'Osoite',
    'address-or-stop': 'Osoite tai pysäkki',
    agency: 'Liikennöitsijä',
    airplane: 'Lentokone',
    'airplane-with-route-number': 'Lento {routeNumber}',
    'airport-check-in':
      'Tee lähtöselvitys heti saavuttuasi, jos et ole tehnyt sitä jo etukäteen',
    'airport-collect-luggage': 'Nouda matkatavarat',
    'airport-security-check-go-to-gate':
      'Kävele turvatarkastuksen läpi lähtöportille',
    'all-routes': 'Kaikki linjat',
    'all-routes-disabled': 'Ei linjoja',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Perillä',
    'arriving-soon': 'Nyt',
    'at-time': 'klo',
    'avoid-transfers': 'Vältä vaihtoja',
    'avoid-walking': 'Vältä kävelyä',
    'back-button-title': 'Siirry takaisin edelliselle sivulle',
    'back-to-front-page': 'Siirry etusivulle ›',
    bicycle: 'Polkupyörä',
    'bicycle-distance-duration': 'Pyöräile {duration} ({distance})',
    'bicycle-walk-from-transit':
      'Ota pyörä {transportMode} ja taluta {distance} ({duration})',
    'bicycle-walk-to-transit':
      'Taluta {distance} ({duration}) ja ota pyörä mukaan {transportMode}',
    bicycle_walk: 'Polkupyörän talutus',
    'bike-availability': 'Pyöriä asemalla juuri nyt',
    'bike-not-allowed-bus': 'Pyörää ei voi kuljettaa bussissa',
    'bike-not-allowed-tram': 'Pyörää ei voi kuljettaa raitiovaunussa',
    'bike-park': 'Liityntäpyöräparkki',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    'bikes-available': 'Pyöriä asemalla {amount}',
    'biketransport-citybike': 'Käytän kaupunkipyörää',
    'biketransport-only-bike': 'Liikun vain pyörällä',
    'biking-amount': 'Pyöräilyn määrä',
    'biking-speed': 'Pyöräilynopeus',
    bus: 'Bussi',
    'bus-with-route-number': 'Bussi {routeNumber} {headSign}',
    buy: 'Osta',
    'buy-ticket': 'Näin ostat lipun',
    call: 'Soita',
    cancel: 'Peruuta',
    canceled: 'Peruttu',
    'canceled-itineraries-amount':
      'Lisäksi {itineraryAmount, plural, =1 {1 peruttu reittiehdotus} other {{itineraryAmount} peruttua reittiehdotusta}}',
    'canceled-itineraries-amount-hide':
      'Piilota perutut reittiehdotukset ({itineraryAmount})',
    'canceled-legs': 'Reitillä peruttuja vuoroja',
    car: 'Auto',
    'car-distance-duration': 'Aja {duration} ({distance})',
    car_park: 'Liityntäpysäköinti',
    cause: 'syy',
    'char-left': 'merkkiä',
    'choose-stop': 'Valitse pysäkki',
    'choose-stop-or-vehicle': 'Valitse linja tai pysäkki',
    'choose-vehicle': 'Valitse linja',
    citybike: 'Kaupunkipyörä',
    'citybike-buy-season':
      'Osta käyttöoikeutta päiväksi, viikoksi tai koko kaudeksi',
    'citybike-distance-duration': 'Pyöräile {duration} ({distance})',
    'citybike-duration-general-header':
      'Useampi reitin kaupunkipyöräosuuksista sisältää lisämaksuja.',
    'citybike-duration-info':
      'Kaupunkipyörää voi käyttää yhtäjaksoisesti {duration} min, jonka jälkeen peritään lisämaksu.',
    'citybike-duration-info-header':
      'Reitin kaupunkipyöräosuus kestää yli {duration} min.',
    'citybike-duration-info-short':
      'Osuus yli {duration} min. Sisältää lisämaksun.',
    'citybike-network-headers': 'Kaupunkipyörät',
    'citybike-purchase-link': 'Siirry ostamaan',
    'citybike-register-required':
      'Kaupunkipyörien käyttö vaatii rekisteröitymisen',
    'citybike-return-full': 'Palauttamassa kaupunkipyörää?',
    'citybike-return-full-link':
      'Näin palautat pyörän, kun pyöräasema on täynnä ›',
    'citybike-start-using': 'Ota kaupunkipyörät käyttöön',
    'citybike-start-using-info': 'Lisätietoja',
    'citybike-station': 'Kaupunkipyöräasema {stationId}',
    'citybike-station-no-id': 'Kaupunkipyöräasema',
    'clear-button-label': 'Tyhjennä',
    close: 'Sulje',
    'close-favourite-module': 'Sulje suosikkimoduuli',
    'close-settings': 'Sulje ikkuna ja tallenna asetukset',
    'contact-information': '{contactName}:n yhteystiedot',
    continue: 'Jatka',
    'create-account': 'Luo {contactName} tunnus',
    'cycle-distance-duration': 'Pyöräile {duration} ({distance})',
    'cyclewalk-distance-duration': 'Taluta pyörää {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Tämänhetkinen sijainti',
    datasources: 'Tietolähteet',
    date: 'Päivämäärä',
    day: 'Päivä',
    delete: 'Poista',
    'delete-favourite-failed-heading': 'Tallennuksen poistaminen ei onnistunut',
    'departure-is-canceled':
      '{modeInfo} lähtö {from}–{to} kello {time} on peruttu',
    'departure-is-canceled-airplane': 'Lennon {shortName}',
    'departure-is-canceled-bus': 'Bussin {shortName}',
    'departure-is-canceled-ferry': 'Lautan {shortName}',
    'departure-is-canceled-rail': '{shortName}-junan',
    'departure-is-canceled-subway': 'Metron {shortName}',
    'departure-is-canceled-tram': 'Raitiolinjan {shortName}',
    'departure-list-update.sr-instructions':
      'Tulevien lähtöjen lista ja lähtöajat päivittyvät reaaliajassa.',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Lähdöt',
    'departures-by-hour': 'Lähdöt tunneittain',
    'departures-by-hour-minutes-route': '(minuutit/linja)',
    description: 'Löydä joukkoliikennetarjonta lähelle ja kauas.',
    destination: 'Määränpää',
    'destination-label-change': 'Vaihda määränpää',
    'destination-outside-service':
      'Reittiehdotuksia ei löytynyt, koska määränpää sijaitsee palvelualueen ulkopuolella.',
    'destination-placeholder': 'Kirjoita määränpää, linja tai pysäkki',
    'dialog-return-to-map': 'Takaisin karttaan',
    'digitransit-platform': 'Digitransit-palvelualusta',
    disclaimer:
      'Tulokset perustuvat arvioituihin ajoaikoihin. Ehdotetun yhteyden toteutumista ei voida taata.',
    disruption: 'Häiriö',
    'disruption-info': 'Poikkeusinfo',
    'disruption-info-no-alerts':
      'Ei tiedossa olevia häiriöitä tai poikkeuksia.',
    'disruption-info-route-no-alerts':
      'Linjalla ei tiedossa olevia häiriöitä tai poikkeuksia.',
    disruptions: 'Häiriöt',
    'disruptions-and-diversions': 'Häiriöt ja poikkeukset',
    'disruptions-tab.sr-disruptions': 'Yksi tai useampi häiriö tiedossa',
    'disruptions-tab.sr-no-disruptions': 'Ei tiedossa olevia häiriöitä',
    'distance-between': 'Etäisyys {distance1} m — {distance2} m',
    'distance-total': 'Matkan pituus',
    'distance-under': 'Etäisyys alle {distance} m',
    earlier: 'Aiempia lähtöjä',
    'edit-favourites': 'Muokkaa paikkaa Suosikkisi-välilehdellä',
    'elevation-gained-total': 'Nousua yhteensä',
    'elevation-lost-total': 'Laskua yhteensä',
    explanations: 'Selitykset',
    'extra-info': 'Lisätiedot',
    'favourite-failed-text': 'Yritä hetken päästä uudelleen.',
    'favourite-target': 'Suosikkikohde',
    ferry: 'Lautta',
    'ferry-with-route-number': 'Lautta {routeNumber} {headSign}',
    'fetch-new-route': 'Hae uusi reitti',
    'from-ferry': 'lautasta',
    'from-rail': 'junasta',
    'from-station': 'asemalta',
    'from-stop': 'pysäkiltä',
    'from-subway': 'metrosta',
    frontpage: 'Etusivu',
    'generic-cancelation': '{mode} {route} {headsign} kello {time} on peruttu.',
    'generic-error': 'Tapahtui virhe',
    'geolocate-yourself': 'Paikanna',
    'geolocation-denied-heading': 'Paikannus on estetty',
    'geolocation-denied-text':
      'Et ole antanut lupaa paikannukseen. Voit sallia paikannuksen selaimen tai puhelimen asetuksista.',
    'geolocation-failed-heading': 'Paikannus epäonnistui',
    'geolocation-failed-text':
      'Paikannus ei toimi tai selaimesi ei tue paikannusta.',
    'geolocation-prompt-text': 'Hyväksy selaimen paikannuspyyntö.',
    'geolocation-timeout-heading': 'Paikannus kestää odotettua pidempään.',
    'geolocation-timeout-text': 'Oletko hyväksynyt selaimen paikannuspyynnön?',
    'give-destination': 'Määränpää',
    'give-name-to-location': 'Anna paikalle kuvaava nimi',
    'give-origin': 'Lähtöpaikka',
    'give-position': 'Kirjoita sijaintisi tai lähtöpaikkasi hakukenttään.',
    'give-route': 'Hae linjaa',
    hour: 'Tunti',
    'hour-short': 'h',
    'how-to-rate-service': 'Kuinka arvioisit palvelua?',
    'in-addition': 'Lisäksi',
    'index.title': 'Reittiopas',
    inquiry: 'Mitä pidät uudesta Reittioppaasta? Kerro se meille! ',
    instructions: 'Ohjeet',
    'itinerary-details.biking-leg':
      '{time} pyöräile kohteesta {origin} {distance} kohteeseen {destination}. Matka-aika {duration}',
    'itinerary-details.car-leg':
      '{time} aja {distance} kohteesta {origin} kohteeseen {destination}. Matka-aika {duration}',
    'itinerary-details.end-leg': '{time} saavu kohteeseen {destination}.',
    'itinerary-details.route-has-info-alert': 'Reitillä on tiedotteita.',
    'itinerary-details.route-has-severe-alert': 'Reitillä on poikkeuksia.',
    'itinerary-details.route-has-unknown-alert': 'Reitillä on häiriöitä.',
    'itinerary-details.route-has-warning-alert': 'Reitillä on häiriöitä.',
    'itinerary-details.scooter-leg':
      '{time} potkulautaile {distance} kohteesta {origin} kohteeseen {destination}. Matka-aika {duration}',
    'itinerary-details.transit-leg-part-1': '{time} ota',
    'itinerary-details.transit-leg-part-2':
      'pysäkiltä {startStop} {startZoneInfo} {trackInfo} Pysäkille {endStop} {endZoneInfo}. Arvioitu matka-aika {duration}',
    'itinerary-details.walk-leg':
      '{time} kävele {distance} kohteesta {origin} kohteeseen {destination}. Matka-aika {duration}',
    'itinerary-feedback-button': 'Lähetä palautetta',
    'itinerary-feedback-message': 'Etkö löytänyt mitä etsit?',
    'itinerary-feedback-placeholder': 'Kuvaus (valinnainen)',
    'itinerary-hide-stops': 'Piilota pysäkit',
    'itinerary-page.description': 'Reittiohje',
    'itinerary-page.hide-details': 'Piilota reittiohje',
    'itinerary-page.itineraries-loaded': 'Lisää hakutuloksia ladattu',
    'itinerary-page.loading-itineraries': 'Ladataan lisää hakutuloksia',
    'itinerary-page.show-details': 'Näytä reittiohje',
    'itinerary-page.show-details-label': 'Näytä reittiohje {number}',
    'itinerary-page.title': 'Reittiohje',
    'itinerary-page.update-alert': 'Hakutulokset päivitetty',
    'itinerary-summary-page-street-mode.update-alert':
      'Kävely ja pyöräily hakutulokset päivitetty',
    'itinerary-summary-page.description': 'Reittiehdotukset',
    'itinerary-summary-row.biking-distance':
      'Pyöräilyä yhteensä {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Näytä kartalla',
    'itinerary-summary-row.description':
      'Lähtö {departureDate} kello {departureTime}. Perillä {arrivalDate} kello {arrivalTime}. {firstDeparture} {transfers} Matka-aika {totalTime}',
    'itinerary-summary-row.first-departure':
      '{vehicle} lähtee asemalta {stopName} kello {departureTime}.',
    'itinerary-summary-row.first-leg-start-time':
      'Lähtee klo {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}{firstDeparturePlatform}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Lähtö klo {firstDepartureTime} kaupunkipyöräasemalta {firstDepartureStop}',
    'itinerary-summary-row.no-transit-legs': 'Lähde, kun sinulle sopii',
    'itinerary-summary-row.transfers': 'Vaihto {vehicle} pysäkillä {stopName}',
    'itinerary-summary-row.walking-distance':
      'Kävelyä yhteensä {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Ota pyöräsi mukaan kulkuneuvoon',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Ota pyöräsi mukaan junaan tai metroon',
    'itinerary-summary.bikeAndPublic-rail-title': 'Ota pyöräsi mukaan junaan',
    'itinerary-summary.bikeAndPublic-subway-title':
      'Ota pyöräsi mukaan metroon',
    'itinerary-summary.bikePark-title': 'Jätä pyöräsi liityntäpysäköintiin',
    'itinerary-summary.interline-wait':
      'Linjatunnus ({shortName}) ja määränpää ({destination}) vaihtuvat pysäkillä {stop}. Matka jatkuu {time} odotuksen jälkeen. Odota kulkuneuvossa.',
    'itinerary-summary.show-on-map': 'Näytä kartalla {target}',
    'itinerary-ticket.title': 'Tarvittava lippu',
    'itinerary-tickets.title': 'Tarvittavat liput',
    'itinerary-time.title': 'Matka-aika',
    'itinerary-walk.title': 'Kävelyä',
    'journeyplanner.title': 'HSL Reittiopas',
    later: 'Myöhempiä lähtöjä',
    leaves: 'Lähtee',
    'leaving-at': 'Lähtöaika',
    'likely-to-recommend':
      'Kuinka todennäköistä on, että suosittelisit palveluamme ystävälle tai kollegallesi?',
    'likely-to-use':
      'Kuinka todennäköisesti käyttäisit tätä palvelua ennemmin kuin nykyistä reittiopas-palvelua?',
    loading: 'Ladataan...',
    localadmin: 'Paikkakunta',
    locate: 'Paikanna',
    'location-examples': 'esim. Koti, Työ, Koulu,...',
    'location-from-map': 'Valittu sijainti',
    'location-from-map-confirm': 'Vahvista valinta',
    'location-home': 'Koti',
    'location-school': 'Koulu',
    'location-shopping': 'Kauppa',
    'location-sport': 'Urheilu',
    'location-work': 'Työ',
    login: 'Siirry kirjautumaan',
    'login-content':
      'Paikkojen, linjojen ja pysäkkien tallentaminen ja hyödyntäminen edellyttää kirjautumista HSL-tunnuksella!',
    'login-header': 'Kirjaudu ensin',
    logout: 'Kirjaudu ulos',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Sulje päävalikko',
    'main-menu-label-open': 'Avaa päävalikko',
    'main-mode': 'Kulkumuoto',
    map: 'Kartta',
    'map-layer-citybike': 'Kaupunkipyöräasemat',
    'map-layer-park-and-ride': 'Liityntäpysäköintipaikat',
    'map-layer-stop-bus': 'Bussipysäkit',
    'map-layer-stop-ferry': 'Lautat',
    'map-layer-stop-tram': 'Raitiovaunupysäkit',
    'map-layer-terminal-bus': 'Bussiterminaalit',
    'map-layer-terminal-rail': 'Juna-asemat',
    'map-layer-terminal-subway': 'Metroasemat',
    'map-layer-vehicles': 'Liikennevälineet',
    'menu-faq': 'UKK',
    'menu-feedback': 'Anna palautetta',
    'menu-link-to-privacy-policy': 'Rekisteriseloste',
    'messagebar-label-close-message-bar': 'Sulje banneri',
    'messagebar-label-page': 'Sivu',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Linja',
    'more-departures': 'Lisää lähtöjä',
    'more-settings': 'Lisäasetukset',
    'move-on-map': 'Siirry kartalla',
    'move-to-tab': 'Siirry välilehdelle {number}',
    navigate: 'Navigoi',
    nearest: 'Lähimmät {mode}',
    'nearest-favorites': 'Omat lähipysäkit',
    'nearest-favorites-browse-stops': 'Selaa ja valitse pysäkkejä',
    'nearest-favorites-no-favorites':
      'Valitsemiesi pysäkkien aikataulut ja ajoneuvot kartalla',
    'nearest-no-stops':
      'Valitun liikennevälineen asemia tai pysäkkejä ei ole lähialueella.',
    'nearest-stops': 'Lähimmät pysäkit',
    'nearest-stops-airplane': 'lentoasemat',
    'nearest-stops-bus': 'bussipysäkit',
    'nearest-stops-citybike': 'pyöräasemat',
    'nearest-stops-favorite': 'omat lähipysäkit',
    'nearest-stops-ferry': 'lauttalaiturit',
    'nearest-stops-rail': 'juna-asemat',
    'nearest-stops-subway': 'metroasemat',
    'nearest-stops-tram': 'raitiovaunupysäkit',
    'nearest-stops-update-location': 'Päivitä {mode}',
    'nearest-stops-updated-location': 'Kartan sijainti on muuttunut',
    'network-error': 'Verkkovirhe',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Naapurusto',
    next: 'Seuraava',
    'no-bike-allowed-popup-train':
      'Lähiliikenteen juniin pyörän voi ottaa mukaan ilmaiseksi, mutta pitkän matkan junissa peritään lisämaksu ja mahdollisesti vaaditaan etukäteisvaraus.',
    'no-bike-allowed-popup-tram-bus':
      'Pyörää ei voi ottaa bussiin tai raitiovaunuun. Jos käytät reitilläsi näitä kulkuvälineitä, jätä pyörä pysäkille tai liityntäpysäköintiin.',
    'no-departures': 'Ei lähteviä vuoroja',
    'no-position': 'Ei sijaintia',
    'no-position-no-stops':
      'Sijaintisi ei ole tiedossa, joten emme voi näyttää lähimpiä pysäkkejä.',
    'no-route-already-at-destination': 'Olet jo perillä.',
    'no-route-end': 'Syötä vielä määränpää',
    'no-route-end-date-not-in-range':
      'Aikataulu ei sisällä myöhäisempiä reittejä.',
    'no-route-msg': 'Reittiehdotuksia ei valitettavasti löytynyt.',
    'no-route-msg-with-changes':
      'Reittiehdotuksia ei valitettavasti löytynyt. Ole hyvä ja tarkasta asettamasi hakuasetukset tai yritä muuttaa lähtöpaikkaa tai määränpäätä.',
    'no-route-origin-near-destination':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka ja määränpää ovat niin lähellä toisiaan.',
    'no-route-origin-same-as-destination':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka on sama kuin määränpää.',
    'no-route-showing-alternative-options':
      'Asettamillasi asetuksilla ei löytynyt yhtään reittiehdotusta. Löysimme kuitenkin seuraavia reittivaihtoehtoja:',
    'no-route-start': 'Syötä vielä lähtöpaikka',
    'no-route-start-date-too-early': 'Aikataulu ei sisällä aiempia reittejä.',
    'no-route-start-end': 'Syötä lähtöpaikka ja määränpää',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Valitulle päivälle ei löydy matkoja.',
    now: 'Nyt',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ei pysähdyksiä} one {1 pysäkki} other {{number} pysäkkiä} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuuttia} one {1 minuutti} other {{number} minuuttia}}',
    'off-canvas-info':
      'Kirjautumalla palveluun saat suosikit talteen ja voit hyödyntää niitä muillakin laitteillasi',
    'option-default': 'Keskimääräinen',
    'option-least': 'Hidas',
    'option-less': 'Rauhallinen',
    'option-more': 'Ripeä',
    'option-most': 'Nopea',
    or: 'tai',
    origin: 'Lähtöpaikka',
    'origin-label-change': 'Vaihda lähtöpaikka',
    'origin-outside-service':
      'Reittiehdotuksia ei löytynyt, koska lähtöpaikka sijaitsee palvelualueen ulkopuolella.',
    'origin-placeholder': 'Mistä? - osoite tai pysäkki',
    'other-dates': 'Muut ajankohdat',
    'own-position': 'Nykyinen sijaintisi',
    'page-not-found': 'Etsimääsi sivua ei löytynyt.',
    'park-and-ride': 'Liityntäpysäköinti',
    'park-and-ride-availability': 'Pysäköintipaikkoja vapaana',
    'pay-attention': 'Huom!',
    'pick-icon': 'Valitse kuvake',
    'pick-mode': 'Liikennevälineet',
    'pick-mode-airplane-disabled': 'Lentokone ei valittu',
    'pick-mode-airplane-enabled': 'Lentokone valittu',
    'pick-mode-bus-disabled': 'Bussi ei valittu',
    'pick-mode-bus-enabled': 'Bussi valittu',
    'pick-mode-citybike-disabled': 'Kaupunkipyörä ei valittu',
    'pick-mode-citybike-enabled': 'Kaupunkipyörä valittu',
    'pick-mode-ferry-disabled': 'Lautta ei valittu',
    'pick-mode-ferry-enabled': 'Lautta valittu',
    'pick-mode-rail-disabled': 'Juna ei valittu',
    'pick-mode-rail-enabled': 'Juna valittu',
    'pick-mode-selected': 'Valitut joukkoliikennevälineet',
    'pick-mode-subway-disabled': 'Metro ei valittu',
    'pick-mode-subway-enabled': 'Metro valittu',
    'pick-mode-tram-disabled': 'Raitiovaunu ei valittu',
    'pick-mode-tram-enabled': 'Raitiovaunu valittu',
    'pick-street-mode': 'Omat kulkuvälineet',
    'place-route-or-keyword': 'Määränpää, linja tai pysäkki',
    platform: 'Laituri',
    'platform-num': 'Laituri {platformCode}',
    'platform-short': 'Lait. {platformCode}',
    'platform-short-no-num': 'Lait.',
    position: 'Sijainti',
    'prefer-walking': 'Suosi kävelyä',
    previous: 'Edelliset',
    print: 'Tulosta',
    'print-timetable': 'Viikkoaikataulu',
    'public-transport': 'Joukkoliikenne',
    rail: 'Lähijuna',
    'rail-with-route-number': 'Juna {routeNumber} {headSign}',
    'read-more': 'Lue lisää',
    realtime: 'Reaaliaika',
    'realtime-matching': 'Realiajan integraatio',
    releases: 'Tiedotteet',
    'remove-favourite': 'Poista suosikkivalinta',
    'remove-via-button-label': 'Poista välipiste {index}',
    'rent-cycle-at': 'Nouda Kaupunkipyörä: ',
    'rent-scooter-at': 'Vuokraa potkulauta asemalta {station}',
    'required-ticket': 'Reitillä tarvittava lippu',
    'return-cycle-to': 'Palauta kaupunkipyörä:',
    'return-scooter-to': 'Palauta potkulauta asemalle {station}',
    'right-now': 'Juuri nyt',
    route: 'Linja',
    'route-add-viapoint': 'Välipiste',
    'route-custom-settings': 'Omat asetukset',
    'route-default': 'Perusasetukset',
    'route-destination-arrives': 'Vain poistuminen',
    'route-destination-endpoint': 'Saapuu / Päätepysäkki',
    'route-fastest': 'Nopea reitti',
    'route-from-here': 'Lähtöpaikka',
    'route-guide': 'Linjaohje',
    'route-here': 'Määränpää',
    'route-least-elevation-changes': 'Vältä korkeuseroja',
    'route-least-transfers': 'Vältä vaihtoja',
    'route-least-walking': 'Vältä kävelyä',
    'route-not-possible':
      'Valitettavasti reittisi ei ole mahdollinen. Tekninen virhe: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} on pysäkillä {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} on pysäkin {stopName} ja pysäkin {nextStopName} välissä.',
    'route-page.description': 'Linja {shortName}, {longName}',
    'route-page.future-routes': 'Tulevat reitit',
    'route-page.pattern-chosen': 'Valittu',
    'route-page.pattern-select-title': 'Valitse tarkasteltava reitti',
    'route-page.similar-routes': 'Sinua saattaisi kiinnostaa myös',
    'route-page.special-routes': 'Muut reitit',
    'route-page.title': 'Linja {shortName}',
    'route-page.title-short': 'Linja',
    'route-to-stop': 'Reitti pysäkille',
    routes: 'Linjat',
    'routes-platforms': 'Linjat ja laiturit',
    'routes-tracks': 'Linjat ja raiteet',
    run: 'Juoksu',
    save: 'Tallenna',
    'scooter-availability': 'Potkulautoja asemalla juuri nyt',
    'scooter-distance-duration': 'Potkulautaile {duration} ({distance})',
    'scooter-register-required':
      'Potkulautojen käyttö vaatii rekisteröitymisen',
    'scooterwalk-distance-duration':
      'Taluta potkulautaa {duration} ({distance})',
    search: 'Etsi',
    'search-button-label': 'Hae',
    'search-destination': 'Hae määränpäätä, linjaa tai pysäkkiä',
    'search-destination-index': 'Syötä määränpää',
    'search-destination-results-but-no-search':
      'Katso tulokset viereiseltä Määränpää-välilehdeltä',
    'search-fields.sr-instructions':
      'Reittihaku tehdään automaattisesti kun lähtöpaikka ja määränpää on asetettu. Haun parametrien muuttaminen laukaisee uuden reittihaun',
    'search-no-results': 'Ei tuloksia',
    'search-origin': 'Hae lähtöpaikkaa, linjaa tai pysäkkiä',
    'search-origin-index': 'Syötä lähtöpaikka',
    'search-position': 'Paikanna',
    'search-search-results-but-no-destination':
      'Katso tulokset viereiseltä "Tietoja linjasta tai pysäkistä" –välilehdeltä',
    'searching-position': 'Paikannetaan...',
    'select-all-routes': 'Valitse kaikki reitit',
    'select-map-layers-header': 'Näytä kartalla',
    'select-position': 'Valitse sijaintisi edellisistä hauistasi',
    'select-route': 'Valitse {mode}linja {shortName} kohti {headsign}',
    'selected-routes': 'Valitut linjat',
    send: 'Lähetä',
    'separate-ticket-required':
      'Tälle matkan osuudelle tarvitaan erillinen lippu.',
    'separate-ticket-required-disclaimer':
      'Matka sisältää yhden tai useamman osuuden, jonka lippujen myynnistä {agencyName} ei vastaa.',
    'set-specific-settings': 'Aseta tarkemmat asetukset',
    'set-stop-as-routes': 'Aseta pysäkki reitin',
    settings: 'Asetukset',
    'settings-dropdown-close-label': 'Sulje vaihtoehdot',
    'settings-dropdown-open-label': 'Avaa vaihtoehdot',
    'settings-label-change': 'Muuta reittihaun asetuksia',
    share: 'Jaa ohje',
    show: 'Näytä',
    'show-departures': 'Näytä lähdöt',
    'show-more': 'Näytä lisää',
    'show-more-stops-near-you': 'Näytä lisää pysäkkejä lähelläsi',
    'show-routes': 'Valitse linjat',
    'skip-positioning': 'Ohita',
    'skip-to-content': 'Siirry sisältöön',
    slow: 'Hidas',
    'specify-location': 'Määritä sijainti',
    'splash-locating': 'Paikannetaan',
    'splash-or-choose': 'tai valitse lähtöpaikaksi',
    'splash-use-positioning': 'Käytä paikannusta',
    'splash-welcome': 'Miten haluat aloittaa?',
    'splash-you-can-also': 'tai',
    station: 'Asema',
    stop: 'Pysäkki',
    'stop-departure-time-future': 'Lähtöaika {minutes} min päästä',
    'stop-departure-time-past': 'Lähtöaika oli',
    'stop-list-update.sr-instructions':
      'Pysäkkien lähtöajat päivittyvät reaaliajassa.',
    'stop-near-you': 'Linja, pysäkki tai asema',
    'stop-near-you-modal-grant-permission': 'tai salli paikannus',
    'stop-near-you-modal-grant-permission-info':
      'Voit sallia paikannuksen selaimen tai puhelimen asetuksista, jolloin palvelu pystyy hyödyntämään nykyistä sijaintiasi.',
    'stop-near-you-modal-header': 'Et ole sallinut paikannusta',
    'stop-near-you-modal-info':
      'Lähipysäkkien käyttö edellyttää tietoa sijainnista.',
    'stop-near-you-title': 'Linja- ja pysäkkihaku',
    'stop-near-you-update-alert': 'Lähimmät pysäkit päivitetty',
    'stop-number': 'Pysäkkinumero',
    'stop-page.description': 'Pysäkki - {name} {code}, {desc}',
    'stop-page.right-now.loaded': 'pysäkkisivu ladattu',
    'stop-page.title': 'Pysäkki - {name} {code}',
    'stop-page.title-short': 'Pysäkki',
    'stop-virtual-monitor': 'Virtuaalimonitori',
    stops: 'Pysäkit',
    'stops-near-you.description':
      'Lähialueesi pysäkkiaikataulut ja ajoneuvot kartalla.',
    'stops-near-you.title': 'Lähipysäkkien aikataulut',
    'street-mode-bicycle': 'Pyöräily',
    'street-mode-bike-aria':
      'Näytä pyöräilyreitti. Kesto {duration}, pituus {length}',
    'street-mode-bikeandvehicle-aria':
      'Näytä pyöräilyn ja julkisen liikenteen yhdistelmä. Kesto {duration}, pyöräilyn pituus {length}',
    'street-mode-car': 'Auto',
    'street-mode-car_park': 'Liityntä\u00ADpysäköinti',
    'street-mode-public_transport': 'Julkinen liikenne',
    'street-mode-walk': 'Kävely',
    'street-mode-walk-aria':
      'Näytä kävelyreitti. Kesto {duration}, pituus {length}',
    // eslint-disable-next-line sort-keys
    street: 'Katu',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Reittiehdotus {number}',
    'summary-page.title': 'Reittiehdotukset',
    'swap-order-button-label': 'Käännä reitin suunta vastakkaiseksi',
    'swipe-disruption-info': 'Poikkeustietovälilehtien',
    'swipe-disruption-info-header': 'Poikkeustietovälilehdet.',
    'swipe-message-bar': 'Viestivälilehtien',
    'swipe-message-bar-header': 'Viestivälilehdet.',
    'swipe-result-tab-left':
      'Navigointipainike. Näytä edellinen välilehti painamalla enteriä tai välilyöntiä.',
    'swipe-result-tab-right':
      'Navigointipainike. Näytä seuraava välilehti painamalla enteriä tai välilyöntiä.',
    'swipe-result-tabs': 'Selaa välilehtiä nuolinäppäimillä.',
    'swipe-sr-new-tab-opened': 'Välilehti {number} avattu.',
    'swipe-stops-near-you': 'Lähipysäkkinäkymävälilehtien',
    'swipe-stops-near-you-header': 'Lähipysäkkinäkymävälilehdet.',
    'swipe-summary-page': 'Reittiehdotusvälilehtien',
    'swipe-summary-page-header': 'Reittiehdotusvälilehdet.',
    'terminal-page.description': 'Terminaali - {name}',
    'terminal-page.title': 'Terminaali - {name}',
    'terminal-page.title-short': 'Terminaali',
    'terms-of-use': 'Käyttöehdot',
    'ticket-and-price-info': 'Lippu- ja hintatiedot',
    'ticket-single-adult': 'Aikuinen',
    'ticket-type-none': 'Ei rajoitusta',
    tickets: 'Matkaliput',
    time: 'Aika',
    'time-selector-hours-label': 'Tunti',
    'time-selector-minutes-label': 'Minuutti',
    timetable: 'Aikataulu',
    'to-frontpage': 'Etusivulle',
    today: 'Tänään',
    tomorrow: 'Huomenna',
    track: 'Raide',
    'track-num': 'Raide {platformCode}',
    'track-short': 'Raide {platformCode}',
    'track-short-no-num': 'Raide',
    tram: 'Raitiovaunu',
    'tram-with-route-number': 'Raitiovaunu {routeNumber} {headSign}',
    transfers: 'Vaihtojen määrä',
    'transfers-allowed': 'Saa olla vaihtoja',
    'transfers-margin': 'Vaihtoaika vähintään',
    'travel-time': '{min} min',
    'travel-time-label': 'Matka-aika',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'Reitin CO2 päästöt',
    'trip-information': 'Lähdön tiedot',
    'trip-page.description': 'Linja {shortName}, {longName}',
    'trip-page.title': 'Linja {shortName}',
    'try-again': 'Yritä uudelleen',
    'use-citybike': 'Ota käyttöön',
    'use-national-service-postfix': '-palvelusta.',
    'use-national-service-prefix':
      'Suosittelemme kokeilemaan reittihakua koko maan kattavasta ',
    'use-own-position': 'Käytä nykyistä sijaintia',
    userinfo: 'Omat tiedot',
    usermenu: 'Käyttäjävalikko',
    'using-modes': 'Haluan kulkea',
    'very-likely': 'Erittäin todennäköistä',
    'very-unlikely': 'Erittäin epätodennäköistä',
    'via-leg-stop-duration': 'Välipisteessä {stayDuration}',
    'via-point': 'Välipiste',
    'via-point-index': 'Välipiste {index}',
    'viapoint-slack-amount': 'Pysähdyksen kesto',
    'view-route': 'Linjan tiedot',
    // eslint-disable-next-line sort-keys
    venue: 'Paikka',
    wait: 'Odota',
    'wait-amount-of-time': 'Odota {duration}',
    walk: 'kävelyn',
    'walk-bike-itinerary-1':
      'Löysimme valitsemallesi reitille vain kävelyyn liittyviä reittiehdotuksia.',
    'walk-bike-itinerary-2':
      'Löysimme valitsemallesi reitille vain pyöräilyyn liittyviä reittiehdotuksia.',
    'walk-bike-itinerary-3':
      'Löysimme valitsemallesi reitille vain kävelyyn ja pyöräilyyn liittyviä reittiehdotuksia.',
    'walk-distance-duration': 'Kävele {duration} ({distance})',
    walking: 'Kävelyn määrä',
    'walking-speed': 'Kävelynopeus',
    'warning-call-agency': `Liikennöidään kutsujoukkoliikenteenä: {routeName},
      joka on tilattava etukäteen`,
    'warning-call-agency-no-route':
      'Liikennöidään kutsujoukkoliikenteenä, joka on tilattava etukäteen',
    weather: 'Sää',
    'weather-at-destination': 'Sää määränpäässä',
    'weather-data-source': 'Lähde: Ilmatieteen laitos',
    'weather-detail-title': 'Sääennuste reitin lähtöpaikassa klo',
    'weather-icon-1': 'Selkeää',
    'weather-icon-2': 'Puolipilvistä',
    'weather-icon-21': 'Heikkoja sadekuuroja',
    'weather-icon-22': 'Sadekuuroja',
    'weather-icon-23': 'Voimakkaita sadekuuroja',
    'weather-icon-3': 'Pilvistä',
    'weather-icon-31': 'Heikkoa vesisadetta',
    'weather-icon-32': 'Vesisadetta',
    'weather-icon-33': 'Voimakasta vesisadetta',
    'weather-icon-41': 'Heikkoja lumikuuroja',
    'weather-icon-42': 'Lumikuuroja',
    'weather-icon-43': 'Voimakkaita lumikuuroja',
    'weather-icon-51': 'Heikkoa lumisadetta',
    'weather-icon-52': 'Lumisadetta',
    'weather-icon-53': 'Voimakasta lumisadetta',
    'weather-icon-61': 'Ukkoskuuroja',
    'weather-icon-62': 'Voimakkaita ukkoskuuroja',
    'weather-icon-63': 'Ukkosta',
    'weather-icon-64': 'Voimakasta ukkosta',
    'weather-icon-71': 'Heikkoja räntäkuuroja',
    'weather-icon-72': 'Räntäkuuroja',
    'weather-icon-73': 'Voimakkaita räntäkuuroja',
    'weather-icon-81': 'Heikkoa räntäsadetta',
    'weather-icon-82': 'Räntäsadetta',
    'weather-icon-83': 'Voimakasta räntäsadetta',
    'weather-icon-91': 'Utua',
    'weather-icon-92': 'Sumua',
    where: 'Minne mennään?',
    yesterday: 'Eilen',
    zone: 'Vyöhyke',
    'zone-info': 'Vyöhyke {zone}',
    'zone-unknown': 'Tuntematon vyöhyke',
    zones: 'Lippuvyöhykkeet',
  },

  fr: {
    'about-this-service': 'À propos de ce service',
    accessibility: 'Accessibilité',
    'accessibility-limited': 'Fauteuil roulant',
    'accessibility-nolimit': 'Aucune limite',
    'add-itinerary-via-point': 'Ajouter une étape au trajet',
    'add-location-to-favourites':
      "Ajouter vos lieux importants à l'onglet favoris",
    address: 'Adresse',
    'address-or-stop': 'Adresse ou arrêt',
    agency: 'Transporteur',
    airplane: 'Avion',
    'airplane-with-route-number': 'Avion {routeNumber}',
    'airport-check-in': "S'enregistrer au guichet {agency}",
    'airport-collect-luggage': 'Récupérer vos bagages',
    'airport-security-check-go-to-gate':
      "Franchir les contrôles de sécurité et se rendre à la porte d'embarquement",
    'all-routes': 'Toutes lignes',
    'all-routes-disabled': 'Aucune ligne',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arrivée',
    'arriving-soon': 'Imm.',
    'avoid-transfers': 'Éviter les correspondances',
    'avoid-walking': 'Éviter la marche',
    'back-button-title': 'Revenir à la page précédente',
    'back-to-front-page': "Revenir à la page d'accueil",
    bicycle: 'Vélo',
    'bicycle-distance-duration': 'Vélo {duration} ({distance})',
    'bike-availability': 'Vélos disponibles en station',
    'bike-not-allowed-bus': 'Les vélos ne sont pas autorisés dans le bus',
    'bike-not-allowed-tram': 'Les vélos ne sont pas autorisés dans le tram',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    'biketransport-citybike': "J'utilise un vélo en libre service",
    'biketransport-only-bike': "J'utilise uniquement le vélo",
    'biking-amount': 'Quantité de vélo',
    'biking-speed': 'Vitesse en vélo',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Acheter un titre de transport',
    call: 'Appeler',
    cancel: 'Annuler',
    car: 'Voiture',
    'car-distance-duration': 'Conduire {duration} ({distance})',
    car_park: 'parc relais',
    cause: 'Cause',
    'char-left': 'marquer',
    'choose-stop': 'Merci de choisir la ligne',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Vélo en libre service',
    'citybike-distance-duration': 'VLS {duration} ({distance})',
    'citybike-register-required':
      "L'utilisation du Vélo en libre service nécessite une inscription",
    close: 'Fermer',
    'contact-information': 'Contacter {contactName}',
    'create-account': 'Créer un identifiant {contactName}',
    'cycle-distance-duration': 'Pédaler {duration} ({distance})',
    'cyclewalk-distance-duration':
      'Avancer pied à terre sur {duration} ({distance})',
    datasources: 'Sources des données',
    date: 'Date',
    delete: 'Supprimer',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Départs',
    description:
      'Trouvez un réseau de transport public pour voyager loin ou à proximité',
    destination: 'Destination',
    'destination-label-change': 'Changer la destination',
    'destination-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de destination',
    'destination-placeholder': 'Lieu, adresse ou arrêt',
    'dialog-return-to-map': 'Retour à la carte',
    'digitransit-platform': 'Plateforme Digitransit',
    disclaimer:
      'Merci de noter que les résultats sont basés sur des temps de trajet estimés. Les correspondances indiquées ne peuvent être garanties.',
    disruption: 'Perturbation',
    'disruption-info': 'Informations sur les perturbations',
    'disruption-info-no-alerts': "Aucune perturbation n'est répertoriée",
    'disruption-info-route-no-alerts': 'La ligne circule normalement',
    disruptions: 'Perturbations',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-under': 'Distance inférieure à {distance} m',
    earlier: 'Plus tôt',
    'edit-favourites': 'Éditer les favoris',
    explanations: 'Cause',
    'extra-info': 'Informations complémentaires',
    'favourite-target': 'Destination favorite',
    ferry: 'Bâteau',
    'ferry-with-route-number': 'Bâteau {routeNumber} {headSign}',
    frontpage: "Page d'accueil",
    'generic-error': "Une erreur s'est produite",
    'geolocate-yourself': 'Se géolocaliser',
    'geolocation-denied-heading': 'Comment souhaitez-vous démarrer ?',
    'geolocation-denied-text':
      "La géolocalisation n'est pas autorisée. Merci de vérifier votre navigateur/téléphone",
    'geolocation-failed-heading': 'Géolocalisation échouée',
    'geolocation-failed-text':
      'Votre navigateur ne supporte pas le positionnement',
    'geolocation-prompt-text':
      "Merci d'autoriser l'utilisation de votre position.",
    'geolocation-timeout-heading':
      'Le positionnement prend plus de temps que prévu.',
    'geolocation-timeout-text':
      'Merci de sélectionner une origine ou réessayer plus tard.',
    'give-destination': 'Entrer votre destination',
    'give-name-to-location': 'Donner un nom à cet endroit',
    'give-origin': 'Entrer votre point de départ',
    'give-position':
      'Indiquer votre position ou un lieu dans le champ de recherche.',
    'give-route': 'Entrer une ligne',
    'hour-short': 'h',
    'how-to-rate-service': 'Comment noteriez-vous ce service ?',
    'in-addition': 'En plus',
    inquiry: 'Répondre aux questions pour développer le service',
    instructions: 'Instructions',
    'itinerary-feedback-button': 'Envoyez votre avis',
    'itinerary-feedback-message': 'Avez-vous trouvé ce que vous cherchiez ?',
    'itinerary-feedback-placeholder': 'Description (optionnel)',
    'itinerary-hide-stops': 'Cacher les arrêts',
    'itinerary-page.description': 'Itinéraire',
    'itinerary-page.title': 'Itinéraire',
    'itinerary-summary-page.description': 'Trajet recommandé',
    'itinerary-summary-page.title': 'Trajet recommandé',
    'itinerary-ticket.title': 'Titre de transport requis',
    'itinerary-tickets.title': 'Titres de transport requis',
    'itinerary-time.title': 'Duréé',
    'itinerary-walk.title': 'Distance totale de marche',
    'journeyplanner.title': 'Digitransit for Paris',
    later: 'Plus tard',
    leaves: 'Départ',
    'leaving-at': 'Départ',
    'likely-to-recommend':
      'A quel point recommandriez vous ce service à un ami ou un collègue ?',
    'likely-to-use':
      'A quel point utiliseriez-vous ce service plutôt que le service actuel ?',
    loading: 'Chargement',
    locate: 'Localiser',
    'location-examples': 'Gare de Lyon, Longjumeau, Rue du Bac, Châtelet,...',
    'location-from-map': 'Emplacement sélectionné',
    login: 'Se connecter',
    'long-transfers-margin': '12 min',
    'main-menu-label-open': 'Ouvrir le menu principal',
    'main-mode': 'Voyager avec',
    map: 'Carte',
    'menu-feedback': 'Donner votre avis',
    'menu-link-to-privacy-policy': 'Vie privée',
    'messagebar-label-close-message-bar': 'Fermer le message',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Ligne',
    'more-settings': 'Plus de paramètres',
    navigate: 'Voyager',
    nearest: 'Le plus proche',
    'network-error': 'Erreur réseau',
    next: 'Suivant',
    'no-bike-allowed-popup-train':
      'Bicycles are allowed on commuter trains but on long distance trains you have to pay an extra fee and it might require a reservation.',
    'no-bike-allowed-popup-tram-bus':
      "Les vélos ne sont pas autorisés dans les bus ou les trams. Si vous utilisez ces véhicules sur votre trajet, laissez le vélo à l'arrêt ou à un Parc Relais.",
    'no-position': 'Aucune position',
    'no-position-no-stops':
      'Localisation inconnue, impossible de proposer les arrêts à proximité.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      "Impossible de trouver un itinéraire dans cette zone. Changer le point de départ ou d'arrivée.",
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'The origin and the destination are close to each other. Please change either location.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Choisir un point de départ et une destination',
    'no-transfers-margin': 'Aucune',
    'no-trips-found': "Il n'y a pas de trajet possible au jour choisi.",
    now: 'Maintenant',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Sans arrêt} =1 {{number} arrêt} other {{number} arrêts} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minute} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      "En vous connectant, vos favoris seront sauvegardés et accessibles depuis d'autres appareils",
    'option-default': 'Par défaut',
    'option-least': 'Le moins',
    'option-less': 'Moins',
    'option-more': 'Plus',
    'option-most': 'Le plus',
    or: 'ou',
    origin: 'Départ',
    'origin-label-change': 'Changer le départ',
    'origin-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de départ',
    'origin-placeholder': 'Lieu, adresse ou arrêt',
    'own-position': 'Position actuelle',
    'page-not-found': 'Page non trouvée',
    'park-and-ride': 'Parc Relais',
    'park-and-ride-availability': 'Parc Relais disponible',
    'park-and-ride-unknown': 'Capacité inconnue',
    'pay-attention': 'Attention !',
    'pick-icon': 'Sélectionner une icône',
    'pick-mode': 'Sélectionner un mode de transport',
    'place-route-or-keyword': 'Lieu, ligne ou mot-clé',
    'platform-num': 'Quai {platformCode}',
    'platform-short': 'Quai {platformCode}',
    'platform-short-no-num': 'Quai',
    'prefer-walking': 'Préférer la marche',
    previous: 'Précédent',
    print: 'Imprimer',
    'print-timetable': 'Fiche horaire de la semaine',
    'public-transport': 'Transport public',
    rail: 'Train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    realtime: 'Temps-réel',
    'realtime-matching': 'Intégration du temps-réel',
    'rent-cycle-at': 'Décrocher un vélo à la station {station}',
    'required-ticket': 'Titre de transport requis',
    'return-cycle-to': 'Retourner la vélo à la station {station}',
    'right-now': 'Immédiatement',
    route: 'Ligne',
    'route-default': 'Paramètres par défaut',
    'route-destination-arrives': 'Arrivée / Terminus',
    'route-fastest': 'Trajet le plus rapide',
    'route-from-here': "Partir d'ici",
    'route-guide': 'Guide de trajet',
    'route-here': 'Arriver ici',
    'route-least-transfers': 'Éviter les correspondances',
    'route-least-walking': 'Éviter la marche',
    'route-not-possible':
      'Impossible de calculer un itinéraire. Information technique : "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Ligne {shortName}, {longName}',
    'route-page.title': 'Ligne {shortName}',
    'route-page.title-short': 'Ligne',
    routes: 'Lignes',
    run: 'Lancer',
    save: 'Enregistrer',
    search: 'Recherche',
    'search-destination': 'Entrer votre point de destination',
    'search-destination-results-but-no-search':
      "Voir les résultats de l'onglet 'Destination'",
    'search-no-results': 'Aucun résultat',
    'search-origin': 'Entrer votre point de départ',
    'search-position': 'Rechercher la position',
    'search-search-results-but-no-destination':
      "Voir les résultats de l'onglet 'Ligne, arrêt ou mot-clé'",
    'searching-position': 'Localisation en cours...',
    'select-map-layers-header': "Qu'afficher sur la carte ?",
    'select-position': 'Utiliser une localisation précédente',
    send: 'Envoyer',
    settings: 'Réglages',
    'settings-label-change': 'Changer les paramètres',
    share: 'Partager',
    'show-departures': 'Afficher les départs',
    'show-more': 'Afficher plus',
    'show-routes': 'Afficher les lignes',
    'skip-positioning': 'Ignorer le positionnement',
    'skip-to-content': 'Skip to content',
    slow: 'Lent',
    'specify-location': 'Indiquer un endroit',
    'splash-locating': 'Localisation...',
    'splash-or-choose': 'Ou choisir parmi ces destinations',
    'splash-please-allow-positioning':
      'Ce service fonctionne mieux en autorisant la géolocalisation.',
    'splash-use-positioning': 'Utiliser la position',
    'splash-welcome': 'Comment souhaitez-vous démarrer ?',
    'splash-you-can-also': 'Vous pouvez aussi',
    stop: 'Arrêt',
    'stop-number': "Identifiant d'arrêt",
    'stop-page.description': 'Arrêt - {name} {code}, {desc}',
    'stop-page.title': 'Arrêt - {name} {code}',
    'stop-page.title-short': 'Arrêt',
    stops: 'Arrêts',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    'street-mode-bicycle': 'Vélo',
    'street-mode-car': 'Voiture',
    'street-mode-car_park': 'Parc relais',
    'street-mode-public_transport': 'Transport public',
    'street-mode-walk': 'Marche',
    subway: 'Métro',
    'subway-with-route-number': 'Métro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Itinéraire proposé',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal - {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'CGU',
    'ticket-and-price-info': 'Titres et informations tarifaires',
    'ticket-single-adult': 'Adulte',
    'ticket-type-none': 'Pas de limite tarifaire',
    tickets: 'Titres de transport',
    time: 'Heure',
    timetable: 'Fiche horaire',

    today: "Aujourd'hui",
    tomorrow: 'Demain',
    tram: 'Tramway',
    'tram-with-route-number': 'Tramway {routeNumber} {headSign}',
    transfers: 'Correspondances',
    'transfers-allowed': 'Autoriser les correspondances',
    'transfers-margin': 'Marge de correspondance',
    'trip-co2-emissions': 'Émissions de CO2',
    'trip-information': 'Informations de voyage',
    'trip-page.description': 'Ligne {shortName}, {longName}',
    'trip-page.title': 'Ligne {shortName}',
    'try-again': 'Réessayer',
    'use-citybike': 'Utiliser',
    'use-national-service':
      'Vous pouvez aussi utiliser le service national disponible à',
    'use-own-position': 'Utiliser la position actuelle',
    'using-modes': 'Modes de transport',
    'very-likely': 'Très probable',
    'very-unlikely': 'Très improbable',
    'via-leg-stop-duration': "Attente de {stayDuration} à l'étape",
    'via-point': 'Étape',
    'via-point-index': 'Étape {index}',
    'viapoint-slack-amount': "Durée de l'étape",
    'view-route': "Voir l'itinéraire",
    wait: 'Attendre',
    'wait-amount-of-time': 'Attendre {duration}',
    walk: 'marche',
    'walk-distance-duration': 'Marcher {duration} ({distance})',
    walking: 'Marcher',
    'walking-speed': 'Vitesse de marche',
    'warning-call-agency':
      "La ligne {routeName} est à la demande, et doit être réservée à l'avance",
    'warning-call-agency-no-route':
      "Uniquement à la demande. Doit être réservé à l'avance",
    'weather-at-destination': 'Météo à destination',
    yesterday: 'Hier',
    zones: 'Zones tarifaires',
  },

  nb: {
    'about-this-service': 'Om tjenesten',
    accessibility: 'Tilgjengelighet',
    'add-itinerary-via-point': 'Tilføy viapunkt i reisen',
    'add-location-to-favourites': 'Legg til sted i favoritter',
    address: 'Adresse',
    'address-or-stop': 'Adresse eller stoppested',
    agency: 'Operatør',
    airplane: 'Fly',
    'airplane-with-route-number': 'Fly {routeNumber}',
    'airport-check-in': 'Bagasje kan sjekkes inn hos {agency}',
    'airport-collect-luggage': 'Hent eventuell bagasje',
    'airport-security-check-go-to-gate':
      'Passer sikkerhetskontrollen og fortsett til gate',
    'approx-meters': '{approxDistance} meter',
    'arriving-at': 'Ankomst',
    'arriving-soon': 'Nå',
    'avoid-transfers': 'Unngå bytter',
    'avoid-walking': 'Unngå gange',
    'back-button-title': 'Gå tilbake til forrige side',
    'back-to-front-page': 'Tilbake til forsiden ›',
    'bike-availability': 'Sykler finnes',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    bus: 'Buss',
    'bus-with-route-number': 'Buss {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Kjøp billett',
    call: 'Ring',
    cancel: 'Avbryt',
    'car-distance-duration': 'Kjør {duration} ({distance})',
    cause: 'Årsak',
    'char-left': 'slett',
    'choose-stop': 'Velg stopp',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Bysykkel',
    'citybike-register-required': 'Bysykkelregistrering påkreves',
    close: 'Steng',
    'contact-information': '{contactName} kontaktinformasjon',
    'create-account': 'Lag {contactName}-konto',
    'cycle-distance-duration': 'Sykle {duration} ({distance})',
    'cyclewalk-distance-duration': 'Trill sykkelen {duration} ({distance})',
    datasources: 'Datakilder',
    delete: 'Slett',
    'departure-time-in-minutes': '{minutes} m',
    departures: 'Avganger',
    description:
      'En nasjonal reiseplanlegger skal bidra til å gjøre det lettere å reise kollektivt over hele landet.',
    destination: 'Til',
    'destination-label-change': 'Endre destinasjon',
    'destination-placeholder': 'Hvor vil du reise?',
    'digitransit-platform': 'Digitransit platform',
    'disruption-info': 'Avviksmelding',
    'disruption-info-no-alerts': 'Ingen avviksmeldinger.',
    'disruption-info-route-no-alerts': 'Ingen avviksmeldinger.',
    disruptions: 'Avvik',
    'distance-between': 'Avstand {distance1} m — {distance2} m',
    'distance-under': 'Avstand mindre enn {distance} m',
    earlier: 'Tidligere',
    'edit-favourites': 'Rediger favorittsted',
    'extra-info': 'Mer info',
    'favourite-target': 'Favorittdestinasjon',
    ferry: 'Ferge',
    'ferry-with-route-number': 'Ferge {routeNumber} {headSign}',
    frontpage: 'Forside',
    'geolocate-yourself': 'Finn min posisjon',
    'geolocation-denied-heading':
      'Tjenesten fungerer best når du oppgir din posisjon',
    'geolocation-denied-text':
      'Lokalisering er ikke tillatt. Sjekk innstillingene for nettleseren din.',
    'geolocation-failed-heading': 'Lokalisering fungerer ikke',
    'geolocation-failed-text':
      'Nettleseren din har ikke støtte for lokalisering.',
    'geolocation-prompt-text': 'Vennligst aksepter lokalisering.',
    'geolocation-timeout-heading': 'Lokalisering tar lengre tid enn forventet.',
    'geolocation-timeout-text': 'Velg startsted og prøv igjen.',
    'give-destination': 'skriv hvor du vil reise',
    'give-name-to-location': 'Skriv inn et beskrivende navn på stedet',
    'give-origin': 'skriv avgangssted',
    'give-position': 'Skriv inn posisjon i søkefeltet over.',
    'hour-short': 't',
    'how-to-rate-service': 'Hvordan vil du rangere tjenesten?',
    'in-addition': 'Og',
    inquiry: 'Send tilbakemelding',
    instructions: 'Instruksjoner',
    'itinerary-feedback-button': 'Send tilbakemelding',
    'itinerary-feedback-message': 'Fant du ikke det du lette etter?',
    'itinerary-feedback-placeholder': 'Beskrivelse (valgfritt)',
    'itinerary-hide-stops': 'Skjul stopp',
    'itinerary-page.description': 'Reisebeskrivelser',
    'itinerary-page.title': 'Reisebeskrivelser',
    'itinerary-summary-page.description': 'Anbefalte ruter',
    'itinerary-summary-page.title': 'Anbefalte ruter',
    later: 'Senere',
    leaves: 'Går',
    'leaving-at': 'Avgang',
    'likely-to-recommend':
      'Hvor sannsynlig er det at du anbefaler vår tjeneste til en venn eller kollega?',
    'likely-to-use':
      'Hvor sannsynlig er det at du vil bruke denne tjenesten fremfor tjenesten du allerede bruker?',
    loading: 'laster',
    locate: 'Der jeg er',
    'location-examples': 'f.eks hjemme, arbeid, skole ...',
    'location-from-map': 'Velg posisjon',
    login: 'Logg inn',
    'long-transfers-margin': 'Veldig lang',
    'main-menu-label-open': 'Åpen hovedmenyen',
    'main-mode': 'Jeg reiser med',
    map: 'Kart',
    'messagebar-label-close-message-bar': 'Lukk melding',
    'messagebar-label-page': 'Side',
    'minute-short': 'm',
    minutes: 'min',
    navigate: 'Naviger',
    nearest: 'Forrige',
    'network-error': 'Nettverksfeil',
    next: 'Neste',
    'no-position': 'Ingen posisjon',
    'no-position-no-stops':
      'Kan ikke vise holdeplasser i nærheten da vi ikke vet din posisjon.',
    'no-route-already-at-destination': 'You are already at your destination.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Vi finner dessverre ingen reiseforslag til angitt rute. Forsøk å endre adresse',
    'no-route-msg-with-changes':
      'Unfortunately no routes were found for your journey. Try changing your settings, origin or destination.',
    'no-route-origin-near-destination':
      'The origin and the destination are close to each other. Please change either location.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Vennligst velg avgangssted og destinasjon.',
    'no-transfers-margin': 'Ingen',
    'no-trips-found': 'Ingen reiser tilgjenelig for denne dagen.',
    now: 'Nå',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ingen stopp} other {{number} stopp} }',
    'off-canvas-info':
      'Ved å logge inn kan du finne tilbake til dine favoritter på andre enheter',
    or: 'eller',
    origin: 'Fra',
    'origin-label-change': 'Endre avreisested',
    'origin-placeholder': 'Fra',
    'own-position': 'Min posisjon',
    'page-not-found': 'Siden finnes ikke ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Innfartsparkering',
    'park-and-ride-availability': 'Tilgjengelighet',
    'park-and-ride-unknown': 'Ukjent kapasitet',
    'pay-attention': 'Obs!',
    'pick-icon': 'Velg et ikon',
    'place-route-or-keyword': 'Adresse eller stoppested',
    'platform-num': 'Plattform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'platform-short-no-num': 'Plat.',
    'prefer-walking': 'Foretrekk gange',
    previous: 'Tidligere',
    print: 'Skriv ut',
    'print-timetable': 'Tidstabeller',
    rail: 'Tog',
    'rail-with-route-number': 'Tog {routeNumber} {headSign}',
    'realtime-matching': 'Sanntidsmatching',
    'rent-cycle-at': 'Lei sykkel fra stativ {station}',
    'required-ticket': 'Billett før påstigning',
    'right-now': 'Nå',
    route: 'linje',
    'route-destination-arrives': 'Destinasjon / Siste holdeplass',
    'route-from-here': 'Finn rute herfra',
    'route-here': 'Finn rute hit',
    'route-not-possible':
      'Dessverre kan vi ikke finne et reiseforslag til deg. Teknisk feil: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Rute {shortName}, {longName}',
    'route-page.title': 'Rute {shortName}',
    'route-page.title-short': 'Rute',
    routes: 'Ruter',
    run: 'Løping',
    save: 'Lagre',
    search: 'Til',
    'search-destination-results-but-no-search': 'Se resultater i Til-fanen',
    'search-no-results': 'Ingen resultater',
    'search-origin': 'skriv avgangssted',
    'search-position': 'Let etter posisjon',
    'search-search-results-but-no-destination': 'Se resultater i Fra-fanen',
    'searching-position': 'Leter etter posisjon',
    'select-position': 'Velg din posisjon basert på tidligere søk',
    send: 'Send',
    settings: 'Innstillinger',
    'settings-label-change': 'Endre innstillinger',
    share: 'Del reisen',
    'show-departures': 'Vis avganger',
    'show-more': 'Vis flere',
    'skip-positioning': 'Hopp over',
    'skip-to-content': 'Skip to content',
    slow: 'Spasering',
    'specify-location': 'Angi sted',
    'splash-locating': 'Søker etter din posisjon',
    'splash-or-choose': 'Eller velg din posisjon fra',
    'splash-use-positioning': 'Bruk min posisjon',
    'splash-welcome': 'Velkommen til ny versjon av din reiseplanlegger',
    'splash-you-can-also': 'Alternativt',
    stop: 'Holdeplass',
    'stop-number': 'Holdeplassnummer',
    'stop-page.description': 'Holdeplass - {name} {code}, {desc}',
    'stop-page.title': 'Holdeplass - {name} {code}',
    'stop-page.title-short': 'Holdeplass',
    stops: 'Holdeplasser',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    subway: 'T-bane',
    'subway-with-route-number': 'T-bane {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Anbefalte ruter',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Brukervilkår',
    'ticket-and-price-info': 'Billett- og prisinformasjon',
    tickets: 'Billetter',
    time: 'Tid',
    timetable: 'Tidstabeller',

    today: 'I dag',
    tomorrow: 'I morgen',
    tram: 'Trikk',
    'tram-with-route-number': 'Trikk {routeNumber} {headSign}',
    transfers: 'Bytter',
    'transfers-allowed': 'Bytter tillatt',
    'transfers-margin': 'Byttetid',
    'trip-co2-emissions': 'Reisens CO2-utslipp',
    'trip-page.description': 'Linje {shortName}, {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Prøv igjen.',
    'use-citybike': 'Bruk bysykkel',
    'use-own-position': 'Bruk min posisjon',
    'using-modes': 'Transportform',
    'very-likely': 'Svært sannsynlig',
    'very-unlikely': 'Lite sannsynlig',
    'via-leg-stop-duration': 'Viapunkt {stayDuration}',
    'via-point': 'Viapunkt',
    'via-point-index': 'Viapunkt {index}',
    wait: 'Vent',
    'wait-amount-of-time': 'Vent {duration}',
    walk: 'gå',
    'walk-distance-duration': 'Gå {duration} ({distance})',
    walking: 'Gange',
    'walking-speed': 'Ganghastighet',
    'warning-call-agency':
      'Bestillingsrute: {routeName}, avgangen må forhåndsbestilles fra operatør.',
    'warning-call-agency-no-route':
      'Kun bestillingsrute. Avgangen må forhåndsbestilles fra operatør.',
    'weather-at-destination': 'Været på destinasjonen',
    yesterday: 'I går',
    zones: 'Billettsoner',
  },

  ro: {
    'about-this-service': 'Despre acest serviciu',
    accessibility: 'Accesibilitate',
    'accessibility-limited': 'Scaun cu rotile',
    'accessibility-nolimit': 'Fără limite',
    'add-itinerary-via-point': 'Adaugă punct intermediar',
    'add-location-to-favourites': 'Adaugă un punct important la favorite',
    'add-via-button-label': 'Adaugă intermediar',
    'add-via-duration-button-label':
      'Introdu durata staționării la punctul intermediar {index}',
    'add-via-duration-button-label-close':
      'Închideți selectorul de durată a staționării la punctul intermediar {index}',
    'add-via-duration-button-label-open':
      'Deschideți selectorul de durată a staționării la punctul intermediar {index}',
    'arrive-leave': 'Ajungeți sau plecați la ora selectată',
    'search-autosuggest-label':
      'Locații, adrese, stații. Selectați cu mouse-ul sau alegeți din săgețile tastaturii.',
    'search-autosuggest-len': 'Am găsit {len} sugestii',
    'search-current-suggestion': 'Current selection: {selection}',
    'select-date': 'Selectați data',
    'set-time-earlier-button-label':
      'Setați timpul călătoriei pentru mai devreme',
    'set-time-later-button-label': 'Setați timpul călătoriei pentru mai târziu',
    'set-time-now-button-label': 'Setează ora la cea curentă',
    'time-navigation-buttons': 'Butoane pentru modificarea orei',
    'time-selector-form': 'Modificați ora călătoriei',
    // eslint-disable-next-line sort-keys
    address: 'Adresă',
    'address-or-stop': 'Adresă sau stație',
    agency: 'Companie',
    airplane: 'Zbor',
    'airplane-with-route-number': 'Zborul {routeNumber}',
    'airport-check-in': 'Check-in la ghișeul {agency}',
    'airport-collect-luggage': 'Ridicați bagajul',
    'airport-security-check-go-to-gate':
      'Mergeți spre poartă, trecând prin controlul de securitate',
    'all-routes': 'Toate',
    'all-routes-disabled': 'Niciuna',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Ajunge',
    'arriving-soon': 'Acum',
    'at-time': 'la',
    'avoid-transfers': 'Evită schimbările',
    'avoid-walking': 'Evită mersul pe jos',
    'back-button-title': 'Înapoi la pagina anterioară',
    'back-to-front-page': 'Înapoi la pagina principală',
    bicycle: 'Bicicletă',
    'bicycle-distance-duration': 'Bicicletă {duration} ({distance})',
    'bike-availability': 'Biciclete disponibile la destinație',
    'bike-not-allowed-bus': 'Bicicletele nu sunt permise în autobuz',
    'bike-not-allowed-tram': 'Bicicletele nu sunt permise în tramvai',
    'bike-station-disabled': 'Pyöräasema ei ole käytössä.',
    'biketransport-citybike': 'Folosesc o bicicletă de închiriat',
    'biketransport-only-bike': 'Folosesc doar bicicleta',
    'biking-amount': 'Distanța pe bicicletă',
    'biking-speed': 'Viteza mersului pe bicicletă',
    bus: 'Autobuz',
    'bus-with-route-number': 'Autobuzul {routeNumber} {headSign}',
    buy: 'Buy',
    'buy-ticket': 'Cum cumpăr bilet?',
    call: 'Call',
    cancel: 'Anulare',
    canceled: 'Anulat',
    'canceled-itineraries-amount':
      'Și {itineraryAmount, plural, =1 {un itinerar} alte {{itineraryAmount} itinerarii anulate}}',
    'canceled-itineraries-amount-hide':
      'Ascunde itinerarii anulate ({itineraryAmount})',
    'canceled-legs': 'Plecări anulate',
    car: 'Mașină',
    'car-distance-duration': 'Condu {duration} ({distance})',
    car_park: 'park & ride',
    cause: 'cauză',
    'char-left': 'caractere',
    'choose-stop': 'Selectare stație',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Bicicletă de închiriat',
    'citybike-distance-duration': 'Bicicletă {duration} ({distance})',
    'citybike-network-headers': 'Biciclete, trotinete, scutere',
    'citybike-register-required':
      'Pentru a folosi biciclete de închiriat vă trebuie un cont',
    'clear-button-label': 'Șterge',
    close: 'Închide',
    'contact-information': '{contactName} detalii de contact',
    continue: 'Continuă',
    'create-account': 'Creare cont {contactName}',
    'cycle-distance-duration': 'Pedalați {duration} ({distance})',
    'cyclewalk-distance-duration':
      'Împingeți bicicleta {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Locația curentă',
    datasources: 'Surse de informații',
    date: 'Data',
    delete: 'Ștergere',
    'departure-is-canceled': '{modeInfo} {from}–{to} la {time} este anulat',
    'departure-is-canceled-airplane': 'Zborul {shortName}',
    'departure-is-canceled-bus': 'Autobuzul {shortName}',
    'departure-is-canceled-ferry': 'Feribotul {shortName}',
    'departure-is-canceled-rail': 'Trenul {shortName}',
    'departure-is-canceled-subway': 'Metroul {shortName}',
    'departure-is-canceled-tram': 'Tramvaiul {shortName}',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Plecări',
    description: 'Găsiți transport către locații din apropiere și nu numai',
    destination: 'Destinație',
    'destination-outside-service':
      'Nu am găsit legături între aceste locații. Căutați în interiorul ariei de serviciu.',
    'destination-placeholder': 'Introduceți o destinație, rută sau stație',
    'dialog-return-to-map': 'Înapoi la hartă',
    'digitransit-platform': 'Platforma Digitransit',
    disclaimer:
      'Luați în calcul faptul că datele prezentate sunt estimări. Nu garantăm că veți prinde exact aceste legături.',
    disruption: 'Incident',
    'disruption-info': 'Incidente pe traseu',
    'disruption-info-no-alerts': 'Nu se cunosc incidente sau detururi.',
    'disruption-info-route-no-alerts':
      'Nu se cunosc incidente sau detururi pe acest traseu.',
    disruptions: 'Incidente',
    'distance-between': 'Distanța {distance1} m — {distance2} m',
    'distance-total': 'Distanța totală',
    'distance-under': 'Mai puțin de {distance} m în total',
    earlier: 'Mai devreme',
    'edit-favourites': 'Mofidicați locația în secțiunea Favorite',
    'elevation-gained-total': 'Urcare în altitudine',
    'elevation-lost-total': 'Scădere în altitudine',
    explanations: 'Explicații',
    'extra-info': 'Informații detaliate',
    'favourite-target': 'Locație favorită',
    ferry: 'Feribot',
    'ferry-with-route-number': 'Feribotul {routeNumber} {headSign}',
    'fetch-new-route': 'Încărcați o nouă rută',
    frontpage: 'Prima pagină',
    'generic-error': 'A apărut o eroare',
    'geolocate-yourself': 'Detectați locația',
    'geolocation-denied-heading': 'Geolocalizare nepermisă',
    'geolocation-denied-text':
      'Nu ați permis accesul la datele de localizare. Puteți schimba acest lucru în preferințele browserului.',
    'geolocation-failed-heading': 'Localizarea a eșuat',
    'geolocation-failed-text': 'Browserul nu suportă servicii de localizare.',
    'geolocation-prompt-text':
      'Vă rugăm să acceptați cererea browserului privind localizarea.',
    'geolocation-timeout-heading':
      'Durează mai mult decât ar trebui să detectăm locația.',
    'geolocation-timeout-text':
      'Ați acceptat cererea de permitere a localizării?',
    'give-destination': 'Introduceți destinația',
    'give-name-to-location':
      'Dați locației un nume descriptiv și ușor de reținut',
    'give-origin': 'Introduceți punctul de plecare',
    'give-position': 'Introduceți locația sau punctul de plecare în câmp.',
    'give-route': 'Caută rută',
    hour: 'Oră',
    'hour-short': 'h',
    'how-to-rate-service': 'Cum evaluați serviciul?',
    'in-addition': 'Pe lângă',
    inquiry:
      'Cum vi se pare noul planificator de călătorii? Vă rugăm să ne spuneți!',
    instructions: 'Instrucțiuni',
    'itinerary-details.biking-leg':
      'La {time} pedalați {distance} de la {origin} la {destination}. Va dura aproximativ {duration}',
    'itinerary-details.car-leg':
      'La {time} conduceți {distance} de la {origin} la {destination}. Va dura aproximativ {duration}',
    'itinerary-details.end-leg':
      'La {time} ajungeți la destinație {destination}.',
    'itinerary-details.route-has-info-alert': 'Ruta are avertizări.',
    'itinerary-details.route-has-severe-alert': 'Ruta are incidente grave.',
    'itinerary-details.route-has-unknown-alert': 'Ruta are incidente moderate.',
    'itinerary-details.route-has-warning-alert': 'Ruta are incidente.',
    'itinerary-details.scooter-leg':
      'La {time} mergeți pe trotineta închiriată {distance} de la {origin} la {destination}. Va dura aproximativ {duration}',
    'itinerary-details.transit-leg-part-1': 'La {time} luați',
    'itinerary-details.transit-leg-part-2':
      'de la stația {startStop} {trackInfo} până la stația {endStop}. Va dura aproximativ {duration}',
    'itinerary-details.walk-leg':
      'At {time} mergeți pe jos {distance} de la {origin} la {destination}. Va dura aproximativ {duration}',
    'itinerary-feedback-button': 'Sugestii și reclamații',
    'itinerary-feedback-message': 'Nu ați găsit ce căutați?',
    'itinerary-feedback-placeholder': 'Descriere (opțional)',
    'itinerary-hide-stops': 'Ascunde stațiile',
    'itinerary-page.description': 'Itinerar',
    'itinerary-page.hide-details': 'Ascunde detalierea itinerarului',
    'itinerary-page.show-details': 'Afișează detalierea itinerarului',
    'itinerary-page.title': 'Itinerar',
    'itinerary-page.update-alert': 'Rezultatele căutării au fost actualizate',
    'itinerary-summary-page-street-mode.update-alert':
      'Walking and biking results updated',
    'itinerary-summary-page.description': 'Sugestii de trasee',
    'itinerary-summary-page.title': 'Sugestii de trasee',
    'itinerary-summary-row.biking-distance':
      'Distanța totală de pedalat {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Afișați pe hartă',
    'itinerary-summary-row.description':
      'Itinerarul cu plecare la {departureDate} {departureTime} și sosire la {arrivalDate} {arrivalTime}. {firstDeparture} {transfers} Timp total {totalTime}.',
    'itinerary-summary-row.first-departure':
      '{vehicle} pleacă la {departureTime} de la {stopName}.',
    'itinerary-summary-row.transfers': 'Schimbați cu {vehicle} la {stopName}',
    'itinerary-summary-row.walking-distance':
      'Distanța totală de mers pe jos {totalDistance}.',
    'itinerary-summary.show-on-map': 'Vedeți pe hartă {target}',
    'itinerary-ticket.title': 'Bilet necesar',
    'itinerary-tickets.title': 'Bilete necesare',
    'itinerary-time.title': 'Durata',
    'itinerary-walk.title': 'Distanța totală de mers pe jos',
    'journeyplanner.title': 'Planificator de călătorie',
    later: 'Mai târziu',
    leaves: 'Plecare',
    'leaving-at': 'Pleacă la',
    'likely-to-recommend':
      'Cât de probabil e să recomandați acest serviciu prietenilor sau familiei?',
    'likely-to-use':
      'Cât de probabil ar fi să folosiți acest planificator de călătorie față de cel curent?',
    loading: 'Se încarcă...',
    localadmin: 'Locality',
    locate: 'Detectați locația',
    'location-examples': 'e.g. Acasă, școală, etc...',
    'location-from-map': 'Locația selectată',
    'location-home': 'Acasă',
    'location-school': 'Școală',
    'location-shopping': 'Cumpărături',
    'location-sport': 'Sport',
    'location-work': 'Muncă',
    login: 'Autentificare',
    logout: 'Ieșire',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Închideți meniul principal',
    'main-menu-label-open': 'Deschideți meniul principal',
    'main-mode': 'Călătoresc cu',
    map: 'Harta',
    'map-layer-citybike': 'Închiriere biciclete',
    'map-layer-park-and-ride': 'Parcări Park & ride',
    'map-layer-stop-bus': 'Stații de autobuz',
    'map-layer-stop-ferry': 'Feribot',
    'map-layer-stop-tram': 'Stații de tramvai',
    'map-layer-terminal-bus': 'Autogări',
    'map-layer-terminal-rail': 'Gări',
    'map-layer-terminal-subway': 'Stații de metrou',
    'map-layer-vehicles': 'Vehicles',
    'menu-faq': 'Întrebări frecvente',
    'menu-feedback': 'Spuneți-vă părerea',
    'menu-link-to-privacy-policy': 'Politica de confidențialitate',
    'messagebar-label-close-message-bar': 'Închideți',
    'messagebar-label-page': 'Pagina',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Rută',
    'more-settings': 'Mai multe setări',
    navigate: 'Navigare',
    nearest: 'Cel mai aproape',
    'network-error': 'Eroare de rețea',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Cartier ',
    next: 'Următorul',
    'no-bike-allowed-popup-train':
      'Bicicletele sunt permise în trenurile de navetă, însă e posibil să aveți nevoie de un bilet suplimentar sau de rezervare prealabilă.',
    'no-bike-allowed-popup-tram-bus':
      'Bicicletele nu sunt permise în tramvai și autobuz. Lăsați bicicleta într-un rastel sau o parcare dacă o folosiți pe ruta dumneavoastră.',
    'no-departures': 'Nu am găsit plecări.',
    'no-position': 'Nicio locație.',
    'no-position-no-stops':
      'Nu v-am găsit locația. Nu putem afișa stații în zonă.',
    'no-route-already-at-destination': 'Sunteți deja la destinație.',
    'no-route-end-date-not-in-range': 'Nu am găsit rute mai târziu în orar.',
    'no-route-msg':
      'Nu s-a găsit nicio rută conform selecției. Schimbați punctul de plecare sau destinație.',
    'no-route-msg-with-changes':
      'Nu s-a găsit nicio rută conform selecției. Schimbați plecarea, destinația sau parametrii introduși ca preferințe.',
    'no-route-origin-near-destination':
      'Plecarea și destinația sunt în puncte prea apropiate. Vă rugăm să mutați una din ele.',
    'no-route-start-date-too-early': 'Nu am găsit curse mai devreme în orar.',
    'no-route-start-end': 'Selectați punctele de plecare și destinație.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Nicio călătorie găsită pentru data respectivă.',
    now: 'Now',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Nicio stație intermediară} O {1 stație} altă {{number} opriri} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minute} Un {1 minut} alt {{number} minute}}',
    'off-canvas-info':
      'Autentificați-vă pe platformă pentru a vă salva locațiile favorite și a le putea utiliza pe alte dispozitive.',
    'option-default': 'Implicit',
    'option-least': 'Cel mai puțin',
    'option-less': 'Mai puțin',
    'option-more': 'Mai mult',
    'option-most': 'Cel mai mult',
    or: 'or',
    origin: 'Plecare',
    'origin-outside-service':
      'Unfortunately no routes were found for your journey. Your origin address is located outside the service area, please change it.',
    'origin-placeholder': 'De la? - adresă sau stație',
    'own-position': 'Locația curentă',
    'page-not-found': 'Nu am găsit pagina căutată',
    'park-and-ride': 'Parcare Park and Ride',
    'park-and-ride-availability': 'Locuri disponibile',
    'pay-attention': 'Atenție!',
    'pick-icon': 'Selectați o pictogramă',
    'pick-mode': 'Selectați modul de transport',
    'pick-mode-airplane-disabled': 'Avion ne-selectat',
    'pick-mode-airplane-enabled': 'Avion selectat',
    'pick-mode-bus-disabled': 'Autobuz ne-selectat',
    'pick-mode-bus-enabled': 'Autobuz selectat',
    'pick-mode-citybike-disabled': 'Bicicletă de închiriat ne-selectată',
    'pick-mode-citybike-enabled': 'Bicicletă de închiriat selectată',
    'pick-mode-ferry-disabled': 'Feribot ne-selectat',
    'pick-mode-ferry-enabled': 'Feribot selectat',
    'pick-mode-rail-disabled': 'Tren ne-selectat',
    'pick-mode-rail-enabled': 'Tren selectat',
    'pick-mode-selected': 'Moduri de transport selectate',
    'pick-mode-subway-disabled': 'Metrou ne-selectat',
    'pick-mode-subway-enabled': 'Metrou selectat',
    'pick-mode-tram-disabled': 'Tramvai ne-selectat',
    'pick-mode-tram-enabled': 'Tramvai selectat',
    'place-route-or-keyword': 'Destinație, rută sau stație',
    'platform-num': 'Peron {platformCode}',
    'platform-short': 'Prn. {platformCode}',
    'platform-short-no-num': 'Prn.',
    'prefer-walking': 'Preferă mersul pe jos',
    previous: 'Precedenta',
    print: 'Imprimați',
    'print-timetable': 'Orar săptămânal',
    'public-transport': 'Transport public',
    rail: 'Tren',
    'rail-with-route-number': 'Trenul {routeNumber} {headSign}',
    realtime: 'În timp real',
    'realtime-matching': 'Informații în timp-real',
    releases: 'Lansări',
    'remove-via-button-label': 'Ștergeți punct intermediar {index}',
    'rent-cycle-at': 'Închiriați o bicicletă la stația {station}',
    'rent-scooter-at': 'Închiriați o trotinetă electrică la stația {station}',
    'required-ticket': 'Bilet necear pentru călătorie',
    'return-cycle-to': 'Returnați bicicleta la stația {station}',
    'return-scooter-to': 'Returnați trotineta electrică la stația {station}',
    'right-now': 'Chiar acum',
    route: 'Ruta',
    'route-add-viapoint': 'Punct intermediar',
    'route-custom-settings': 'Setări personalizate',
    'route-default': 'Setări implicite',
    'route-destination-arrives': 'Doar coborâre',
    'route-destination-endpoint': 'Sosire / Terminal',
    'route-fastest': 'Ruta rapidă',
    'route-from-here': 'Plecare',
    'route-guide': 'Ghidul rutelor',
    'route-here': 'Destinație',
    'route-least-elevation-changes': 'Evitați variația de altitudine',
    'route-least-transfers': 'Evitați schimbările de vehicul',
    'route-least-walking': 'Evitați mersul pe jos',
    'route-not-possible': 'Rută indisponibiă. Cod eroare: "{error}"',
    'route-page-vehicle-position': '{mode} {shortName} is at {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} is between {stopName} and {nextStopName}',
    'route-page.description': 'Ruta {shortName} - {longName}',
    'route-page.title': 'Ruta {shortName}',
    'route-page.title-short': 'Ruta',
    routes: 'Rute',
    'routes-platforms': 'Rute, peroane',
    'routes-tracks': 'Rute, linii',
    run: 'Rulează',
    save: 'Salvare',
    'scooter-availability': 'Trotinete disponibile în stație',
    'scooter-distance-duration': 'Mergeți pe trotinetă {duration} ({distance})',
    'scooter-register-required':
      'Pentru a utiliza trotinetele, trebuie să vă autentificați',
    'scooterwalk-distance-duration':
      'Împingeți trotineta {duration} ({distance})',
    search: 'Caută',
    'search-button-label': 'Caută',
    'search-destination': 'Căutați punctul de plecare, ruta sau stația',
    'search-destination-index': 'Enter destination',
    'search-destination-results-but-no-search':
      'Vedeți rezultatele în fereastra “Destinație”',
    'search-no-results': 'Nicio locație',
    'search-origin': 'Căutați destinația, ruta sau stația',
    'search-origin-index': 'Enter origin',
    'search-position': 'Detectați locația',
    'search-search-results-but-no-destination':
      'Vedeți rezultatele în fereastra “Despre rută sau stație”',
    'searching-position': 'Se caută locația...',
    'select-map-layers-header': 'Ce ar trebui să apară pe hartă?',
    'select-position': 'Locații din căutările anterioare',
    send: 'Trimite',
    'separate-ticket-required':
      'Un bilet separat e necesar pentru o parte din acest itinerar.',
    'separate-ticket-required-disclaimer':
      'Acest itinerar conține una sau mai multe rute pentru care {agencyName} nu comercializează bilete.',
    settings: 'Setări',
    'settings-label-change': 'Schimbă setări',
    share: 'Trimite itinerar',
    show: 'Afișare',
    'show-departures': 'Arată plecări',
    'show-more': 'Arată mai multe',
    'show-routes': 'Alege rute',
    'skip-positioning': 'Sari',
    'skip-to-content': 'Către conținut',
    slow: 'Lent',
    'specify-location': 'Specificați locația',
    'splash-locating': 'Detectați automat locația',
    'splash-or-choose': 'sau alegeți manual un punct de pornire',
    'splash-use-positioning': 'Utilizați servicii de localizare',
    'splash-welcome': 'Cum doriți să începeți?',
    'splash-you-can-also': 'sau',
    station: 'Station',
    stop: 'Stație',
    'stop-number': 'Numărul stației',
    'stop-page.description': 'Stația {name} - {code}',
    'stop-page.title': 'Stația {name} - {code}',
    'stop-page.title-short': 'Stație',
    'stop-virtual-monitor': 'Panou plecări',
    stops: 'Stații',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    'street-mode-bicycle': 'Bicicletă',
    'street-mode-car': 'Mașină',
    'street-mode-car_park': 'Parcare Park & ride',
    'street-mode-public_transport': 'Transport public',
    'street-mode-walk': 'Mers pe jos',
    // eslint-disable-next-line sort-keys
    street: 'Stradă',
    subway: 'Metrou',
    'subway-with-route-number': 'Metroul {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Sugestii itinerar {number}',
    'summary-page.title': 'Sugestii itinerar',
    'swap-order-button-label': 'Schimbați originea cu destinația',
    'swipe-result-tab-left':
      'Swipe result tabs left arrow. Press Enter or Space to show the previous tab.',
    'swipe-result-tab-right':
      'Swipe result tabs right arrow. Press Enter or Space to show the next tab.',
    'swipe-result-tabs': 'Switch tabs using arrow keys.',
    'terminal-page.description': 'Terminalul {name}',
    'terminal-page.title': 'Terminalul {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Termeni de utilizare',
    'ticket-and-price-info': 'Prețul și achiziționarea biletelor',
    'ticket-single-adult': 'Adult',
    'ticket-type-none': 'Fără limitarea zonelor tarifare',
    tickets: 'Bilete',
    time: 'Timp',
    'time-selector-hours-label': 'Ora',
    'time-selector-minutes-label': 'Minut',
    timetable: 'Orar',
    today: 'Azi',
    tomorrow: 'Mâine',
    'track-num': 'Peronul {platformCode}',
    'track-short': 'Peronul {platformCode}',
    tram: 'Tramvai',
    'tram-with-route-number': 'Tramvaiul {routeNumber} {headSign}',
    transfers: 'Număr de schimbări',
    'transfers-allowed': 'Număr de schimbări permise',
    'transfers-margin': 'Așteptarea minimă la transfer',
    'travel-time': '{min} min',
    'travel-time-label': 'Timp de călătorie',
    'travel-time-with-hours': '{h} o {min} min',
    'trip-co2-emissions': 'Emisii CO2',
    'trip-information': 'Informații călătorie',
    'trip-page.description': 'Ruta {route_short_name} - {route_long_name}',
    'trip-page.title': 'Ruta {route_short_name}',
    'try-again': 'Încercați din nou',
    'use-citybike': 'Începeți să utilizați',
    'use-national-service': 'Încercați și serviciul disponibil la',
    'use-own-position': 'Folosiți locația curentă',
    'using-modes': 'Vreau să călătoresc cu',
    'very-likely': 'Foarte probabil',
    'very-unlikely': 'Foarte improbabil',
    'via-leg-stop-duration': 'La orice punct {stayDuration}',
    'via-point': 'Punct intermediar',
    'via-point-index': 'Punctul intermediar {index}',
    'viapoint-slack-amount': 'Durata opririi',
    'view-route': 'Vedeți ruta',
    // eslint-disable-next-line sort-keys
    venue: 'Locație',
    wait: 'Așteptare',
    'wait-amount-of-time': 'Așteptați {duration}',
    walk: 'mers pe jos',
    'walk-distance-duration': 'Mergeți pe jos {duration} ({distance})',
    walking: 'Distanța de mers pe jos',
    'walking-speed': 'Viteza mersului pe jos',
    'warning-call-agency':
      'Doar la cerere: {routeName}, trebuie rezervată în avans vorbind cu compania.',
    'warning-call-agency-no-route':
      'Doar la cerere! Trebuie stabilit cu compania în avans.',
    'weather-at-destination': 'Starea vremii la destinație',
    where: 'Where to?',
    yesterday: 'Ieri',
    zone: 'Zone',
    zones: 'Zone tarifare',
    'book-on-demand-taxi': 'Book a ride'
  },

  sv: {
    'about-this-service': 'Om tjänsten',
    accessibility: 'Tillgänglighet',
    'accessibility-limited': 'Med rullstolen',
    'accessibility-nolimit': 'Ingen begränsning',
    'accessibility-statement': 'Tillgänglighetsförklaring',
    'add-favourite-bikeStation-failed-heading':
      'Kunde inte spara stadscykelstationen',
    'add-favourite-place-failed-heading': 'Kunde inte spara platsen',
    'add-favourite-route-failed-heading': 'Kunde inte spara linjen',
    'add-favourite-station-failed-heading': 'Kunde inte spara stationen',
    'add-favourite-stop-failed-heading': 'Kunde inte spara hållplatsen',
    'add-home': 'Lägg till hem',
    'add-itinerary-via-point': 'Lägg till mellanstopp',
    'add-location-to-favourites':
      'Lägg till en plats som är viktig för dig på fliken Favoriter',
    'add-to-favourites': 'Lägg till för dig på fliken Favoriter',
    'add-via-button-label': 'Lägg till viapunkt',
    'add-via-duration-button-label':
      'Ställ in varaktighet för viapunkten {index}',
    'add-via-duration-button-label-close':
      'Stäng väljaren för varaktighet vid via-punkten',
    'add-via-duration-button-label-open':
      'Öppna väljaren för varaktighet vid via-punkten',
    'add-work': 'Lägg till arbete',
    'aria-itinerary-summary':
      'Den totala restiden {duration}. Avgång {inFuture} klockan {departureTime}. Framme klockan {arrivalTime}',
    'aria-itinerary-summary-bike-distance':
      'cykling sammanlagt {duration}, {distance}',
    'aria-itinerary-summary-walk-distance':
      'promenad sammanlagt {duration}, {distance}',
    'arrive-leave': 'Anländer eller lämnar vid vald tid',
    'as-destination': 'Destination',
    'as-origin': 'Avfärdsplats',
    'as-viapoint': 'Via punkt',
    'search-autosuggest-label':
      'Plats, linje och hållplatssökning. Navigera listan med piltangenterna och välj med Enter-tangeten',
    'search-autosuggest-len': 'Hittade {len} förslag',
    'search-current-suggestion': 'Nuvarande val: {selection}',
    'select-date': 'Välj datum',
    'set-time-earlier-button-label': 'Ställ in restid till tidigare',
    'set-time-later-button-label': 'Ställ in restid till senare',
    'set-time-now-button-label': 'Ställ in restid till nu',
    'time-navigation-buttons': 'Tidsnavigeringsknappar',
    'time-selector-form': 'Redigera tid',
    // eslint-disable-next-line sort-keys
    address: 'Adress',
    'address-or-stop': 'Adress eller hållplats',
    agency: 'Trafikidkare',
    airplane: 'Flyg',
    'airplane-with-route-number': 'Flyg {routeNumber}',
    'airport-check-in':
      'Checka in strax du anlänt, om du inte redan har gjort det i förväg',
    'airport-collect-luggage': 'Plocka upp ditt bagage',
    'airport-security-check-go-to-gate':
      'Gå igenom säkerhetskontrollen till gate',
    'all-routes': 'Alla linjer',
    'all-routes-disabled': 'Inga linjer',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Anländer',
    'arriving-soon': 'Nu',
    'at-time': 'kl.',
    'avoid-transfers': 'Undvik byten',
    'avoid-walking': 'Undvik gång',
    'back-button-title': 'Tillbaka till föregående sida',
    'back-to-front-page': 'Tillbaka till framsidan ›',
    bicycle: 'cyckel',
    'bicycle-distance-duration': 'Cykla {duration} ({distance})',
    'bicycle-walk-from-transit':
      'Ta cykeln från {transportMode} och led cykeln {distance} ({duration})',
    'bicycle-walk-to-transit':
      'Led cykeln {distance} ({duration}) och ta cykeln med på {transportMode}',
    bicycle_walk: 'Leder en cykel',
    'bike-availability': 'Antal cyklar just nu',
    'bike-not-allowed-bus': 'Cyklar är inte tillåtna på bussen',
    'bike-not-allowed-tram': 'Cyklar är inte tillåtna på spårvagnen',
    'bike-park': 'Anslutningsparkering för cyklar',
    'bike-station-disabled': 'Stadscykelstationen är ur bruk.',
    'bikes-available': '{amount} cyklar vid stationen',
    'biketransport-citybike': 'Jag använder en stadscykel',
    'biketransport-only-bike': 'Jag reser bara med cykel',
    'biking-amount': 'Antal cyklar',
    'biking-speed': 'Cykling hastighet',
    bus: 'Buss',
    'bus-with-route-number': 'Buss {routeNumber} {headSign}',
    buy: 'Köp',
    'buy-ticket': 'Så här köper du biljetten',
    call: 'Ring',
    cancel: 'Avbryt',
    canceled: 'Inställt',
    'canceled-itineraries-amount':
      'Ytterligare {itineraryAmount, plural, =1 {1 avställt avgång} other {{itineraryAmount} avställda avgångar}}',
    'canceled-itineraries-amount-hide':
      'Dölja inställda reseförslag ({itineraryAmount})',
    'canceled-legs': 'Inställda avgångar på linjen',
    car: 'Bil',
    'car-distance-duration': 'Kör {duration} ({distance})',
    car_park: 'infartsparkering',
    cause: 'Orsak',
    'char-left': 'tecken',
    'choose-stop': 'Välj hållplats',
    'choose-stop-or-vehicle': 'Select vehicle or stop',
    'choose-vehicle': 'Select vehicle',
    citybike: 'Stadscykel',
    'citybike-buy-season':
      'Köp ett abonnemang för en dag, en vecka eller för en hel säsong',
    'citybike-distance-duration': 'Cykla {duration} ({distance})',
    'citybike-duration-general-header':
      'Rutten har flera sträckor med stadscykel som inkluderar tilläggsavgifter.',
    'citybike-duration-info':
      'Du kan använda stadscykeln {duration} minuter åt gången, efter det debiteras en tilläggsavgift.',
    'citybike-duration-info-header':
      'Sträckan med stadscykel tar över {duration} min.',
    'citybike-duration-info-short':
      'Sträckan tar över {duration} min. En tilläggsavgift ska debiteras.',
    'citybike-network-headers': 'Stadscyklarna',
    'citybike-purchase-link': 'Gå till köp',
    'citybike-register-required':
      'Det krävs registrering för att kunna använda stadscyklar',
    'citybike-return-full': 'Vill du återlämna din stadscykel?',
    'citybike-return-full-link':
      'Så här återlämnar du cykeln till en fullsatt cykelstation ›',
    'citybike-start-using': 'Ta stadscyklar i bruk',
    'citybike-start-using-info': 'Tilläggsinformation',
    'citybike-station': 'Stadscykelstation {stationId}',
    'citybike-station-no-id': 'Stadscykelstation',
    'clear-button-label': 'Töm',
    close: 'Stäng',
    'close-favourite-module': 'Stäng favoritmodulen',
    'close-settings': 'Stäng fönstret och spara inställningar',
    'contact-information': '{contactName} Kontaktuppgifter',
    continue: 'Fortsätt',
    'create-account': 'Skapa {contactName} konto',
    'cycle-distance-duration': 'Cykla {duration} ({distance})',
    'cyclewalk-distance-duration': 'Led cykeln {duration} ({distance})',
    // eslint-disable-next-line sort-keys
    currentposition: 'Nuvarande position',
    datasources: 'Datakällor',
    date: 'Datum',
    day: 'Dagen',
    delete: 'Ta bort',
    'delete-favourite-failed-heading': 'Unable to delete save',
    'departure-is-canceled':
      'Avgång på {modeInfo} {from}–{to} kl. {time} är inställd',
    'departure-is-canceled-airplane': 'flyg {shortName}',
    'departure-is-canceled-bus': 'linje {shortName}',
    'departure-is-canceled-ferry': 'färja {shortName}',
    'departure-is-canceled-rail': '{shortName}-tåget',
    'departure-is-canceled-subway': 'metro {shortName}',
    'departure-is-canceled-tram': 'spårvagnslinje {shortName}',
    'departure-list-update.sr-instructions':
      'Listan på avgångar och avgångstider uppdateras i realtid.',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Avgångar',
    'departures-by-hour': 'Avgångar per timme',
    'departures-by-hour-minutes-route': '(minuter/rad)',
    description: 'Res kollektivt när och fjärran.',
    destination: 'Destination',
    'destination-label-change': 'Byt destination',
    'destination-outside-service':
      'Vi kunde tyvärr inte hitta några reseförslag för denna sträcka. Ändra destination, som ligger utanför serviceområde.',
    'destination-placeholder': 'Destination, linje eller hållplats',
    'dialog-return-to-map': 'Tillbaka till kartan',
    'digitransit-platform': 'Digitransit-serviceplattformen',
    disclaimer:
      'Resultaten baserar sig på beräknade körtider. Vi kan inte garantera att den förslagna förbindelsen kan förverkligas.',
    disruption: 'Störning',
    'disruption-info': 'Undantagsinfo',
    'disruption-info-no-alerts': 'Inga kända störningar eller avvikelser.',
    'disruption-info-route-no-alerts':
      'Linjen har för tillfället inga kända störningar eller avvikelser.',
    disruptions: 'Störningar',
    'disruptions-and-diversions': 'Störningar och avvikelser',
    'disruptions-tab.sr-disruptions': 'En eller flera kända störningar',
    'disruptions-tab.sr-no-disruptions': 'Inga kända störningar',
    'distance-between': 'Avstånd {distance1} m — {distance2} m',
    'distance-total': 'Resans längd',
    'distance-under': 'Avstånd mindre än {distance} m',
    earlier: 'Tidigare avgångar',
    'edit-favourites': 'Redigera favoritdestination',
    'elevation-gained-total': 'Uppförsbacke totalt',
    'elevation-lost-total': 'Nedförsbacke totalt',
    explanations: 'Förklaringar',
    'extra-info': 'Mer info',
    'favourite-failed-text': 'Försök på nytt senare.',
    'favourite-target': 'Favoritdestination',
    ferry: 'Färja',
    'ferry-with-route-number': 'Färja {routeNumber} {headSign}',
    'fetch-new-route': 'Sök en ny rutt',
    'from-ferry': 'lautasta',
    'from-rail': 'junasta',
    'from-station': 'från stationen',
    'from-stop': 'från hållplats',
    'from-subway': 'metrosta',
    frontpage: 'Framsidan',
    'generic-cancelation': '{mode} {route} {headsign} kl. {time} ställs in.',
    'generic-error': 'Det hände ett fel',
    'geolocate-yourself': 'Sök position',
    'geolocation-denied-heading': 'Delning av platsinformation är förbjudet',
    'geolocation-denied-text':
      'Du har inte aktiverat platstjänster. Du kan aktivera platsdelning via webbläsarens eller telefonens inställningar.',
    'geolocation-failed-heading': 'Platstjänster otillgängliga.',
    'geolocation-failed-text': 'Försök igen eller skriv in din plats.',
    'geolocation-prompt-text': 'Vänligen tillåt delning av platsinformation.',
    'geolocation-timeout-heading':
      'Det tar längre tid än väntat att hitta din plats.',
    'geolocation-timeout-text':
      'Har du godkänt att webbläsaren får använda din plats?',
    'give-destination': 'Skriv in din destination',
    'give-name-to-location': 'Ge ett namn som beskriver platsen',
    'give-origin': 'Skriv in din avfärdsplats',
    'give-position': 'Skriv in din position eller avfärdsplats i sökfältet.',
    'give-route': 'Sök linje',
    hour: 'Timme',
    'hour-short': 'h',
    'how-to-rate-service': 'Hur skulle du utvärdera tjänsten?',
    'in-addition': 'Även',
    'index.title': 'Reseplaneraren',
    inquiry: 'Vad tycker du om den nya Reseplaneraren. Berätta för oss!',
    instructions: 'Anvisningar',
    'itinerary-details.biking-leg':
      '{time} cykla {distance} från {origin} till destinationen {destination}. Restid {duration}',
    'itinerary-details.car-leg':
      '{time} kör {distance} från {origin} till destinationen {destination}. Restid {duration}',
    'itinerary-details.end-leg':
      '{time} du anländer till destinationen {destination}',
    'itinerary-details.route-has-info-alert':
      'Det finns meddelanden som gäller rutten.',
    'itinerary-details.route-has-severe-alert': 'Avvikelser i rutten.',
    'itinerary-details.route-has-unknown-alert': 'Störningar längs rutten..',
    'itinerary-details.route-has-warning-alert': 'Störningar längs rutten..',
    'itinerary-details.scooter-leg':
      '{time} sparkcykla {distance} från {origin} till destinationen {destination}. Restid {duration}',
    'itinerary-details.transit-leg-part-1': '{time} ta',
    'itinerary-details.transit-leg-part-2':
      'från hållplats {startStop} {startZoneInfo} {trackInfo} till hållplats {endStop} {endZoneInfo}. Beräknad restid {duration}',
    'itinerary-details.walk-leg':
      '{time} promenera {distance} från {origin} till destinationen {destination}. Restid {duration}',
    'itinerary-feedback-button': 'Skicka respons',
    'itinerary-feedback-message': 'Hittade du inte vad du sökte?',
    'itinerary-feedback-placeholder': 'Beskrivning (valfri)',
    'itinerary-hide-stops': 'Dölj hållplatserna',
    'itinerary-page.description': 'Ruttinformation',
    'itinerary-page.hide-details': 'Göm ruttbeskrivningen',
    'itinerary-page.itineraries-loaded': 'Flera ruttbeskrivningen laddade',
    'itinerary-page.loading-itineraries': 'Laddar fler ruttbeskrivningar',
    'itinerary-page.show-details': 'Visa ruttbeskrivningen',
    'itinerary-page.show-details-label': 'Visa ruttbeskrivningen {number}',
    'itinerary-page.title': 'Ruttinformation',
    'itinerary-page.update-alert': 'Sökresultaten uppdaterade',
    'itinerary-summary-page-street-mode.update-alert':
      'Walking and biking results updated',
    'itinerary-summary-page.description': 'Ruttförslag',
    'itinerary-summary-page.title': 'Ruttförslag',
    'itinerary-summary-row.biking-distance':
      'Cykling sammanlagt {totalDistance}.',
    'itinerary-summary-row.clickable-area-description': 'Visa på kartan',
    'itinerary-summary-row.description':
      'Avgång {departureDate} klockan {departureTime}. Framme {arrivalDate} klockan {arrivalTime}. {firstDeparture} {transfers} Restid {totalTime}.',
    'itinerary-summary-row.first-departure':
      '{vehicle} avgår från station {stopName} klockan {departureTime}.',
    'itinerary-summary-row.first-leg-start-time':
      'Avgår kl {firstDepartureTime} {firstDepartureStopType} {firstDepartureStop}{firstDeparturePlatform}',
    'itinerary-summary-row.first-leg-start-time-citybike':
      'Avgång kl {firstDepartureTime} från {firstDepartureStop} stadscykelstation',
    'itinerary-summary-row.no-transit-legs': 'Avgå när det passar för dig',
    'itinerary-summary-row.transfers':
      'Byte {vehicle} vid hållplats {stopName}',
    'itinerary-summary-row.walking-distance':
      'Promenad sammanlagt {totalDistance}.',
    'itinerary-summary.bikeAndPublic-fallback-title':
      'Ta cykeln med dig i fordonet',
    'itinerary-summary.bikeAndPublic-rail-subway-title':
      'Ta cykeln med på tåget eller metron',
    'itinerary-summary.bikeAndPublic-rail-title': 'Ta cykeln med på tåget',
    'itinerary-summary.bikeAndPublic-subway-title': 'Ta cykeln med på metron',
    'itinerary-summary.bikePark-title':
      'Lämna din cykel till anslutningsparkeringen',
    'itinerary-summary.interline-wait':
      'Linjenumret ({shortName}) och destinationen ({destination}) ändras vid hållplats {stop}. Resan fortsätter om {time}. Vänta ombord på bussen.',
    'itinerary-summary.show-on-map': 'Visa på kartan {target}',
    'itinerary-ticket.title': 'Biljett som behövs',
    'itinerary-tickets.title': 'Biljetter som behövs',
    'itinerary-time.title': 'Restid',
    'itinerary-walk.title': 'Gång totalt',
    'journeyplanner.title': 'HRT Reseplaneraren',
    later: 'Senare avgångar',
    leaves: 'Avgår',
    'leaving-at': 'Avgångstid',
    'likely-to-recommend':
      'Hur sannolikt är det att du skulle rekommendera den här tjänsten åt en vän eller kollega?',
    'likely-to-use':
      'Hur sannolikt skulle du använda denna tjänst i stället för den nuvarande Reseplaneraren?',
    loading: 'Laddar...',
    localadmin: 'Tätort',
    locate: 'Lokalisera',
    'location-examples': 't.ex. Hem, Arbetsplats, Skola,...',
    'location-from-map': 'Vald position',
    'location-from-map-confirm': 'Bekräfta valet',
    'location-home': 'Hem',
    'location-school': 'Skola',
    'location-shopping': 'Affär',
    'location-sport': 'Sport',
    'location-work': 'Arbetsplats',
    login: 'Logga in',
    'login-content':
      'För att kunna spara och utnyttja platser, linjer och hållplatser ska du logga in med ditt HRT-konto!',
    'login-header': 'Logga in först',
    logout: 'Logga ut',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Stäng huvudmenyn',
    'main-menu-label-open': 'Öppna huvudmenyn',
    'main-mode': 'Jag använder',
    map: 'Karta',
    'map-layer-citybike': 'Stadscykelstation',
    'map-layer-park-and-ride': 'Infartsparkering',
    'map-layer-stop-bus': 'Busshållplats',
    'map-layer-stop-ferry': 'Färja',
    'map-layer-stop-tram': 'Spårvagnshållplats',
    'map-layer-terminal-bus': 'Bussterminal',
    'map-layer-terminal-rail': 'Tågstation',
    'map-layer-terminal-subway': 'Metrostation',
    'map-layer-vehicles': 'Fordon',
    'menu-faq': 'Frågor och svar',
    'menu-feedback': 'Skicka respons',
    'menu-link-to-privacy-policy': 'Registerbeskrivning',
    'messagebar-label-close-message-bar': 'Stäng banner',
    'messagebar-label-page': 'Sidan',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Linje',
    'more-departures': 'Fler avgångar',
    'more-settings': 'Fler inställningar',
    'move-on-map': 'Flytta på kartan',
    'move-to-tab': 'Gå till fliken {number}',
    navigate: 'Navigera',
    nearest: 'Närmaste { mode }',
    'nearest-favorites': 'Hållplatser nära mig',
    'nearest-favorites-browse-stops': 'Bläddra och välj hållplatser',
    'nearest-favorites-no-favorites':
      'Tidtabeller och fordon vid de valda hållplatserna på kartan',
    'nearest-no-stops':
      'Inga stationer eller hållplatser för det valda trafikfordonet i närheten.',
    'nearest-stops': 'Närliggande hållplatser',
    'nearest-stops-airplane': 'flygplatser',
    'nearest-stops-bus': 'busshållplatser',
    'nearest-stops-citybike': 'cykelstationer',
    'nearest-stops-favorite': 'hållplatser nära mig',
    'nearest-stops-ferry': 'färjekajer',
    'nearest-stops-rail': 'tågstationer',
    'nearest-stops-subway': 'metrostationer',
    'nearest-stops-tram': 'spårvagnshållplatser',
    'nearest-stops-update-location': 'Uppdatera {mode}',
    'nearest-stops-updated-location': 'Kartans läge har ändrats',
    'network-error': 'Nätverksfel',
    // eslint-disable-next-line sort-keys
    neighbourhood: 'Gransskap',
    next: 'Nästa',
    'no-bike-allowed-popup-train':
      'Du kan ta med cykeln i närtågen avgiftsfritt, men i andra tåg är det avgiftsbelagt och kräver möjligtvis en förhandsreservation.',
    'no-bike-allowed-popup-tram-bus':
      'Cyklar är inte tillåtna i bussar eller spårvagnar. Om du använder dessa fordon på din färd, lämna cykeln vid hållplatsen eller vid infartsparkeringen.',
    'no-departures': 'Inga avgående resor',
    'no-position': 'Ingen position',
    'no-position-no-stops':
      'De närmaste hållplatserna kan inte visas, eftersom din position inte är känd.',
    'no-route-already-at-destination': 'Du är redan vid din destination.',
    'no-route-end': 'Välj destination',
    'no-route-end-date-not-in-range':
      'Tidtabellen har inga resor som avgår senare.',
    'no-route-msg': 'Kunde tyvärr inte hitta ruttförslag',
    'no-route-msg-with-changes':
      'Kunde tyvärr inte hitta ruttförslag Var god och kontrollera dina val eller försök ändra avgångsplats eller destination.',
    'no-route-origin-near-destination':
      'Kunde inte hitta ruttförslag eftersom avgångsplatsen och destinationen ligger för nära varandra.',
    'no-route-origin-same-as-destination':
      'Kunde inte hitta ruttförslag eftersom avgångsplatsen är densamma som destinationen.',
    'no-route-showing-alternative-options':
      'Kunde inte hitta några ruttförslag med dina val. Vi hittade dock följande ruttalternativ:',
    'no-route-start': 'Välj avfärdsplats',
    'no-route-start-date-too-early':
      'I tidtabellen ingår inte tidigare rutter.',
    'no-route-start-end': 'Välj avfärdsplats och destination',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Inga resor på den valda dagen.',
    now: 'Nu',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Inga hållplatser} one {1 hållplats} other {{number} hållplatser} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuter} one {1 minut} other {{number} minuter}}',
    'off-canvas-info':
      'Genom att logga in kan du spara dina favoriter och använda dem med dina andra enheter.',
    'option-default': 'Standard',
    'option-least': 'Minst',
    'option-less': 'Mindre',
    'option-more': 'Mera',
    'option-most': 'Mest',
    or: 'eller',
    origin: 'Avfärdsplats',
    'origin-label-change': 'Byt avfärdsplats',
    'origin-outside-service':
      'Kunde inte hitta ruttförslag eftersom avgångsplatsen ligger utanför vårt serviceområde.',
    'origin-placeholder': 'Från - adress eller hållplats',
    'other-dates': 'Andra datum',
    'own-position': 'Min position',
    'page-not-found': 'Sidan kunde inte hittas.',
    'park-and-ride': 'Infartsparkering',
    'park-and-ride-availability': 'Lediga parkeringsplatser',
    'pay-attention': 'Obs!',
    'pick-icon': 'Välj en ikon',
    'pick-mode': 'Trafikslag',
    'pick-mode-airplane-disabled': 'Flyg inte vald',
    'pick-mode-airplane-enabled': 'Flyg valt',
    'pick-mode-bus-disabled': 'Buss inte vald',
    'pick-mode-bus-enabled': 'Buss valt',
    'pick-mode-citybike-disabled': 'Stadscykel inte vald',
    'pick-mode-citybike-enabled': 'Stadscykel valt',
    'pick-mode-ferry-disabled': 'Färja inte vald',
    'pick-mode-ferry-enabled': 'Färja valt',
    'pick-mode-rail-disabled': 'Tåg inte vald',
    'pick-mode-rail-enabled': 'Tåg valt',
    'pick-mode-selected': 'Valda trafikslagen',
    'pick-mode-subway-disabled': 'Metro inte vald',
    'pick-mode-subway-enabled': 'Metro valt',
    'pick-mode-tram-disabled': 'Spårvagn inte vald',
    'pick-mode-tram-enabled': 'Spårvagn valt',
    'pick-street-mode': 'Egna färdmedel',
    'place-route-or-keyword': 'Destination, linje eller hållplats',
    platform: 'Plattform',
    'platform-num': 'Plattform {platformCode}',
    'platform-short': 'Plattf. {platformCode}',
    'platform-short-no-num': 'Plattf.',
    position: 'Position',
    'prefer-walking': 'Främja gång',
    previous: 'Senaste',
    print: 'Skriv ut',
    'print-timetable': 'Veckans tidtabell',
    'public-transport': 'Kollektivtrafik',
    rail: 'Närtåg',
    'rail-with-route-number': 'Tåg {routeNumber} {headSign}',
    'read-more': 'Läs mer',
    realtime: 'Realtid',
    'realtime-matching': 'Realtidsintegration',
    releases: 'Meddelanden',
    'remove-favourite': 'Ta bort favoritmarkeringen',
    'remove-via-button-label': 'Ta bort viapunkt {index}',
    'rent-cycle-at': 'Sök en stadscykel:',
    'rent-scooter-at': 'Hyr en sparkcykel från stationen {station}',
    'required-ticket': 'Biljett som behövs under resan',
    'return-cycle-to': 'Returnera stadscykeln: ',
    'return-scooter-to': 'Returnera sparkcykeln till stationen {station}',
    'right-now': 'Just nu',
    route: 'Linje',
    'route-add-viapoint': 'Via punkt',
    'route-custom-settings': 'Mina inställningar',
    'route-default': 'Standardrutt',
    'route-destination-arrives': 'Endast för avstigning',
    'route-destination-endpoint': 'Ankomst / Ändhållplats',
    'route-fastest': 'Snabb rutt',
    'route-from-here': 'Avfärdsplats',
    'route-guide': 'Linjeguide',
    'route-here': 'Destination',
    'route-least-elevation-changes': 'Undvik höjdskillnader',
    'route-least-transfers': 'Minsta överföringar',
    'route-least-walking': 'Minsta gången',
    'route-not-possible':
      'Tyvärr är din rutt inte möjlig. Tekniskt fel: "{error}"',
    'route-page-vehicle-position':
      '{mode} {shortName} är vid hållplatsen {stopName}',
    'route-page-vehicle-position-between':
      '{mode} {shortName} är mellan hållplats {stopName} och hållplats {nextStopName}.',
    'route-page.description': 'Linje {shortName} - {longName}',
    'route-page.future-routes': 'Framtida rutter',
    'route-page.pattern-chosen': 'Vald',
    'route-page.pattern-select-title': 'Välj den rutt du vill se',
    'route-page.similar-routes': 'Du kanske också är intresserad av',
    'route-page.special-routes': 'Andra rutter',
    'route-page.title': 'Linje {shortName}',
    'route-page.title-short': 'Linje',
    'route-to-stop': 'Rutt till hållplatsen',
    routes: 'Linjer',
    'routes-platforms': 'Linjer och plattformer',
    'routes-tracks': 'Linjer och spår',
    run: 'Springande',
    save: 'Spara',
    'scooter-availability': 'Antal sparkcyklar just nu',
    'scooter-distance-duration': 'Sparkcykla {duration} ({distance})',
    'scooter-register-required':
      'Det krävs registrering för att kunna använda sparkcyklar',
    'scooterwalk-distance-duration': 'Led sparkcykeln {duration} ({distance})',
    search: 'Sök',
    'search-button-label': 'Sök',
    'search-destination': 'Sök destination, linje eller hållplats',
    'search-destination-index': 'Skriv destination',
    'search-destination-results-but-no-search':
      'Se resultaten under fliken Destination',
    'search-fields.sr-instructions':
      'Ruttsökning görs automatiskt när du skriver in avgångsplats och destination. Om du ändrar sökvillkoren, startas en ny ruttsökning automatiskt.',
    'search-no-results': 'Inga sökresultat',
    'search-origin': 'Sök avfärdsplats, linje eller hållplats',
    'search-origin-index': 'Skriv avfärdsplats',
    'search-position': 'Sök position',
    'search-search-results-but-no-destination':
      'Se resultaten under fliken Linje eller hållplats',
    'searching-position': 'Söker position...',
    'select-all-routes': 'Välj alla rutter',
    'select-from-map-destination': 'Välj destination',
    'select-from-map-no-title': 'Ingen rubrik',
    'select-from-map-origin': 'Välj avfärdsplats',
    'select-from-map-viaPoint': 'Välj mellanpunkt',
    'select-map-layers-header': 'Visa på kartan',
    'select-position': 'Välj din position bland tidigare sökningar',
    'select-route': 'Välj {mode} rutt {shortName} till {headsign}',
    'selected-routes': 'Valda linjen',
    send: 'Skicka',
    'separate-ticket-required':
      'Denna etapp av resan kräver en separat biljett.',
    'separate-ticket-required-disclaimer':
      'Resan består av en eller fleta etapper vilka kräver biljetter som inte säljs av {agencyName}.',
    'set-specific-settings': 'Ställa avancerade inställningar',
    'set-stop-as-routes': 'Ställa hållplats som rutter',
    settings: 'Anpassa',
    'settings-dropdown-close-label': 'Stäng alternativen',
    'settings-dropdown-open-label': 'Öppna alternativen',
    'settings-label-change': 'Anpassa sökning',
    share: 'Dela resan',
    show: 'Visa',
    'show-departures': 'Visa avgångarna',
    'show-more': 'Visa flera',
    'show-more-stops-near-you': 'Visa flera hållplatser nära dig',
    'show-routes': 'Välj linjer',
    'skip-positioning': 'Hoppa över',
    'skip-to-content': 'Till innehållet',
    slow: 'Långsam',
    'specify-location': 'Ange en plats',
    'splash-locating': 'Söker din plats...',
    'splash-or-choose': 'eller välj en avfärdsplats',
    'splash-please-allow-positioning':
      'Tjänsten fungerar bäst om du delar din plats.',
    'splash-use-positioning': 'Använd min plats',
    'splash-you-can-also': 'alternativt',
    station: 'Station',
    stop: 'Hållplats',
    'stop-departure-time-future': 'Avgångstid är i {minutes} minuter',
    'stop-departure-time-past': 'Avgångstid var klockan',
    'stop-list-update.sr-instructions':
      'Avgångstider uppdateras i realtid för varje hållplats.',
    'stop-near-you': 'Sök hållplatser eller linjer nära dig',
    'stop-near-you-modal-grant-permission': 'eller aktivera positionering',
    'stop-near-you-modal-grant-permission-info':
      'Du kan aktivera positionering i inställningar så att tjänsten kan använda din position.',
    'stop-near-you-modal-header': 'Du har inte aktiverat positionering',
    'stop-near-you-modal-info':
      'Användning av funktion ”hållplatserna nära dig” förutsätter information om din position.',
    'stop-near-you-title': 'Sök hållplatser och linjer',
    'stop-near-you-update-alert': 'Närliggande hållplatser uppdaterade',
    'stop-number': 'Hållplatsnummer',
    'stop-page.description': 'Hållplats {name} - {code}',
    'stop-page.right-now.loaded': 'hållplats sidan laddad',
    'stop-page.title': 'Hållplats {name} - {code}',
    'stop-page.title-short': 'Hållplats',
    'stop-virtual-monitor': 'Virtuell monitor',
    stops: 'Hållplatser',
    'stops-near-you.description': 'Stop timetables and vehicles near your',
    'stops-near-you.title': 'Near by stops timetables',
    'street-mode-bicycle': 'Cykel',
    'street-mode-bike-aria': 'Visa cykelrutten. Tid {duration}, längd {length}',
    'street-mode-bikeandvehicle-aria':
      'Visa kombinationen av cykling och kollektivtrafik. Tid {duration}, längd {length}',
    'street-mode-car': 'Bil',
    'street-mode-car_park': 'Infarts\u00ADparkering',
    'street-mode-public_transport': 'Kollektiv\u00ADtrafik',
    'street-mode-walk': 'Gång',
    'street-mode-walk-aria':
      'Visa promenadrutt. Tid {duration}, längd {length}',
    // eslint-disable-next-line sort-keys
    street: 'Gata',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.row-label': 'Ruttförslag {number}',
    'summary-page.title': 'Ruttförslag',
    'swap-order-button-label': 'Sväng ruttens riktning till den motsatta',
    'swipe-disruption-info': 'Navigeringsknapp för att kunna bläddra störning',
    'swipe-disruption-info-header': 'Störning.',
    'swipe-message-bar': 'Navigeringsknapp för att kunna bläddra stäng banner',
    'swipe-message-bar-header': 'Stäng banner.',
    'swipe-result-tab-left':
      'Gå till föregående blad genom att trycka på enter eller mellanslag.',
    'swipe-result-tab-right':
      'Gå till följande blad genom att trycka på enter eller mellanslag.',
    'swipe-result-tabs': 'Bläddra mellan blad med pilknapparna.',
    'swipe-sr-new-tab-opened': 'Blad {number} öppnad.',
    'swipe-stops-near-you':
      'Navigeringsknapp för att kunna bläddra hållplatser nära mig.',
    'swipe-stops-near-you-header': 'Hållplatser nära mig.',
    'swipe-summary-page': 'Navigeringsknapp för att kunna bläddra ruttförslag.',
    'swipe-summary-page-header': 'Ruttförslag.',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Användarvillkor',
    'ticket-and-price-info': 'Biljett- och prisinformation',
    'ticket-single-adult': 'Vuxen',
    'ticket-type-none': 'Ingen begränsning',
    tickets: 'Biljetter',
    time: 'Tid',
    'time-selector-hours-label': 'Timme',
    'time-selector-minutes-label': 'Minut',
    timetable: 'Tidtabell',
    'to-ferry': 'lauttaan',
    'to-frontpage': 'Till startsidan',
    'to-rail': 'junaan',
    'to-subway': 'metroon',
    today: 'I dag',
    tomorrow: 'I morgon',
    track: 'Spår',
    'track-num': 'Spår {platformCode}',
    'track-short': 'Spår {platformCode}',
    'track-short-no-num': 'Spår',
    tram: 'Spårvagn',
    'tram-with-route-number': 'Spårvagn {routeNumber} {headSign}',
    transfers: 'Antal byten',
    'transfers-allowed': 'Flera byten',
    'transfers-margin': 'Bytestid minst',
    'travel-time': '{min} min',
    'travel-time-label': 'Restid',
    'travel-time-with-hours': '{h} h {min} min',
    'trip-co2-emissions': 'Resans CO2-utsläpp',
    'trip-information': 'Visa avgång',
    'trip-page.description': 'Linje {shortName} - {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Försök igen',
    'use-citybike': 'Ta i bruk',
    'use-national-service': 'Du kan också använda national tjänsten:',
    'use-national-service-postfix': '-tjänsten.',
    'use-national-service-prefix':
      'Vi rekommenderar att du använder ruttsökning',
    'use-own-position': 'Använd min position',
    userinfo: 'Mina uppgifter',
    usermenu: 'Användarmeny',
    'using-modes': 'Jag vill åka',
    'very-likely': 'Väldigt sannolikt',
    'very-unlikely': 'Väldigt osannolikt',
    'via-leg-stop-duration': 'Mellanstopp {stayDuration}',
    'via-point': 'Stoppets längd',
    'via-point-index': 'Stoppets längd {index}',
    'viapoint-slack-amount': 'Stop duration',
    'view-route': 'Visa linje',
    // eslint-disable-next-line sort-keys
    venue: 'Mötesplats',
    wait: 'Vänta',
    'wait-amount-of-time': 'Vänta {duration}',
    walk: 'gång',
    'walk-bike-itinerary-1':
      'Vi har endast hittat ruttförslag i vilka ingår promenad.',
    'walk-bike-itinerary-2':
      'Vi har endast hittat ruttförslag i vilka ingår cykling.',
    'walk-bike-itinerary-3':
      'Vi har endast hittat ruttförslag i vilka ingår promenad och cykling.',
    'walk-distance-duration': 'Gå {duration} ({distance})',
    walking: 'Gång',
    'walking-speed': 'Promenadhastighet',
    'warning-call-agency':
      'Trafikeras enligt behov: {routeName}, som bör beställas på förhand.',
    'warning-call-agency-no-route':
      'Trafikeras enligt behov som bör beställas på förhand.',
    weather: 'Väder',
    'weather-at-destination': 'Vädret på destinationen',
    'weather-data-source': 'Källa: Meteorologiska institutet',
    'weather-detail-title': 'Väderprognos för avgångsplatsen kl.',
    'weather-icon-1': 'Klart',
    'weather-icon-2': 'Halvklart',
    'weather-icon-21': 'Lätta regnskurar',
    'weather-icon-22': 'Regnskurar',
    'weather-icon-23': 'Kraftiga regnskurar',
    'weather-icon-3': 'Mulet',
    'weather-icon-31': 'Lätt regn',
    'weather-icon-32': 'Regn',
    'weather-icon-33': 'Kraftigt regn',
    'weather-icon-41': 'Lätta snöbyar',
    'weather-icon-42': 'Snöbyar',
    'weather-icon-43': 'Ymniga snöbyar',
    'weather-icon-51': 'Lätt snöfall',
    'weather-icon-52': 'Snöfall',
    'weather-icon-53': 'Ymnigt snöfall',
    'weather-icon-61': 'Åskskurar',
    'weather-icon-62': 'Kraftiga åskskurar',
    'weather-icon-63': 'Åska',
    'weather-icon-64': 'Kraftigt åska',
    'weather-icon-71': 'Byar av lätt snöblandat regn',
    'weather-icon-72': 'Byar av snöblandat regn',
    'weather-icon-73': 'Byar av kraftigt snöblandat regn',
    'weather-icon-81': 'Lätt snöblandat regn',
    'weather-icon-82': 'Snöblandat regn',
    'weather-icon-83': 'Lätt snöblandat regn',
    'weather-icon-91': 'Dis',
    'weather-icon-92': 'Dimma',
    where: 'Vart vill du åka?',
    yesterday: 'I går',
    zone: 'Zon',
    'zone-info': 'Zon {zone}',
    'zone-unknown': 'Okänd zon',
    zones: 'Biljettzoner',
  },
};

export default translations;
